import { LogMessage, LogTag } from './types';
import { logRequest } from './utils';
import WsLoggerClient from './ws-logger-client.class';

let wsLogger: WsLoggerClient;

const getWsLogger = (): WsLoggerClient => {
  if (wsLogger) return wsLogger;
  wsLogger = new WsLoggerClient();
  return wsLogger;
};

export const log = (msg: LogMessage) => {
  logRequest(msg);
};

export const wsLog = (data: LogMessage): void => {
  getWsLogger()?.log(data);
};

export const wsDebug = (tag: LogTag, msg = '', data = {}, requestId = '') => {
  getWsLogger()?.debug(tag, msg, data, requestId);
};

export const wsInfo = (tag: LogTag, msg = '', data = {}, requestId = '') => {
  getWsLogger()?.info(tag, msg, data, requestId);
};

export const wsNotice = (tag: LogTag, msg = '', data = {}, requestId = '') => {
  getWsLogger()?.notice(tag, msg, data, requestId);
};

export const wsWarning = (tag: LogTag, msg = '', data = {}, requestId = '') => {
  getWsLogger()?.warning(tag, msg, data, requestId);
};

export const wsError = (tag: LogTag, msg = '', data = {}, requestId = '') => {
  getWsLogger()?.error(tag, msg, data, requestId);
};

export const wsCritical = (tag: LogTag, msg = '', data = {}, requestId = '') => {
  getWsLogger()?.critical(tag, msg, data, requestId);
};
