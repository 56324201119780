import React, { FC } from 'react';
import useCarsApi from 'providers/cars-api/use-cars-api';
import Notifications from 'modules/notifications';
import useIframeContext from 'providers/iframe/use-iframe-context';

import { SlideDown } from 'components/common/animations';
import styles from './header.module.scss';

const TopStickyContainer: FC = () => {
  const { notifications } = useCarsApi();
  const { inIframe, topEdge } = useIframeContext();
  const visible = notifications.length > 0;

  return (
    <SlideDown open={visible}>
      <div
        className={styles.topStickyContainer}
        style={{
          top: inIframe ? `${topEdge}px` : undefined
        }}
      >
        <div className={styles.topStickyContainer__content}>
          <Notifications />
        </div>
      </div>
    </SlideDown>
  );
};

export default TopStickyContainer;
