import React, { FC } from 'react';
import className from 'classnames';
import useCarsApi from 'providers/cars-api/use-cars-api';
import AcceptedPayments from './accepted-payments';
import Certificates from './certificates';
import BottomStickyContainer from './bottom-sticky-container';

import styles from './footer.module.scss';

const PageFooter: FC = () => {
  const { subpage } = useCarsApi();

  return (
    <div className={styles.pageFooter__wrapper}>
      <div
        className={className(styles.pageFooter, {
          [styles['pageFooter--enlarge']]: subpage === 'searchform'
        })}
      >
        <AcceptedPayments />

        <Certificates />

        <BottomStickyContainer />
      </div>
    </div>
  );
};

export default PageFooter;
