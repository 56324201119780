import React, { FC } from 'react';

import useTranslation from 'providers/translations/use-translations';
import { CarOffer } from 'src/cars/types';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import Button from 'components/forms/button';
import CarIcon from 'components/common/car-icon';

import { goToBookingPage } from 'src/url-manager';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import styles from '../compare.module.scss';

interface Props {
  offer?: CarOffer;
}

const GoToBookingCell: FC<Props> = ({ offer }) => {
  const { t } = useTranslation();
  const { inhouse } = useDevToolsContext();
  const { isMobile } = useScreenDetect();

  return (
    <Button
      className={styles.bookBtn}
      variant="primary"
      size="large"
      onClick={() => {
        goToBookingPage(offer.offerId, inhouse);
      }}
    >
      {!isMobile && <span>{t('0nc_choosethis')}</span>}
      <CarIcon icon="chevron-right-thin" size="30px" />
    </Button>
  );
};

GoToBookingCell.defaultProps = {
  offer: null
};

export default GoToBookingCell;
