import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';
import useTranslation from 'providers/translations/use-translations';

interface Props {
  offer?: CarOffer;
}

const FuelPolicyCell: FC<Props> = ({ offer }) => {
  const { t } = useTranslation();

  return (
    <div>
      {offer.fuelPolicy && t(`fuel_policy_${offer.fuelPolicy.toLowerCase()}`, offer.fuelPolicy)}
      {!offer.fuelPolicy && t('fuel_policy_unkown')}
    </div>
  );
};

FuelPolicyCell.defaultProps = {
  offer: null
};

export default FuelPolicyCell;
