import React, { FC } from 'react';
import amex from 'payment-icons/min/single/amex.svg';
import mastercard from 'payment-icons/min/single/mastercard.svg';
import maestro from 'payment-icons/min/single/maestro.svg';
import visa from 'payment-icons/min/single/visa.svg';
import diners from 'payment-icons/min/single/diners.svg';
import useTranslation from 'providers/translations/use-translations';

import styles from './footer.module.scss';

const AcceptedPayments: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.acceptedPayments}>
      <p className={styles.acceptedPayments__title}>{t('accepted_payment_methods')}</p>
      <ul className={styles.acceptedPayments__list}>
        <li>
          <img src={amex} alt="American Express" />
        </li>
        <li>
          <img src={mastercard} alt="Master Card" />
        </li>
        <li>
          <img src={maestro} alt="Maestro" />
        </li>
        <li>
          <img src={visa} alt="Visa" />
        </li>
        <li>
          <img src={diners} alt="Diners" />
        </li>
      </ul>
    </div>
  );
};

export default AcceptedPayments;
