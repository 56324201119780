import React from 'react';
import Head from 'next/head';
import useCarsApi from 'providers/cars-api/use-cars-api';
import useTheme from 'providers/theme/use-theme';
import {
  parseLinkTags,
  parseMetaTags,
  parseScriptTags,
  parseStyleTags,
  parseTitle
} from 'src/utils/string';

const AgentCustomMeta = () => {
  const { customHeaders, customCssUrl } = useTheme();
  const { agent, general } = useCarsApi();

  if (!general || !agent) return null;
  const metaContent = customHeaders.meta;
  const stylesContent = customHeaders.css;
  const scriptsContent = customHeaders.js;

  if (
    (!metaContent || metaContent.length === 0) &&
    (!stylesContent || stylesContent.length === 0) &&
    (!scriptsContent || scriptsContent.length === 0) &&
    !customCssUrl
  ) {
    return null;
  }

  const title = parseTitle(metaContent);

  return (
    <>
      <Head>
        {title && <title>{title}</title>}

        {customCssUrl !== '' && <link key="agentCustomCss" href={customCssUrl} rel="stylesheet" />}

        {metaContent.map((item) =>
          parseMetaTags(item).map((attrs, i) => <meta key={`custom-head-meta-${i}`} {...attrs} />)
        )}

        {metaContent
          .concat(stylesContent)
          .map((item) =>
            parseLinkTags(item).map((attrs, i) => <link key={`custom-head-link-${i}`} {...attrs} />)
          )}

        {metaContent.map((item) =>
          parseScriptTags(item).map((attrs, i) => (
            <script
              key={`custom-head-meta-script-${i}`}
              src={attrs.src}
              dangerouslySetInnerHTML={{
                __html: `/* Custom head (meta) script no.${i} */${attrs.content}`
              }}
            />
          ))
        )}

        {stylesContent.map((item, j) =>
          parseStyleTags(item).map((attrs, i) => (
            <style
              key={`custom-head-style-${j}-${i}`}
              dangerouslySetInnerHTML={{
                __html: `/* Custom head style no. ${j} - ${i} */${attrs.content}`
              }}
            />
          ))
        )}

        {scriptsContent.map((item) =>
          parseScriptTags(item).map((attrs, i) => (
            <script
              key={`custom-head-script-${i}`}
              src={attrs.src}
              dangerouslySetInnerHTML={{
                __html: `/* Custom head script no.${i} */${attrs.content}`
              }}
            />
          ))
        )}
      </Head>
    </>
  );
};

export default AgentCustomMeta;
