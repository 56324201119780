import { ErrorMessage, ErrorMessages } from 'types/error-message.type';
import { get } from 'lodash';
import ErrorCodes from 'types/error-codes.enum';

const DEFAULT_ERROR: ErrorCodes = 999;

class FrontendError extends Error {
  code: number;
  message: string;
  reason: string;
  partnerMessage: string;
  requestUrl: string;
  requestBody: string;
  responseData: any;
  details: any;

  requestId: string;

  data: ErrorMessage = {
    code: DEFAULT_ERROR,
    message: 'Unknown error'
  };
  logged = false;

  constructor(
    public errors: ErrorMessages = [],
    requestId = ''
  ) {
    super(
      `${get(errors, '[0].code', DEFAULT_ERROR)} - ${get(errors, '[0].message', 'Unknown error')}`
    );

    this.requestId = get(errors, '[0].requestId', requestId);
    this.code = get(errors, '[0].code', DEFAULT_ERROR);
    this.message = get(errors, '[0].message', 'Unknown error');
    this.reason = get(errors, '[0].reason', '');
    this.partnerMessage = get(errors, '[0].partnerMessage', '');
    this.requestUrl = get(errors, '[0].requestUrl', '');
    this.requestBody = get(errors, '[0].requestBody', '');
    this.responseData = get(errors, '[0].responseData', {});
    this.details = get(errors, '[0].details', {});
  }
  setLogged() {
    this.logged = true;
  }
}

export default FrontendError;

export const simplifyFrontendError = (err: FrontendError): any => ({
  code: get(err, 'code', DEFAULT_ERROR),
  message: get(err, 'message', 'Unknown error'),
  reason: get(err, 'reason', ''),
  partnerMessage: get(err, 'partnerMessage', ''),
  requestId: get(err, 'requestId', ''),
  details: get(err, 'details', {})
});
