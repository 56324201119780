import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import useTheme from 'providers/theme/use-theme';

const AgentCustomFooter = () => {
  const { customHeaders } = useTheme();
  const [footer, setFooter] = useState(customHeaders.footer);

  useEffect(() => {
    setFooter(customHeaders.footer);
  }, [customHeaders]);

  if (footer === '') return null;

  return <>{parse(footer)}</>;
};

export default AgentCustomFooter;
