export type Coordinates = {
  latitude: number;
  longitude: number;
};

export type OpenHourItem = Array<[number, number]>;

export type OpenHours = {
  mon: OpenHourItem;
  tue: OpenHourItem;
  wed: OpenHourItem;
  thu: OpenHourItem;
  fri: OpenHourItem;
  sat: OpenHourItem;
  sun: OpenHourItem;
};
export type RawStation = {
  code: string;
  extendedCode: string;
  name: string;
  partner: string;

  geo: Coordinates;
  locationTypes: Array<StationType>;

  countryCode: string;
  countryName: string;
  city: string;
  zipCode: string;
  addressLines: Array<string>;
  openHours: OpenHours;
  phoneNumber: string;
};

export type StationType = 'city' | 'rail' | 'airport' | 'shuttle' | 'terminal' | 'other';

export const stationFilterCheckAgainst = ['city', 'rail', 'airport', 'shuttle', 'terminal'];

export type Station = {
  id: string;
  code: string;
  name: string;
  partner: string;
  geo: Coordinates;
  locationTypes: Array<StationType>;
  countryCode: string;
  countryName: string;
  city: string;
  zipCode: string;
  addressLines: Array<string>;
  openHours: OpenHours;
  phoneNumber: string;
  cars: Array<any>;
  date?: string;
};
