import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';
import classNames from 'classnames';
import styles from '../compare.module.scss';

interface Props {
  offer?: CarOffer;
}

const CarImageCell: FC<Props> = ({ offer }) => (
  <div className={classNames(styles.comparison__offerPicture)}>
    <img alt={offer.name} src={offer.image.replace('75x75', '180x130')} />
  </div>
);

CarImageCell.defaultProps = {
  offer: null
};

export default CarImageCell;
