import React, { FC, ReactNode } from 'react';
import { CarsApiConstructParams } from 'types/engine/cars-api-construct-params.type';

import styles from './clean-layout.module.scss';

type Props = {
  initData: CarsApiConstructParams;
  children: ReactNode;
};

const CleanLayout: FC<Props> = ({ children }) => (
  <div id="yps_container" className={styles.pageContainer}>
    {children}
  </div>
);

export default CleanLayout;
