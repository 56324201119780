import { milisecondsToSeconds } from './data-conversion';

export type SplitTime = {
  time: number;
  interval: number;
  timestamp: number;
  name?: string;
};

export class Timer {
  private initTime = 0;

  private total = 0;

  private splits: Array<SplitTime> = [];
  constructor() {
    this.initTime = performance.now();
  }

  splitTime(name?: string, params = {}) {
    this.splits.push({
      ...params,
      timestamp: performance.now(),
      time: Math.round(performance.now() - this.initTime),
      interval: Math.round(
        performance.now() -
          (this.splits.length > 0 ? this.splits[this.splits.length - 1].timestamp : this.initTime)
      ),
      name
    });
  }

  stop(name = 'Total', params = {}) {
    this.total = Math.round(performance.now() - this.initTime);
    this.splits.push({
      ...params,
      time: this.total,
      timestamp: performance.now(),
      interval: Math.round(
        performance.now() -
          (this.splits.length > 0 ? this.splits[this.splits.length - 1].timestamp : this.initTime)
      ),
      name
    });
    return this.total;
  }

  getTimes(): Array<SplitTime> {
    return this.splits;
  }

  getTimesInSeconds(): Array<SplitTime> {
    return this.splits.map((el) => ({
      ...el,
      timestamp: undefined,
      time: el.time / 1000,
      interval: el.interval / 1000
    }));
  }

  totalInSeconds(accuracy = 2): number {
    const multiplier = 10 ** accuracy;
    return Math.round(this.total * multiplier) / multiplier;
  }

  toSecondsString(accuracy = 2): string {
    return `${milisecondsToSeconds(this.total, accuracy)}s.`;
  }
}
