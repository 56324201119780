import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getQueryParamsList } from 'src/utils/url-tools';

import styles from './dev-tools.module.scss';

const UrlParamsPreview = () => {
  const router = useRouter();
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(getQueryParamsList());

    const handleRouteChange = () => {
      setList(getQueryParamsList());
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <div>
      <h5 className={styles.subtitle}>Url params</h5>
      <ul className={styles.urlParamsList}>
        {list.map((item) => (
          <li key={item['name']}>
            {`${item['name']}: `}
            {item['name'] === 'searchid' ? (
              <a
                className={styles.link}
                target="_blank"
                href={`http://carmonitor.infosys.loc/session-details?session_id=${item['value']}`}
                rel="noreferrer"
              >
                <strong>{item['value']}</strong>
              </a>
            ) : (
              <span>
                <strong>{item['value']}</strong>
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UrlParamsPreview;
