import { CarsProvider } from 'providers/cars-api';
import React, { FC, ReactNode } from 'react';
import { CarsApiConstructParams } from 'types/engine/cars-api-construct-params.type';
import classNames from 'classnames';
import styles from './hpreview-layout.module.scss';

type Props = {
  initData: CarsApiConstructParams;
  children: ReactNode;
};

const HpreviewLayout: FC<Props> = ({ initData, children }) => (
  <CarsProvider initData={initData} layoutStyle={styles}>
    <div id="yps_container" className={classNames(styles.pageContainer, 'hpreviewLayout')}>
      {children}
    </div>
  </CarsProvider>
);

export default HpreviewLayout;
