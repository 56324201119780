import { iframeContext } from 'providers/iframe';
import { useContext } from 'react';
import { IframeContextType } from './types';

const useIframeContext = (): IframeContextType => {
  const { iframeData, inIframe, topEdge, bottomEdge, goTop } = useContext(iframeContext);
  return {
    iframeData,
    inIframe,
    topEdge,
    bottomEdge,
    goTop
  };
};

export default useIframeContext;
