import React, { FC, useEffect } from 'react';

import useTranslation from 'providers/translations/use-translations';
import Button from 'components/forms/button';
import useCarsApi from 'providers/cars-api/use-cars-api';
import classNames from 'classnames';
import { wsDebug } from 'src/logger';
import { Notify } from './types';

import styles from './notifications.module.scss';

interface Props {
  notify: Notify;
}

const SingleNotification: FC<Props> = ({ notify }) => {
  const { notificationService } = useCarsApi();
  const { t } = useTranslation();

  useEffect(() => {
    wsDebug('userInteraction', `Show ${notify.type} notification`, {
      title: t(notify.title),
      messages: notify.messages
        .filter((message) => t(message.key, message.data))
        .map((message) => t(message.key, message.data))
    });
  }, []);

  return (
    <li
      className={classNames(styles.notification, {
        [styles['notification--danger']]: notify.type === 'error',
        [styles['notification--warning']]: notify.type === 'warn',
        [styles['notification--large']]: notify.size === 'large'
      })}
    >
      <div className={styles.notification__content}>
        {notify.title && t(notify.title, '') && (
          <p className={styles.notification__title}>{t(notify.title)}</p>
        )}
        {notify.messages
          .filter((message) => t(message.key, message.data))
          .map((message) => (
            <p key={message.key} className={styles.notification__message}>
              {t(message.key, message.data)}
            </p>
          ))}
      </div>

      <Button
        className={styles.notification__close}
        icon="cross"
        onClick={() => notificationService.removeNotify(notify.id)}
        variant="text"
      />
    </li>
  );
};

export default SingleNotification;
