import React, { createContext, FC, useEffect, useState } from 'react';
import { ScreenContextProps, ScreenContextType } from './types';

const screenContext = createContext<ScreenContextType>({
  screenWidth: 0,
  isMobile: false,
  isTablet: false,
  isDesktop: true
});

const getWidth = (ssrIsMobile = false): number => {
  if (typeof window === 'undefined') {
    return ssrIsMobile ? 767 : 1280;
  }
  return window.innerWidth || document.body.offsetWidth;
};

const ScreenProvider: FC<ScreenContextProps> = ({
  children,
  ssrIsMobile = false
}: ScreenContextProps) => {
  const width = getWidth(ssrIsMobile);
  const [screenWidth, setScreenWidth] = useState(width);
  const [isMobile, setIsMobile] = useState(width < 768);
  const [isTablet, setIsTablet] = useState(width < 1025);
  const [isDesktop, setIsDesktop] = useState(width >= 1025);

  const handleWindowResize = () => {
    const w = getWidth();
    setScreenWidth(w);
    setIsMobile(w < 768);
    setIsTablet(w >= 768 && w < 1025);
    setIsDesktop(w >= 1025);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <screenContext.Provider
      value={{
        screenWidth,
        isMobile,
        isTablet,
        isDesktop
      }}
    >
      {children}
    </screenContext.Provider>
  );
};

export { screenContext, ScreenProvider };
