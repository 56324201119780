import moment from 'moment';
import { getTabId, initTabId } from 'providers/cars-api/utils';
import { LogMessage, LogTag, WsLogMessage } from 'src/logger/types';
import safeNextConfig from 'src/utils/safe-next-config';
import { getRawQueryParam } from 'src/utils/url-tools';
import { serializeError } from 'serialize-error';
import { sessionStorageAvailable } from 'src/utils';
import { getBrowserData, logRequest, isLogLevel, stringToLogLevel, tagToGroup } from './utils';

const { publicRuntimeConfig } = safeNextConfig();

export default class WsLoggerClient {
  socket: WebSocket = null;
  opened = false;
  wasOpened = false;
  brokenWs = false;
  logLevel = 0;
  retryCounter = 0;
  aliveInterval;

  constructor() {
    if (typeof window === 'undefined') return;
    this.logLevel = stringToLogLevel(publicRuntimeConfig.logLevel);
    this.connect();
  }

  static getUrl(): string {
    const { protocol, host } = window.location;
    return typeof window !== 'undefined'
      ? `${protocol === 'https:' ? 'wss:' : 'ws:'}//${host}/data-log`
      : '';
  }

  connect() {
    this.socket = new WebSocket(WsLoggerClient.getUrl());

    this.socket.onopen = () => {
      this.retryCounter = 0;
      this.opened = true;
      this.wasOpened = true;
      this.keepAlive();
    };
    this.socket.onclose = (e) => {
      this.retryCounter += 1;
      this.opened = false;
      if (this.retryCounter < (this.wasOpened ? 20 : 10)) {
        setTimeout(() => this.connect(), 2000);
      } else {
        const { code } = e;

        this.brokenWs = true;
        logRequest({
          level: 'WARNING',
          tag: 'wsError',
          msg: WsLoggerClient.wsErrCodeToMessage(code),
          data: {
            browserData: getBrowserData(),
            tabId: initTabId(),
            referer: document.referrer,
            error: serializeError(e)
          }
        });
      }
    };
    this.socket.onerror = () => null;
  }

  private static wsErrCodeToMessage(code?: number): string {
    if (code === 1006) return 'Connection closed by browser';
    return 'Websocket connection failed';
  }

  private skipLog(level: string): boolean {
    if (!isLogLevel(level)) return true;
    return this.logLevel < stringToLogLevel(level);
  }

  private sendMessage(e: WsLogMessage): void {
    if (this.opened) {
      this.socket.send(JSON.stringify(e));
    } else if (this.brokenWs) {
      if (e.type === 'log') {
        logRequest(e.data, true);
      }
    } else {
      setTimeout(() => {
        this.sendMessage(e);
      }, 1000);
    }
  }

  private keepAlive() {
    if (!this.opened) return;
    if (this.aliveInterval) clearInterval(this.aliveInterval);
    this.sendMessage({ type: 'ping' });
    this.aliveInterval = setInterval(() => {
      this.sendMessage({ type: 'ping' });
    }, 10000);
  }

  static baseMessage(): LogMessage {
    return {
      created: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      level: 'DEBUG',
      tag: 'debug',
      group: 'engine',
      sid:
        getRawQueryParam('sid') ??
        (sessionStorageAvailable() ? sessionStorage.getItem('sid') ?? '' : ''),
      agentId: getRawQueryParam('aid') || '',
      url: window ? window.location.href : '',
      searchId: getRawQueryParam('searchid') || ''
    };
  }

  log(data: LogMessage): void {
    if (typeof window === 'undefined' || this.skipLog(data.level)) return;

    if (!data.group) data.group = tagToGroup(data.tag);
    if (!data.tabId) data.tabId = getTabId();

    this.sendMessage({
      type: 'log',
      data: {
        ...WsLoggerClient.baseMessage(),
        ...data
      }
    });
  }

  debug(tag: LogTag, msg = '', data = {}, requestId = ''): void {
    this.log({
      tag,
      msg,
      data,
      level: 'DEBUG',
      requestId
    });
  }

  info(tag: LogTag, msg = '', data = {}, requestId = ''): void {
    this.log({
      tag,
      msg,
      data,
      level: 'INFO',
      requestId
    });
  }

  notice(tag: LogTag, msg = '', data = {}, requestId = ''): void {
    this.log({
      tag,
      msg,
      data,
      level: 'NOTICE',
      requestId
    });
  }

  warning(tag: LogTag, msg = '', data = {}, requestId = ''): void {
    this.log({
      tag,
      msg,
      data,
      level: 'WARNING',
      requestId
    });
  }

  error(tag: LogTag, msg = '', data = {}, requestId = ''): void {
    this.log({
      tag,
      msg,
      data,
      level: 'ERROR',
      requestId
    });
  }

  critical(tag: LogTag, msg = '', data = {}, requestId = ''): void {
    this.log({
      tag,
      msg,
      data,
      level: 'CRITICAL',
      requestId
    });
  }
}
