import React, { FC, ReactNode } from 'react';
import { CarsApiConstructParams } from 'types/engine/cars-api-construct-params.type';
import CleanLayout from './clean-layout';
import DefaultLayout from './default-layout';
import WidgetLayout from './widget-layout';
import HpreviewLayout from './hpreview-layout';

const layouts = {
  default: DefaultLayout,
  clean: CleanLayout,
  widget: WidgetLayout,
  hpreview: HpreviewLayout
};

type Props = {
  children: ReactNode;
  initData: CarsApiConstructParams;
};

const LayoutWrapper: FC<Props> = (props) => {
  const Layout = layouts[props.initData.layout];

  if (Layout != null) {
    return <Layout {...props}>{props.children}</Layout>;
  }

  return <DefaultLayout {...props}>{props.children}</DefaultLayout>;
};

export default LayoutWrapper;
