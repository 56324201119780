import React, { FC } from 'react';
import classNames from 'classnames';
import { CarOffer } from 'src/cars/types';
import CarIcon from 'components/common/car-icon';
import styles from '../compare.module.scss';

interface Props {
  label?: string;
  labelIcon?: string;
  offers?: Array<CarOffer>;
  additionalProps?: any;
  template?: FC<any>;
  noDivider?: boolean;
}

const ComparisonRow: FC<Props> = (props) => (
  <div className={styles.comparison__table__row}>
    <div className={styles.comparison__table__label}>
      {props.labelIcon && <CarIcon icon={props.labelIcon} size="14px" />}
      {props.label}
    </div>
    <div
      className={classNames(styles.comparison__table__rowContent, {
        [styles['noDivider']]: props.noDivider
      })}
    >
      {props.offers.map((offer) => (
        <div className={styles.comparison__table__cell} key={offer.offerId}>
          {props.template({ offer, additionalProps: props.additionalProps })}
        </div>
      ))}
    </div>
  </div>
);

ComparisonRow.defaultProps = {
  label: '',
  labelIcon: '',
  noDivider: false,
  offers: [],
  additionalProps: {},
  template: null
};

export default ComparisonRow;
