import React, { FC, createContext, useState } from 'react';
import { deleteSearchParam, updateSearchParam } from 'src/url-manager';
import { defaults } from 'src/config';
import { DevData, TestMode } from 'types/engine/dev-data.type';
import { sessionStorageAvailable } from 'src/utils';
import { DevToolsContextType, DevToolsContextProps } from './types';

const devData: DevData = defaults.devData as DevData;

const devToolsContext = createContext<DevToolsContextType>({
  inhouse: devData.inhouse,
  dataPreview: devData.dataPreview,
  setDataPreview: () => null,
  testMode: devData.testMode,
  setTestMode: () => null,
  partners: devData.partners,
  setPartners: () => null,
  disableSca: devData.disableSca,
  setDisableSca: () => null,
  customProxy: devData.customProxy,
  setCustomProxy: () => null
});

const DevToolsProvider: FC<DevToolsContextProps> = ({ children, initData }) => {
  const initInhouse = initData.devData ? initData.devData.inhouse : devData.inhouse;
  const [inhouse] = useState(initInhouse);
  const [dataPreview, setDataPreview] = useState(
    initData.devData ? initData.devData.dataPreview : devData.dataPreview
  );
  const [testMode, setTestMode] = useState<TestMode>(
    initData.devData ? initData.devData.testMode : devData.testMode
  );
  const [partners, setPartners] = useState(
    initData.devData ? initData.devData.partners : devData.partners
  );
  const [disableSca, setDisableSca] = useState(
    initData.devData ? initData.devData.disableSca : devData.disableSca
  );
  const [customProxy, setCustomProxy] = useState<string>(
    (initInhouse && sessionStorageAvailable()
      ? sessionStorage.getItem('customProxy')
      : undefined) || devData.customProxy
  );

  const changeDataPreview = (enabled: boolean) => {
    setDataPreview(enabled);
    if (enabled) {
      updateSearchParam('dev_datapreview', '1');
    } else {
      deleteSearchParam('dev_datapreview');
    }
  };

  const changeTestMode = (mode: TestMode) => {
    setTestMode(mode);
    if (mode !== devData.testMode) {
      updateSearchParam('dev_testmode', mode.join('|'));
    } else {
      deleteSearchParam('dev_testmode');
    }
  };

  const changePartners = (list: Array<string>) => {
    setPartners(list);
    if (list.length > 0) {
      updateSearchParam('dev_partners', list.join('|'));
    } else {
      deleteSearchParam('dev_partners');
    }
  };

  const changeCustomProxy = (value: string) => {
    setCustomProxy(value);
    sessionStorage.setItem('customProxy', value);
  };

  return (
    <devToolsContext.Provider
      value={{
        inhouse,
        dataPreview,
        setDataPreview: changeDataPreview,
        testMode,
        setTestMode: changeTestMode,
        partners,
        setPartners: changePartners,
        disableSca,
        setDisableSca,
        customProxy,
        setCustomProxy: changeCustomProxy
      }}
    >
      {children}
    </devToolsContext.Provider>
  );
};

export { devToolsContext, DevToolsProvider };
