export type CarType =
  | 'wagon_estate'
  | 'van'
  | 'limousine'
  | 'sport'
  | 'cabrio'
  | 'suv'
  | 'coupe'
  | 'monospace'
  | 'motor'
  | '2_wheel'
  | 'roadster'
  | 'crossover'
  | 'special'
  | 'truck_van';

export const carTypeFilterCheckAgainst = [
  'wagon_estate',
  'van',
  'limousine',
  'sport',
  'cabrio',
  'suv',
  'coupe',
  'monospace',
  'motor',
  '2_wheel',
  'roadster',
  'crossover',
  'special',
  'truck_van'
];
