import { DropdownItem } from 'components/forms/types';
import { TranslateFunction } from 'providers/translations/types';
// Generate array of values for dropdown
export const generateDropdownOptions = (
  qty: number,
  addEmpty = false,
  from = 1,
  emptyVal = undefined
): Array<DropdownItem> => {
  const arr: Array<DropdownItem> = [];

  if (addEmpty) {
    arr.push({
      label: '---',
      value: emptyVal
    });
  }

  for (let i = from; i < from + qty; i += 1) {
    arr.push({
      label: i,
      value: i
    });
  }
  return arr;
};

export const createNumberArray = (
  n: number,
  start = 0,
  step = 1,
  reverse = false
): Array<number> => {
  const result: Array<number> = [];
  if (reverse) {
    for (let i = n + start - 1; i >= start; i -= step) {
      result.push(i);
    }
  } else {
    for (let i = start; i < n + start; i += step) {
      result.push(i);
    }
  }

  return result;
};

// Wrap string in array
export const ensureIsStringArray = (data: string | Array<string>): Array<string> =>
  Array.isArray(data) ? data : [data];

// Whether the first array contains all values from second array
export const arrayHasAllValues = (ref: Array<any>, compare: Array<any>) =>
  compare.every((val) => ref.indexOf(val) !== -1);

// Remove duplicates from array
export const unique = (
  items: Array<any>,
  customCompare?: (a: any, b: any) => boolean
): Array<any> => {
  const output: Array<any> = [];
  const comparator = customCompare || ((A: any, B: any) => A === B);

  items.forEach((item) => {
    if (output.findIndex((element) => comparator(element, item)) === -1) {
      output.push(item);
    }
  });
  return output;
};

export const sameArrays = (a: Array<any>, b: Array<any>): boolean => {
  if (a.length !== b.length) return false;
  return a.every((item) => b.indexOf(item) !== 0);
};

export const translateDropdownItems = (
  items: Array<DropdownItem>,
  t: TranslateFunction
): Array<DropdownItem> =>
  items.map((item) => ({
    ...item,
    label: t(item.label as string)
  }));

export const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x);
