export type CarEquip =
  | 'ac'
  | 'manualTransmission'
  | 'automaticTransmission'
  | 'gps'
  | 'drive4W'
  | 'electric'
  | 'unlimitedkm'
  | 'prepaid'
  | 'wifi'
  | 'winterTires'
  | 'additionalDriver'
  | 'noLocalFee'
  | 'hasNoAirportFee';

export const equipFilterCheckAgainst = [
  'ac',
  'manualTransmission',
  'automaticTransmission',
  'gps',
  'drive4W',
  'electric',
  'unlimitedkm',
  'prepaid',
  'winterTires',
  'additionalDriver'
];
