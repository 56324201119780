import { languageContext } from 'providers/translations';
import { useContext } from 'react';
import { UseTranslationType } from './types';

const useTranslation = (namespaces: string | Array<string> = ''): UseTranslationType => {
  const { t } = useContext(languageContext);
  const translate = (key: string, options?: any): string => {
    const nsPrefix = !Array.isArray(namespaces) && namespaces !== '' ? `${namespaces}:` : '';
    const phraseKey = nsPrefix !== '' && key.indexOf(nsPrefix) === -1 ? nsPrefix + key : key;
    return t(phraseKey, options);
  };

  return {
    t: translate
  };
};

export default useTranslation;
