import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { clone } from 'lodash';
import Checkbox from 'components/forms/checkbox';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';

import TextInput from 'components/forms/text-input';
import { TestMode } from 'types/engine/dev-data.type';
import { refreshSession } from 'src/url-manager';
import Button from 'components/forms/button';
import useCarsApi from 'providers/cars-api/use-cars-api';
import styles from './dev-tools.module.scss';
import TestmodeModal from './testmode-modal';

const DevToggles = () => {
  const router = useRouter();
  const { popupManager, globalEvents } = useCarsApi();
  const {
    inhouse,
    dataPreview,
    setDataPreview,
    testMode,
    setTestMode,
    partners,
    setPartners,
    disableSca,
    setDisableSca
  } = useDevToolsContext();

  const testModeChanged = (val: string) => {
    const testBefore = testMode.includes('TEST');
    const arr = val.replace(/ /g, '').split(',');
    setTestMode((arr.length === 1 && arr[0] === '' ? [] : arr) as TestMode);
    if (testBefore !== arr.includes('TEST')) {
      setTimeout(() => {
        refreshSession(inhouse);
        globalEvents.next({ type: 'testModeChange' });
      }, 100);
    }
  };

  const parntersChanged = (val: string) => {
    const arr = val.replace(/ /g, '').split(',');
    setPartners(arr.length === 1 && arr[0] === '' ? [] : arr);
  };

  const toggleTest = (enable: boolean) => {
    if (enable) {
      testMode.push('TEST');
    } else {
      testMode.splice(testMode.indexOf('TEST'), 1);
    }
    setTestMode(clone(testMode));
    setTimeout(() => {
      refreshSession(inhouse);
      globalEvents.next({ type: 'testModeChange' });
    }, 100);
  };

  const openTestModeModal = () => {
    popupManager.open(TestmodeModal, {});
  };

  useEffect(() => {
    const handleRouteChange = () => null;
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <div>
      <h5 className={styles.subtitle}>Settings</h5>
      <ul className={styles.settingsList}>
        <li>
          <Checkbox value={dataPreview} onChange={setDataPreview}>
            Data preview
          </Checkbox>
        </li>
        <li>
          <Checkbox value={disableSca} onChange={setDisableSca}>
            Disable SCA
          </Checkbox>
        </li>
        <li>
          <Checkbox value={testMode.includes('TEST')} onChange={toggleTest}>
            Test mode
          </Checkbox>
        </li>
        <li className={styles.settingsList__flex}>
          <TextInput
            label="Test flags"
            value={testMode.join(', ')}
            placeholder="comma separated strings"
            onChange={testModeChanged}
            horizontal
          />
          <Button onClick={openTestModeModal}>Edit</Button>
        </li>
        <li>
          <TextInput
            label="Partners"
            value={partners.join(', ')}
            placeholder="comma separated strings"
            onChange={parntersChanged}
            horizontal
          />
        </li>
      </ul>
    </div>
  );
};

export default DevToggles;
