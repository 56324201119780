import { Subject } from 'rxjs';
import { forbiddenNotificationMessages } from 'src/config';
import { prepareToken } from 'src/utils/string';
import { Translation } from 'types/engine/translation.type';
import {
  CreateNotificationProps,
  Notify,
  NotifyEvent,
  NotifyPreset,
  NotifyPresetData
} from './types';

const NOTIFY_TTL = 15;
class NotificationService {
  events: Subject<NotifyEvent>;

  list: Array<Notify>;

  autoExpire: any;

  constructor() {
    this.events = new Subject();
    this.list = [];
    this.autoExpire = {};
  }

  createNotification(props: CreateNotificationProps) {
    const id = prepareToken();
    const notify = {
      id,
      type: props.type,
      title: props.title,
      messages: (props.messages ?? []).filter(
        ({ key }) => !forbiddenNotificationMessages.includes(key)
      ),
      size: props.size || 'default',
      onExpire: new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, (props.ttl || NOTIFY_TTL) * 1000);
      })
    };
    this.autoExpire[id] = notify.onExpire.then(
      () => {
        this.removeNotify(id);
      },
      () => null
    );
    this.list.push(notify);

    this.events.next({
      type: 'add',
      notify
    });
  }

  removeNotify(id: string) {
    const index = this.list.findIndex((item) => item.id === id);
    if (index !== -1) {
      const [notify] = this.list.splice(index, 1);
      if (this.autoExpire[id]) {
        delete this.autoExpire[id];
      }
      this.events.next({
        type: 'remove',
        notify
      });
    }
  }

  createNotificationFromPreset(preset: NotifyPreset, data: NotifyPresetData = {}) {
    this.createNotification(NotificationService.getPreset(preset, data));
  }

  static preparePresetMessages = (
    mainMessage?: Translation,
    additionalMessages: Array<Translation> = []
  ): Array<Translation> =>
    [mainMessage].concat(
      Array.isArray(additionalMessages) && additionalMessages.length > 0 ? additionalMessages : []
    );

  static getPreset(preset: NotifyPreset, data: any = {}): CreateNotificationProps {
    const { title, messages } = data;
    switch (preset) {
      case 'invalidSearchParams':
        return {
          type: 'error',
          title: title || 'notification_invalid_searchform_title',
          messages: NotificationService.preparePresetMessages(
            { key: 'notification_invalid_searchform' },
            messages
          ),
          ttl: 8
        };

      case 'sessionExpired':
        return {
          type: 'warn',
          title: title || 'notification_session_expired_title',
          messages: NotificationService.preparePresetMessages(
            { key: 'notification_session_expired_message' },
            messages
          )
        };

      case 'offerNotFound':
        return {
          type: 'warn',
          title: title || 'notification_offer_not_found_title',
          messages: NotificationService.preparePresetMessages(
            { key: 'notification_offer_not_found' },
            messages
          )
        };

      case 'invalidBookingForm':
        return {
          type: 'error',
          title: title || 'notification_invalid_bookingform_title',
          messages: NotificationService.preparePresetMessages(
            { key: 'notification_invalid_bookingform' },
            messages
          ),
          size: 'large'
        };

      case 'bookingFailed':
        return {
          type: 'error',
          title: title || 'notification_booking_error_title',
          messages: NotificationService.preparePresetMessages(
            { key: 'notification_booking_error_message' },
            messages
          ),
          size: 'large',
          ttl: 20
        };

      case 'doubleBooking':
        return {
          type: 'error',
          title: title || 'notification_double_booking_title',
          messages: NotificationService.preparePresetMessages(
            { key: 'notification_double_booking' },
            messages
          ),
          size: 'large',
          ttl: 20
        };

      case 'scaProblem':
        return {
          type: 'error',
          title: title || 'notification_sca_problem_title',
          messages: NotificationService.preparePresetMessages(
            { key: 'notification_sca_problem' },
            messages
          ),
          size: 'large',
          ttl: 20
        };

      case 'paymentDeclined':
        return {
          type: 'error',
          title: title || 'notification_payment_declined_title',
          messages: NotificationService.preparePresetMessages(
            { key: 'notification_payment_declined' },
            messages
          ),
          size: 'large',
          ttl: 20
        };

      case 'offerPriceChange':
        return {
          type: 'error',
          title: title || 'notification_offer_price_changed_title',
          messages: NotificationService.preparePresetMessages(
            { key: 'notification_offer_price_changed_message', data },
            messages
          ),
          size: 'default',
          ttl: 20
        };

      case 'bookPriceChange':
        return {
          type: 'warn',
          title: title || 'notification_book_price_changed_title',
          messages: NotificationService.preparePresetMessages(
            { key: 'notification_book_price_changed_message', data },
            messages
          ),
          size: 'default',
          ttl: 20
        };

      default:
        return {
          type: 'error',
          messages: NotificationService.preparePresetMessages(
            { key: 'notification_unknown_error' },
            messages
          )
        };
    }
  }
}

export default NotificationService;
