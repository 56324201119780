import React, { FC } from 'react';
import PartnerLogo from 'components/common/partner-logo';
import useCarsApi from 'providers/cars-api/use-cars-api';
import Price from 'components/common/price';
import { CarOffer } from 'src/cars/types';
import classNames from 'classnames';

import styles from './compare.module.scss';

interface Props {
  offer: CarOffer;
}

const CompareMobileSlot: FC<Props> = ({ offer }) => {
  const { removeFromCompare } = useCarsApi();
  return (
    <button
      className={classNames(styles.compareBar__car, styles.isMobile)}
      type="button"
      onClick={() => removeFromCompare(offer.offerId)}
    >
      <div className={styles.compareBar__offerPicture}>
        <img alt={offer.name} src={offer.image.replace('75x75', '40x40')} />
      </div>
      <div className={styles.compareBar__carDetails}>
        <PartnerLogo name={offer.partner} width={40} height={12} />
        <Price className={styles.compareBar__price} price={offer.price} currency={offer.currency} />
      </div>
    </button>
  );
};

export default CompareMobileSlot;
