import React, { createContext, FC, useEffect, useState } from 'react';
import { clone } from 'lodash';
import { fallbackLocale } from 'src/config';
import { LanguageContextProps, UseTranslationType } from './types';

const languageContext = createContext<UseTranslationType>({
  t: (key: string, options?: any): string => key
});

const LanguageProvider: FC<LanguageContextProps> = ({
  children = null,
  i18n,
  locale = fallbackLocale,
  translations = []
}) => {
  const lang = (locale || fallbackLocale).replace('_', '-');
  const [loadedNss, setLoadedNss] = useState<Array<string>>([]);
  const [initialized, setInitialized] = useState(false);

  const loadTranslations = () => {
    let changed = false;
    translations.forEach((item) => {
      if (loadedNss.indexOf(item.ns) === -1) {
        i18n.addResourceBundle(lang, 'common', item.resources, true);
        loadedNss.push(item.ns);
        changed = true;
      }
    });
    if (changed) {
      setLoadedNss(clone(loadedNss));
    }
  };

  if (!initialized) {
    loadTranslations();
  }

  if (i18n.language !== lang) {
    i18n.changeLanguage(lang);
  }

  useEffect(() => {
    loadTranslations();
    if (!initialized) setInitialized(true);
  }, [loadedNss, translations]);

  return (
    <languageContext.Provider
      value={{
        t: i18n.getFixedT(lang)
      }}
    >
      {children}
    </languageContext.Provider>
  );
};

export { languageContext, LanguageProvider };
