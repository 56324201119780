import React, { FC } from 'react';
import CarIcon from 'components/common/car-icon';
import PartnerLogo from 'components/common/partner-logo';
import useCarsApi from 'providers/cars-api/use-cars-api';
import Price from 'components/common/price';
import { CarOffer } from 'src/cars/types';
import Button from 'components/forms/button';

import styles from './compare.module.scss';

interface Props {
  offer: CarOffer;
}

const CompareSlot: FC<Props> = ({ offer }) => {
  const { removeFromCompare } = useCarsApi();
  return (
    <div className={styles.compareBar__car}>
      <div className={styles.compareBar__offerPicture}>
        {offer.image && <img alt={offer.name} src={offer.image.replace('75x75', '56x40')} />}
      </div>
      <div className={styles.compareBar__carDetails}>
        <div className={styles.compareBar__carName}>{offer.name}</div>
        <PartnerLogo
          name={(offer.showSupplierLogo ? offer.supplier : null) || offer.partner}
          width={40}
          height={12}
        />
        <Price className={styles.compareBar__price} price={offer.price} currency={offer.currency} />
      </div>
      <Button
        className={styles.compareBar__remove}
        variant="secondary"
        onClick={() => removeFromCompare(offer.offerId)}
      >
        <CarIcon icon="cross" />
      </Button>
    </div>
  );
};

export default CompareSlot;
