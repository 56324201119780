import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './compare.module.scss';

const CompareEmptySlot: FC = () => (
  <div className={classNames(styles.isMobile, styles.compareBar__empty)} />
);

export default CompareEmptySlot;
