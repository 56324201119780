import { CarClass } from 'types/car-class.type';
import { CarType } from 'types/car-type.type';
import { Transmission } from 'types/transmission';

const classMapping = {
  mini_elite: 'mini',
  economy_elite: 'economy',
  compact_elite: 'compact',
  standard: 'compact',
  standard_elite: 'compact',
  intermediate_elite: 'intermediate',
  fullsize: 'intermediate',
  fullsize_elite: 'intermediate',
  premium_elite: 'premium',
  luxury_elite: 'luxury'
};
class SippCode {
  private sippMarks: Array<string>;

  cartype: CarType;

  carclass: CarClass;

  transmission: Transmission;

  drive: string;

  fuel: string;

  sipp: string;

  constructor(sipp: string) {
    this.sippMarks = sipp.split('');

    this.sippToClass();
    this.sippToType();
    this.sippToFuel();
    this.sippToTransmission();
  }

  CLASSES = {
    M: 'mini',
    N: 'mini_elite',
    E: 'economy',
    H: 'economy_elite',
    C: 'compact',
    D: 'compact_elite',
    I: 'intermediate',
    J: 'intermediate_elite',
    S: 'standard',
    R: 'standard_elite',
    F: 'fullsize',
    G: 'fullsize_elite',
    P: 'premium',
    U: 'premium_elite',
    L: 'luxury',
    W: 'luxury_elite',
    O: 'oversize',
    X: 'special'
  };

  FUEL = {
    N: 'unspecified',
    R: 'unspecified',
    D: 'diesel',
    Q: 'diesel',
    H: 'hybrid',
    I: 'hybrid',
    E: 'electric',
    C: 'electric',
    L: 'gas',
    S: 'gas',
    A: 'hydrogen',
    B: 'hydrogen',
    M: 'multi',
    F: 'multi',
    V: 'petrol',
    Z: 'petrol',
    U: 'ethanol',
    X: 'ethanol'
  };

  DRIVES = {
    M: 'normal',
    A: 'normal',
    N: '4wd',
    C: 'awd',
    B: '4wd',
    D: 'awd'
  };

  TRANSMISSION = {
    M: 'manual',
    N: 'manual',
    A: 'automatic',
    B: 'automatic',
    D: 'automatic'
  };

  TYPES = {
    B: '2_3_door',
    C: '2_4_door',
    D: '4_5_door',
    W: 'wagon_estate',
    V: 'van',
    K: 'truck_van',
    L: 'limousine',
    S: 'sport',
    T: 'cabrio',
    F: 'suv',
    E: 'coupe',
    M: 'monospace',
    H: 'motor',
    Y: '2_wheel',
    N: 'roadster',
    G: 'crossover'
  };

  sippToClass() {
    this.carclass = this.mapClass();
  }

  sippToFuel() {
    this.fuel = this.FUEL[this.sippMarks[3]];
  }

  sippToTransmission() {
    this.transmission = this.TRANSMISSION[this.sippMarks[2]];
  }

  sippToType() {
    this.cartype = this.TYPES[this.sippMarks[1]] || 'special';
  }

  private mapClass() {
    const cl = this.CLASSES[this.sippMarks[0]];
    return classMapping[cl] || cl;
  }
}

export default SippCode;

/*
  First letter
  (Size of vehicle)
                          Second letter
                          (Number of doors)
                                              Third letter
                                              (Transmission & drive)
                                                                  Fourth letter
                                                                  (Fuel & A/C)

  M = Mini                  B = 2/3 door        M = Manual drive    N = Unspecified fuel, no A/C
  N = Mini Elite            C = 2/4 door        N = Manual, 4WD     R = Unspecified fuel, A/C
  E = Economy               D = 4/5 door        C = Manual, AWD     D = Diesel, A/C
  H = Economy Elite         W = Wagon / Estate  A = Auto drive      Q = Diesel, no A/C
  C = Compact               V = Passenger Van   B = Auto, 4WD       H = Hybrid, A/C
  D = Compact Elite         L = Limousine       D = Auto, AWD       I = Hybrid, no A/C
  I = Intermediate          S = Sport                               E = Electric, A/C
  J = Intermediate Elite    T = Convertable                         C = Electric, no A/C
  S = Standard              F = SUV                                 L = LPG/Gas, A/C
  R = Standard Elite        J = Open Air All Terrain                S = LPG/Gas, no A/C
  F = Fullsize              X = Special                             A = Hydrogen, A/C
  G = Fullsize Elite        P = Pickup Regular Cab                  B = Hydrogen, no A/C
  P = Premium               Q = Pickup Extended Cab                 M = Multi fuel, A/C
  U = Premium Elite         Z = Special Offer Car                   F = Multi fuel, no A/C
  L = Luxury                E = Coupe                               V = Petrol, A/C
  W = Luxury Elite          M = Monospace                           Z = Petrol, no A/C
  O = Oversize              R = Recreational                        U = Ethanol, A/C
  X = Special               H = Motor Home                          X = Ethanol, no A/C
                            Y = 2 Wheel Vehicle
                            N = Roadster
                            G = Crossover
                            K = Commercial Van / Truck
*/
