import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';
import CarIcon from 'components/common/car-icon';

interface Props {
  offer?: CarOffer;
}

const AirConditioningCell: FC<Props> = ({ offer }) => (
  <div>
    <CarIcon icon={offer.equip.includes('ac') ? 'tick' : 'cross'} size="12px" />
  </div>
);

AirConditioningCell.defaultProps = {
  offer: null
};

export default AirConditioningCell;
