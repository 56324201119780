import { useContext } from 'react';
import { offersContext } from '.';
import { OffersContextType } from './types';

const useOffersContext = (): OffersContextType => {
  const {
    searching,
    finished,
    firstVisit,
    progress,
    searchId,
    searchData,
    offers,
    allOffers,
    stations,
    filtersRange,
    total,
    filtered,
    startSearch,
    clearSearch,
    filters,
    clearFilters,
    removeFilter,
    updateFilter,
    inCompareMode,
    updateInCompareMode,
    exchangeRates,
    pricesStartsFrom
  } = useContext(offersContext);

  return {
    searching,
    finished,
    firstVisit,
    progress,
    searchId,
    searchData,
    offers,
    allOffers,
    stations,
    filtersRange,
    total,
    filtered,
    startSearch,
    clearSearch,
    filters,
    clearFilters,
    removeFilter,
    updateFilter,
    inCompareMode,
    updateInCompareMode,
    exchangeRates,
    pricesStartsFrom
  };
};

export default useOffersContext;
