import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { currencyToSymbol, priceFormat } from 'src/utils/string';

type Props = {
  price: number;
  currency: string;
  className?: string;
  prefix?: string;
  round?: boolean;
  inline?: boolean;
};

const Price: FC<Props> = ({
  price,
  currency,
  className = undefined,
  prefix = undefined,
  round = false,
  inline = false
}) => (
  <div
    className={classNames(className, {
      inlineDisplay: inline
    })}
  >
    {prefix && <span>{`${prefix}`}</span>}
    <span>{priceFormat(price, round ? 0 : undefined)}</span>
    <span
      dangerouslySetInnerHTML={{
        __html: ` ${currencyToSymbol(currency)}`
      }}
    />
  </div>
);

export default memo(Price);
