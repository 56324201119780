import React, { FC, useState, useEffect, useRef } from 'react';
import useTranslation from 'providers/translations/use-translations';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import useIframeContext from 'providers/iframe/use-iframe-context';
import Button from 'components/forms/button';
import classNames from 'classnames';
import { range } from 'lodash';
import useOffersContext from 'providers/offers/use-offers-context';
import useCarsApi from 'providers/cars-api/use-cars-api';
import { CarOffer } from 'src/cars/types';
import Comparison from './comparison';
import CompareEmptySlot from './compare-empty-slot';
import CompareSlot from './compare-slot';
import CompareMobileSlot from './compare-mobile-slot';

import styles from './compare.module.scss';

interface Props {
  offers: Array<CarOffer>;
}

const CompareBar: FC<Props> = ({ offers }) => {
  const { popupManager } = useCarsApi();
  const { inCompareMode, updateInCompareMode } = useOffersContext();
  const { inIframe, topEdge } = useIframeContext();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { isMobile } = useScreenDetect();
  const compareBarRef = useRef(null);
  const [stickyClass, setStickyClass] = useState(false);

  const handleScroll = () => {
    if (
      (compareBarRef.current && window.pageYOffset > compareBarRef.current.offsetTop) ||
      topEdge > 120 ||
      isMobile
    ) {
      setStickyClass(true);
    } else {
      setStickyClass(false);
    }
  };

  const openCompare = () => {
    setOpen(true);
    popupManager.open(Comparison, {
      offers,
      onClose: () => {
        setOpen(false);
      }
    });
  };

  useEffect(() => {
    if (!isMobile || open) return () => null;
    if (isMobile && inIframe) return handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [open, topEdge]);

  useEffect(() => {
    if (isMobile) setStickyClass(true);
  }, []);

  return (
    <>
      {!open && (
        <div
          ref={compareBarRef}
          className={classNames(
            styles.compareBar,
            isMobile ? styles.isMobile : styles.notMobile,
            stickyClass ? styles.sticky : undefined
          )}
          style={{
            position: stickyClass ? 'fixed' : undefined,
            top: inIframe ? `${topEdge}px` : undefined
          }}
        >
          {isMobile && (
            <div className={styles.compareBar__head}>
              <h4>{t('drel_comparison')}</h4>
              <span>{t('comparison_help_text')}</span>
            </div>
          )}

          <div className={styles.compareBar__main}>
            {!isMobile && (
              <h4 className={styles.compareBar__title}>{`${t('drel_comparison')}:`}</h4>
            )}
            <ul
              className={classNames(
                styles.compareBar__list,
                isMobile ? styles.isMobile : styles.notMobile
              )}
            >
              {offers.map((offer) => (
                <li key={offer.offerId}>
                  {!isMobile && <CompareSlot offer={offer} />}
                  {isMobile && <CompareMobileSlot offer={offer} />}
                </li>
              ))}
              {isMobile &&
                range(0, 3 - offers.length).map((item, index) => (
                  <li key={`key${index}`}>
                    <CompareEmptySlot />
                  </li>
                ))}
            </ul>
          </div>

          {(offers.length > 0 || isMobile) && (
            <div className={styles.compareBar__buttons}>
              <Button disabled={offers.length === 0} variant="primary" onClick={openCompare}>
                {t('drel_show_compare')}
              </Button>
              {isMobile && (
                <Button variant="secondary" onClick={() => updateInCompareMode(!inCompareMode)}>
                  {t('drel_close')}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CompareBar;
