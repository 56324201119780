import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';
import Price from 'components/common/price';
import styles from '../compare.module.scss';

interface Props {
  offer?: CarOffer;
  additionalProps?: any;
}

const CarClassCell: FC<Props> = ({ offer, additionalProps }) => (
  <div>
    {offer && (
      <>
        {(!additionalProps || !additionalProps.perDay) && (
          <Price className={styles.offerPrice} price={offer.price} currency={offer.currency} />
        )}
        {additionalProps && additionalProps.perDay && (
          <>
            <Price
              className={styles.offerPricePerDay}
              price={offer.pricePerDay}
              currency={offer.currency}
            />
          </>
        )}
      </>
    )}
  </div>
);

CarClassCell.defaultProps = {
  offer: null,
  additionalProps: {}
};

export default CarClassCell;
