import OverlayWrapper from 'components/common/overlay-wrapper';
import Button from 'components/forms/button';
import Checkbox from 'components/forms/checkbox';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import React, { FC } from 'react';
import { clone } from 'lodash';
import { FormConfigData } from 'src/booking-engine/types';
import { TestModeFlag } from 'types/engine/dev-data.type';
import styles from './dev-tools.module.scss';

type Props = {
  onClose: (data?: FormConfigData) => void;
};

const ERROR_OPTIONS: Array<[TestModeFlag, string]> = [
  ['FCFAIL', 'Form config crash'],
  ['SCAFAIL', 'SCA request error'],
  ['SESSIONEXPIRED', 'Force session expired error'],
  ['BOOKINGERROR470', 'Booking error (470)'],
  ['BOOKINGERROR473', 'Double booking (473)'],
  ['BOOKINGERROR474', 'Payment declined (474)'],
  ['BOOKINGERROR477', 'External booking error (477)'],
  ['BOOKINGERROR478', 'Invalid booking request (478)']
];

const TestmodeModal: FC<Props> = (props) => {
  const { testMode, setTestMode } = useDevToolsContext();

  const toggle = (flag: TestModeFlag, checked: boolean) => {
    if (checked) {
      testMode.push(flag);
    } else {
      const i = testMode.indexOf(flag);
      if (i === -1) return;
      testMode.splice(i, 1);
    }
    setTestMode(clone(testMode));
  };
  return (
    <OverlayWrapper open inUse onClose={props.onClose} title="Test mode toggles">
      <div className={styles.testmodeModal}>
        <ul className={styles.testmodeModal__list}>
          {ERROR_OPTIONS.map((el) => (
            <li key={el[0]}>
              <Checkbox
                value={testMode.includes(el[0])}
                label={el[1]}
                onChange={(check) => toggle(el[0], check)}
              />
            </li>
          ))}
        </ul>
        <Button onClick={() => props.onClose()}>Close</Button>
      </div>
    </OverlayWrapper>
  );
};
export default TestmodeModal;
