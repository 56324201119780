import { FuelPolicy } from 'src/cars/types/fuel-policy.type';
import { Range } from 'types/range.type';

export type FilterType =
  | 'dayPrice'
  | 'totalPrice'
  | 'station'
  | 'pickupStation'
  | 'passengers'
  | 'doors'
  | 'fuel'
  | 'partner'
  | 'class'
  | 'carType'
  | 'carScore'
  | 'equip'
  | 'deposit'
  | 'hasDeposit';

export type PriceFilter = {
  type: 'dayPrice' | 'totalPrice';
  value: Range;
};

export type ArrayFilter<T = string> = {
  type:
    | 'station'
    | 'pickupStation'
    | 'partner'
    | 'class'
    | 'carType'
    | 'equip'
    | 'carScore'
    | 'hasDeposit';
  value: Array<T>;
};

export type ArrayNumberFilter<T = number> = {
  type: 'deposit';
  value: Array<T>;
};

/*
export type TriStates = ['0', '1', '2'];

export type TriStateFilter = {
  type: '';
  value: TriStates;
};
*/

export const offerDepositFilterCheckAgainst = ['0', '500', '1000', '1500', '2500'];
export const offerHasDepositFilterCheckAgainst = ['has-deposit', 'no-deposit'];

export type PassengersFilter = {
  type: 'passengers';
  value: number;
};

export type Doors = '2' | '2/3' | '3' | '2/4' | '4' | '4/5' | '5';
export const doorsFilterCheckAgainst = ['2', '2/3', '3', '2/4', '4', '4/5', '5'];

export type DoorsFilter = {
  type: 'doors';
  value: Doors;
};

export type FuelFilter = {
  type: 'fuel';
  value: {
    policy: Array<FuelPolicy>;
  };
};

export type Filter =
  | ArrayFilter
  | PriceFilter
  | PassengersFilter
  | DoorsFilter
  | FuelFilter
  | ArrayNumberFilter;

export type Filters = Array<Filter>;
