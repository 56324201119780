import React, { FC } from 'react';
import useCarsApi from 'providers/cars-api/use-cars-api';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SlideUp } from 'components/common/animations';
import SingleNotification from './single-notification';

import styles from './notifications.module.scss';

const Notifications: FC = () => {
  const { notifications } = useCarsApi();

  return (
    <SlideUp open={notifications.length > 0}>
      <ul className={styles.container}>
        <TransitionGroup>
          {notifications.map((notify) => (
            <CSSTransition key={notify.id} timeout={300} classNames="slideUp">
              <SingleNotification notify={notify} key={notify.id} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </ul>
    </SlideUp>
  );
};

export default Notifications;
