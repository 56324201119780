import React, { CSSProperties, memo, ReactNode } from 'react';

import WithLink from 'components/common/with-link';
import CarIcon from 'components/common/car-icon';
import classNames from 'classnames';
import { ComponentSize } from './types';

import styles from './forms.module.scss';

type ButtonProps = {
  href?: string;
  as?: string;
  withLink?: boolean;
  text?: string;
  children?: ReactNode;
  icon?: string;
  isActive?: boolean;
  disabled?: boolean;
  onClick?: (e: any) => void;

  // Appearance
  className?: string;
  style?: CSSProperties;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text';
  size?: ComponentSize;
  noUnderline?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  withLink = false,
  isActive = false,
  disabled = false,
  style = {},
  variant = 'secondary',
  size = 'default',
  noUnderline = false,
  href = undefined,
  as = undefined,
  className = undefined,
  text = undefined,
  children = undefined,
  icon = undefined,
  onClick = () => null
}) => {
  const CustomTag = href ? 'a' : 'button';

  const baseName = icon ? 'icoButton' : 'button';

  return (
    <>
      <WithLink href={href} as={as} withLink={withLink}>
        <CustomTag
          type={href ? undefined : 'button'}
          className={classNames(
            className,
            styles[baseName],
            styles[`${baseName}--${variant}`],
            `${variant}_button`,
            {
              [styles[`${baseName}--disabled`]]: disabled === true,
              [styles[`${baseName}--nounderline`]]: noUnderline,
              // [styles[`${baseName}--disabled`]]: noUnderline,
              [styles[`${baseName}--active`]]: isActive,
              [styles[`${baseName}--${size}`]]: size !== 'default'
            }
          )}
          disabled={disabled}
          style={style}
          onClick={onClick}
        >
          {icon ? <CarIcon icon={icon} size="1em" /> : <>{children ? children : text}</>}
        </CustomTag>
      </WithLink>
    </>
  );
};

export default memo(Button);
