import Router from 'next/router';
import { ParsedUrlQuery } from 'querystring';

export const getQueryParam = (name: string): null | string => {
  if (typeof window === 'undefined') return null;
  const { query } = Router;
  return query[name] as string;
};

export const getQueryParams = (): any => {
  if (typeof window === 'undefined') return null;
  const { query } = Router;
  return query;
};

export const getQueryParamsList = (): Array<{ name: string; value: string }> => {
  if (typeof window === 'undefined') return [];
  const { query } = Router;
  const list: Array<{ name: string; value: string }> = [];
  Object.keys(query).forEach((key) => {
    list.push({
      name: key,
      value: query[key] as string
    });
  });
  return list;
};

export const setQueryParam = (name: string, value: string) => {
  if (typeof window === 'undefined') return;

  const { query, pathname } = Router;
  query[name] = value;

  Router.push({
    pathname,
    query
  });
};

export const setQueryParams = (params: Array<{ name: string; value: string }>) => {
  if (typeof window === 'undefined') return;

  const { query, pathname } = Router;
  params.forEach((item) => {
    query[item.name] = item.value;
  });

  Router.push({
    pathname,
    query
  });
};

export const removeQueryParam = (name: string) => {
  if (typeof window === 'undefined') return;

  const { query, pathname } = Router;
  delete query[name];

  Router.push({
    pathname,
    query
  });
};

export const removeQueryParams = (params: Array<string>) => {
  if (typeof window === 'undefined') return;

  const { query, pathname } = Router;
  params.forEach((name) => {
    delete query[name];
  });

  Router.push({
    pathname,
    query
  });
};

export const queryArrayToObject = (
  params: Array<{ name: string; value: string }> = []
): ParsedUrlQuery => {
  const query = {};

  params.forEach((item) => {
    query[item.name] = item.value;
  });
  return query;
};

export const filterQuery = (query: ParsedUrlQuery, allowed: Array<string> = []): ParsedUrlQuery => {
  const params: Array<{ name: string; value: string }> = [];
  Object.keys(query).forEach((key) => {
    if (allowed.indexOf(key) !== -1) {
      params.push({ name: key, value: String(query[key]) });
    }
  });
  return queryArrayToObject(params);
};

export const extractQueryString = (url: string) => {
  const { search } = new URL(url);
  return search;
};

export const localeFromPath = (pathname: string, defaultValue: any = 'xx_XX') => {
  const match = pathname.match(/^\/(?<lang>[a-z]{2})_(?<country>[A-Z]{2})?/gim);
  return match ? match[0].replace('/', '') : defaultValue;
};

export const pathWithoutLocale = (pathname: string) => {
  const match = pathname.match(/^\/(?<lang>[a-z]{2})_(?<country>[A-Z]{2})?/gim);
  if (!match) return pathname;
  const locale = match[0];
  return pathname.replace(locale, '/').replace('//', '/');
};

export const getRawQueryParam = (name: string): null | string => {
  if (typeof window === 'undefined') return null;
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
};
