import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';
import PartnerLogo from 'components/common/partner-logo';
import classNames from 'classnames';
import styles from '../compare.module.scss';

interface Props {
  offer?: CarOffer;
  additionalProps?: any;
}

const CarPartnerCell: FC<Props> = ({ offer, additionalProps }) => {
  const { supplier } = offer;
  const { partner } = offer;
  const printType = additionalProps && additionalProps.type ? additionalProps.type : 'partner';

  if (offer?.showSupplierLogo !== true) return null;

  return (
    <div className={classNames(styles.comparison__partnerLogoContainer)}>
      <PartnerLogo name={printType === 'supplier' && supplier ? supplier : partner} />
    </div>
  );
};

CarPartnerCell.defaultProps = {
  offer: null,
  additionalProps: {}
};

export default CarPartnerCell;
