import React, { FC, createContext, useEffect } from 'react';
import useState from 'react-usestateref';
import { get, clone, cloneDeep } from 'lodash';
import SubpageType from 'types/engine/subpage-type.type';
import { Notify } from 'modules/notifications/types';

import { Agent } from 'types/agent/agent.type';
import { GeneralConfig } from 'types/engine/general-config.type';
import { CarOffer } from 'src/cars/types';
import { Subject } from 'rxjs';
import { randId, sessionStorageAvailable } from 'src/utils';
import { Timer } from 'src/utils/timer';
import FrontendError from 'src/frontend-error.class';
import { wsInfo, wsWarning } from 'src/logger';
import { getBrowserData } from 'src/logger/utils';
import { updateSearchParam } from 'src/url-manager';
import { ensureIsStringArray } from 'src/utils/array';
import NotificationService from 'modules/notifications/notification-service';
import useNonInitialEffect from 'hooks/use-non-initial-effect';
import moment, { Moment } from 'moment';
import { secondsDiff } from 'src/utils/date-time';
import { getSessionStatus } from 'src/requests';
import { getQueryParam } from 'src/utils/url-tools';
import { useRouter } from 'next/router';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import { CarsContextProps, CarsContextType, GlobalEvent, PopupItem } from './types';
import { defaultContextValue, processError, trimCompareArray } from './helpers';
import { getTabId, hasTabId, initTabId } from './utils';

const carsContext = createContext<CarsContextType>(defaultContextValue);

const CarsProvider: FC<CarsContextProps> = ({ children, initData, layoutStyle }) => {
  const router = useRouter();
  const { customProxy } = useDevToolsContext();

  const [sessionTtl, setSessionTtl] = useState(initData.sessionStatus?.ttl ?? 0);
  const [sessionExpireAt, setSessionExpireAt] = useState<Moment>(
    initData.sessionStatus?.ttl ? moment().add(initData.sessionStatus?.ttl, 'seconds') : undefined
  );
  const [globalEvents] = useState<Subject<GlobalEvent>>(new Subject<GlobalEvent>());
  const [notifyService] = useState<NotificationService>(new NotificationService());
  const [notifications, setNotifications] = useState<Array<Notify>>([]);
  const [agent] = useState<Agent>(initData.agent);
  const [countries] = useState(initData.countries);
  const [general, setGeneral] = useState<GeneralConfig>(initData.general);
  const [subpage, setSubpage] = useState<SubpageType>(initData.subpage);
  const [compare, setCompare] = useState<Array<CarOffer>>([]);
  const [popups, setPopups, popupsRef] = useState<Array<PopupItem>>([]);

  const watchEvents = (type: string | Array<string>): Subject<GlobalEvent> => {
    const subscription = new Subject<GlobalEvent>();
    globalEvents.subscribe((e) => {
      if (ensureIsStringArray(type).indexOf(e.type) === -1) return;
      subscription.next(e);
    });
    return subscription;
  };

  if (initData.subpage !== subpage) {
    setSubpage(initData.subpage);
  }

  const handleError = (err: FrontendError) => {
    processError(err, notifyService, globalEvents, get(initData, 'general.inhouse', false));
  };

  const changeCor = (cor: string) => {
    setGeneral({ ...general, cor });
    updateSearchParam('cor', cor);
    globalEvents.next({ type: 'corChanged', data: cor });
  };

  const addToCompare = (offer: CarOffer) => {
    setCompare(trimCompareArray(cloneDeep(compare).concat([offer])));
  };

  const removeFromCompare = (oid: string) => {
    const index = compare.findIndex((item) => item.offerId === oid);
    if (index !== -1) {
      compare.splice(index, 1);
      setCompare(cloneDeep(compare));
    }
  };

  const clearCompare = () => {
    setCompare([]);
  };

  const updateSessionTtl = () => {
    getSessionStatus(getQueryParam('searchid'), customProxy).then((status) => {
      setSessionTtl(status?.ttl ?? 0);
      setSessionExpireAt(status?.ttl ? moment().add(status?.ttl, 'seconds') : undefined);
    });
  };

  const openPopup = (component: any, props: any) => {
    popups.push({
      id: randId(),
      component,
      props
    });
    setPopups(cloneDeep(popups));
  };

  const closePopup = (e: any, item: PopupItem) => {
    if (item.props.onClose) item.props.onClose(e);
    const i = popupsRef.current.findIndex((el) => el.id === item.id);
    if (i !== -1) {
      popupsRef.current.splice(i, 1);
      setPopups(cloneDeep(popupsRef.current));
    }
  };

  useEffect(() => {
    const notifyChanges = notifyService.events.subscribe(() => {
      setNotifications(clone(notifyService.list));
    });

    return () => {
      notifyChanges.unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.onerror = (message, source, lineno, colno, error) => {
      if (message === 'Script error.') return false;
      wsWarning('documentError', String(message), { message, source, lineno, colno, error });
      return false;
    };
  }, []);

  useEffect(() => {
    if (initData.general) {
      if (sessionStorageAvailable()) sessionStorage.setItem('sid', initData.general.sid);
      updateSearchParam('sid', initData.general.sid);
    }

    const timer = new Timer();
    let tabId = getTabId();
    if (!hasTabId()) {
      const browserData = getBrowserData();
      tabId = initTabId();
      wsInfo('visitLog', `Open new tab - ${subpage} - ${browserData.browser.name}`, {
        ...browserData,
        tabId,
        referrer: document.referrer
      });
    } else {
      wsInfo('visitLog', `Exisiting tab refreshed - ${subpage}`, { tabId });
    }

    const handleUnload = () => {
      timer.stop();
      wsInfo('visitLog', `Leaving browser tab after ${timer.toSecondsString(1)}`, {
        url: window.location.href,
        tabId
      });
    };

    window.onbeforeunload = handleUnload;
  }, []);

  useNonInitialEffect(() => {
    wsInfo('visitLog', `Go to: ${subpage}`);
    setSessionTtl(initData.sessionStatus.ttl ?? 0);
  }, [subpage]);

  useEffect(() => {
    setTimeout(() => {
      const seconds = sessionExpireAt ? secondsDiff(moment(), sessionExpireAt) : 0;
      setSessionTtl(seconds > 0 ? seconds : 0);
    }, 1000);
  }, [sessionTtl]);

  useEffect(() => {
    const handleRouteChange = () => {
      const cor = getQueryParam('cor');
      if (cor && cor !== general.cor) {
        setGeneral({
          ...general,
          cor
        });
      }

      // setSearchId(getQueryParam('searchid') || '');
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <carsContext.Provider
      value={{
        sessionTtl,
        updateSessionTtl,
        setSessionExpireAt,
        globalEvents,
        watchEvents,
        agent,
        general,
        countries,
        notifications,
        notificationService: notifyService,
        popupManager: {
          open: openPopup
        },
        subpage,
        setSubpage,
        changeCor,
        handleError,
        compare,
        addToCompare,
        removeFromCompare,
        clearCompare
      }}
    >
      {children}
      {popups.length > 0 && (
        <div className={layoutStyle.popupWrapper}>
          {popups.map((item) => (
            <item.component key={item.id} {...item.props} onClose={(e) => closePopup(e, item)} />
          ))}
        </div>
      )}
    </carsContext.Provider>
  );
};

export { carsContext, CarsProvider };
