import { DropdownItem } from 'components/forms/types';
import { CustomHeaders } from 'types/agent/custom-headers.type';
import { CustomTracking } from 'types/agent/custom-tracking.type';
import { LayoutSettings } from 'types/layout/layout-settings.type';
import { AgentSpecialSwitches } from 'types/agent/agent-switches.type';

/* --------------------------------------------------------------------------------- DEFAULTS --- */

// Default environment params
export const defaults = {
  port: 3000,
  apiUrl: 'https://test-car.ypsilon.net/carapi/v1/',
  logLevel: 'DEBUG',
  loggerDbHost: '',
  loggerDbName: '',
  loggerDbCollection: '',
  redisUri: 'redis://localhost:6379/1',
  devData: {
    inhouse: false,
    dataPreview: false,
    testMode: [],
    partners: [],
    customProxy: ''
  }
};

// Avaliable countries
// prettier-ignore
export const countries = [
  'AF', 'EG', 'AL', 'DZ', 'AD', 'AO', 'AG', 'GQ', 'AR', 'AM', 'AZ', 'ET', 'AU', 'BS', 'BH', 'BD',
  'BB', 'BY', 'BE', 'BZ', 'BJ', 'BT', 'MM', 'BO', 'BA', 'BW', 'BR', 'BN', 'BG', 'BF', 'BI', 'CL',
  'CN', 'CR', 'DK', 'DE', 'DM', 'DO', 'DJ', 'EC', 'MK', 'SV', 'CI', 'ER', 'EE', 'FJ', 'FI', 'FR',
  'LI', 'GA', 'GM', 'GE', 'GH', 'GD', 'GR', 'GT', 'GN', 'GW', 'GY', 'HT', 'HN', 'IN', 'ID', 'IQ',
  'IR', 'IE', 'IS', 'IL', 'IT', 'JM', 'JP', 'YE', 'JO', 'KH', 'CM', 'CA', 'CV', 'KZ', 'QA', 'KE',
  'KG', 'KI', 'CO', 'KM', 'CG', 'XK', 'HR', 'CU', 'KW', 'LA', 'LS', 'LV', 'LB', 'LR', 'LY', 'LT',
  'LU', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MA', 'MR', 'MU', 'MX', 'MD', 'MC', 'MN', 'ME', 'MZ',
  'NA', 'NR', 'NP', 'NZ', 'NL', 'NE', 'NG', 'NI', 'KP', 'NO', 'OM', 'AT', 'PK', 'PA', 'PG', 'PY',
  'PE', 'PH', 'PL', 'PT', 'KR', 'ZA', 'RW', 'RO', 'RU', 'KN', 'ZM', 'WS', 'SM', 'ST', 'SA', 'SE',
  'CH', 'SN', 'RS', 'SC', 'SL', 'ZW', 'SG', 'SK', 'SI', 'SB', 'SO', 'ES', 'LK', 'LC', 'VC', 'VA',
  'SD', 'SS', 'SR', 'SZ', 'SY', 'TJ', 'TW', 'TZ', 'TH', 'TL', 'TG', 'TO', 'TT', 'TD', 'CZ', 'TN',
  'TR', 'TM', 'TV', 'UG', 'UA', 'HU', 'UY', 'US', 'UZ', 'VU', 'VE', 'AE', 'GB', 'VN', 'CD', 'CF',
  'CY'
];

export const proxyAllowedRoutes: Array<{ pattern: string; method: string }> = [
  { pattern: '^/lang$', method: 'POST' },
  { pattern: '^/suggester$', method: 'POST' },
  { pattern: '^/search$', method: 'POST' },
  { pattern: '^/offers$', method: 'POST' },
  { pattern: '^/offers/partner$', method: 'POST' },
  { pattern: '^/blocks/partner$', method: 'POST' },
  { pattern: '^/form$', method: 'POST' },
  { pattern: '^/sca/auth$', method: 'POST' },
  { pattern: '^/booking$', method: 'POST' },
  { pattern: '^/confirmation$', method: 'POST' },
  { pattern: '^/cancel_booking$', method: 'POST' },
  { pattern: '^/terms$', method: 'POST' },
  { pattern: '^/addons$', method: 'POST' },
  { pattern: '^/offers/ws$', method: 'GET' },
  { pattern: '^/session-status', method: 'POST' },
  { pattern: '^/headers', method: 'POST' },
  { pattern: '^/headerspreview/login', method: 'POST' },
  { pattern: '^/headerspreview/login/refresh', method: 'POST' },
  { pattern: '^/headerspreview/html', method: 'POST' },
  { pattern: '^/headerspreview/save', method: 'POST' },
  { pattern: '^/headerspreview/cached', method: 'POST' },
  { pattern: '^/banner$', method: 'POST' }
];

export const urlDateFormat = 'YYYY-MM-DD';

export const noImageUrl = '/img/no-car-image.png';

export const states = {
  US: [
    {
      name: 'Alabama',
      code: 'AL'
    },
    {
      name: 'Alaska',
      code: 'AK'
    },
    {
      name: 'Arizona',
      code: 'AZ'
    },
    {
      name: 'Arkansas',
      code: 'AR'
    },
    {
      name: 'California',
      code: 'CA'
    },
    {
      name: 'Colorado',
      code: 'CO'
    },
    {
      name: 'Connecticut',
      code: 'CT'
    },
    {
      name: 'Delaware',
      code: 'DE'
    },
    {
      name: 'Florida',
      code: 'FL'
    },
    {
      name: 'Georgia',
      code: 'GA'
    },
    {
      name: 'Hawaii',
      code: 'HI'
    },
    {
      name: 'Idaho',
      code: 'ID'
    },
    {
      name: 'Illinois',
      code: 'IL'
    },
    {
      name: 'Indiana',
      code: 'IN'
    },
    {
      name: 'Iowa',
      code: 'IA'
    },
    {
      name: 'Kansas',
      code: 'KS'
    },
    {
      name: 'Kentucky',
      code: 'KY'
    },
    {
      name: 'Louisiana',
      code: 'LA'
    },
    {
      name: 'Maine',
      code: 'ME'
    },
    {
      name: 'Maryland',
      code: 'MD'
    },
    {
      name: 'Massachusetts',
      code: 'MA'
    },
    {
      name: 'Michigan',
      code: 'MI'
    },
    {
      name: 'Minnesota',
      code: 'MN'
    },
    {
      name: 'Mississippi',
      code: 'MS'
    },
    {
      name: 'Missouri',
      code: 'MO'
    },
    {
      name: 'Montana',
      code: 'MT'
    },
    {
      name: 'Nebraska',
      code: 'NE'
    },
    {
      name: 'Nevada',
      code: 'NV'
    },
    {
      name: 'New Hampshire',
      code: 'NH'
    },
    {
      name: 'New Jersey',
      code: 'NJ'
    },
    {
      name: 'New Mexico',
      code: 'NM'
    },
    {
      name: 'New York',
      code: 'NY'
    },
    {
      name: 'North Carolina',
      code: 'NC'
    },
    {
      name: 'North Dakota',
      code: 'ND'
    },
    {
      name: 'Ohio',
      code: 'OH'
    },
    {
      name: 'Oklahoma',
      code: 'OK'
    },
    {
      name: 'Oregon',
      code: 'OR'
    },
    {
      name: 'Pennsylvania',
      code: 'PA'
    },
    {
      name: 'Rhode Island',
      code: 'RI'
    },
    {
      name: 'South Carolina',
      code: 'SC'
    },
    {
      name: 'South Dakota',
      code: 'SD'
    },
    {
      name: 'Tennessee',
      code: 'TN'
    },
    {
      name: 'Texas',
      code: 'TX'
    },
    {
      name: 'Utah',
      code: 'UT'
    },
    {
      name: 'Vermont',
      code: 'VT'
    },
    {
      name: 'Virginia',
      code: 'VA'
    },
    {
      name: 'Washington',
      code: 'WA'
    },
    {
      name: 'West Virginia',
      code: 'WV'
    },
    {
      name: 'Wisconsin',
      code: 'WI'
    },
    {
      name: 'Wyoming',
      code: 'WY'
    }
  ],
  CA: [
    {
      name: 'Alberta',
      code: 'AB'
    },
    {
      name: 'British Columbia',
      code: 'BC'
    },
    {
      name: 'Manitoba',
      code: 'MB'
    },
    {
      name: 'New Brunswick',
      code: 'NB'
    },
    {
      name: 'Newfoundland and Labrador',
      code: 'NL'
    },
    {
      name: 'Nova Scotia',
      code: 'NS'
    },
    {
      name: 'Ontario',
      code: 'ON'
    },
    {
      name: 'Prince Edward Island',
      code: 'PE'
    },
    {
      name: 'Quebec',
      code: 'QC'
    },
    {
      name: 'Saskatchewan',
      code: 'SK'
    },
    {
      name: 'Yukon',
      code: 'YT'
    }
  ]
};

/* -------------------------------------------------------------------- URL PARAMS DEFINITION --- */

// Params available for all subpages (except login)
export const baseUrlParams = [
  'aid',
  'sid',
  'afid',
  'currency',
  'cor',
  'searchid',
  'resize_origin',
  'returnTo',
  'headersPreviewToken'
];

export const devUrlParams = [
  'dev_testmode',
  'dev_partners',
  'dev_datapreview',
  'confirmation_surname',
  'confirmation_email'
];

// Filters
export const filterUrlParams = [
  'day_price',
  'total_price',
  'station',
  'passengers',
  'doors',
  'fuel',
  'partner',
  'class',
  'car_type',
  'car_score',
  'equip',
  'deposit',
  'has_deposit'
];

// Search params
export const searchUrlParams = [
  'pckloc',
  'pckdate',
  'pcktime',
  'drploc',
  'drpdate',
  'drptime',
  'driver_age',
  'sortby',
  'sortdir',
  'matchid'
].concat(baseUrlParams, filterUrlParams);

// JSON feed params
export const jsonFeedUrlParams = ['limit', 'lang', 'partners', 'timeout'].concat(
  searchUrlParams,
  filterUrlParams
);

export const bookingUrlParams = ['oid'].concat(searchUrlParams);

export const confirmationUrlParams = ['userkey'].concat(baseUrlParams);

// Array contains all available params to filter out incorrect ones
export const allowedUrlParams = confirmationUrlParams;

// List of locales supported by langtool
export const availableLocales = [
  'en_GB',
  'pl_PL',
  'ru_RU',
  'tr_TR',
  'es_ES',
  'fr_FR',
  'it_IT',
  'cs_CZ'
];

export const fallbackLocale = 'de_DE';

/* ----------------------------------------------------------------------------------- OTHERS --- */

export const genderOptions: Array<DropdownItem> = [
  { label: 'mr', value: 'MR' },
  { label: 'mrs', value: 'MRS' }
];

export const personTitleOptions: Array<DropdownItem> = [
  { label: 'drel_dr', value: 'DR' },
  { label: 'drel_prof', value: 'PROF' },
  { label: 'drel_prof_dr', value: 'PROF DR' }
];

export const forbiddenNotificationMessages: Array<any> = [
  'Unknown error',
  'undefined',
  undefined,
  null
];
/* ----------------------------------------------------------------------------- LAYOUT THEME --- */

// Reference layout settings - extendable by agent custom settings
export const defaultLayoutSettings: LayoutSettings = {
  // General
  updateIframeUrl: false,
  profilesLogin: false,
  dynamicBackground: false,

  // Searchform settings
  searchform: {
    advanced: false,
    minDaysToBooking: 3,
    minDuration: 0
  },

  // Offerlist
  offerlist: {
    orderBy: 'price',
    orderDirection: 'asc'
  },

  // Booking form
  bookingForm: {
    sca: false
  },

  // Advanced
  disableDevTools: false,

  // Widgets
  widgets: {
    test: true
  }
};

export const defaultCustomHeaders: CustomHeaders = {
  meta: [],
  css: [],
  js: [],
  header: '',
  footer: ''
};

export const defaultCustomTracking: CustomTracking = {
  search: '',
  pricelist: '',
  booking: '',
  confirmation: ''
};

export const agentSpecialSwitches: AgentSpecialSwitches = {
  rciprivileg: {
    bf_hideCompanyFields: true
  },
  ptgmainz: {
    bf_hideCompanyFields: true
  },
  ptgsparkassekoelnbonn: {
    bf_hideCompanyFields: true
  },
  rci: {
    bf_hideCompanyFields: true
  },
  ptgdb: {
    bf_hideCompanyFields: true
  },
  ptgbarclays: {
    bf_hideCompanyFields: true
  },
  ptgmbb: {
    bf_hideCompanyFields: true
  },
  ptgbestkonto: {
    bf_hideCompanyFields: true
  },
  ptghvb: {
    bf_hideCompanyFields: true
  },
  ptggiroxtra: {
    bf_hideCompanyFields: true
  },
  ptgapobank: {
    bf_hideCompanyFields: true
  },
  ptgvbrb: {
    bf_hideCompanyFields: true
  },
  ptgspkz: {
    bf_hideCompanyFields: true
  },
  ptgsbec: {
    bf_hideCompanyFields: true
  },
  ptgeuro: {
    bf_hideCompanyFields: true
  },
  tsprint: {
    dynamicBackground: true
  },
  mietwagende: {
    dynamicBackground: true
  }
};
