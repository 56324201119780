import getConfig from 'next/config';

export type NextAppConfig = {
  publicRuntimeConfig: any;
  serverRuntimeConfig: any;
};

const safeNextConfig = (): NextAppConfig => {
  const config = getConfig();
  return config !== undefined
    ? config
    : {
        publicRuntimeConfig: {},
        serverRuntimeConfig: {}
      };
};

export default safeNextConfig;
