import React, { FC, useEffect } from 'react';
import useTranslation from 'providers/translations/use-translations';
import { CarOffer } from 'src/cars/types';
import useCarsApi from 'providers/cars-api/use-cars-api';
import OverlayWrapper from 'components/common/overlay-wrapper';
import ComparisonRow from './comparison-row';
import RemoveCell from './remove-cell';
import NameCell from './name-cell';
import CarClassCell from './car-class-cell';
import CarPartnerCell from './car-partner-cell';
import PriceCell from './price-cell';
import FuelPolicyCell from './fuel-policy-cell';
import DistancePolicyCell from './distance-policy-cell';
import GearboxCell from './gearbox-cell';
import DoorCell from './door-cell';
import AirConditioningCell from './ac-cell';
import BaggageCell from './baggage-cell';
import Drive4WCell from './drive-4w-cell';
import GoToBookingCell from './go-to-booking-cell';
import GPSCell from './gps-cell';
import CarImageCell from './car-image-cell';

import styles from '../compare.module.scss';

interface Props {
  offers?: Array<CarOffer>;
  onClose?: () => void;
}

const Comparison: FC<Props> = ({ offers, onClose }) => {
  const { t } = useTranslation();
  const { subpage } = useCarsApi();

  useEffect(() => {
    if (subpage !== 'offerlist') {
      onClose();
    }
  }, [subpage]);

  return (
    <OverlayWrapper
      className={styles.comparison}
      title={t('drel_comparison')}
      open
      closeBtn
      inUse
      onClose={onClose}
    >
      <div className={styles.comparison__table}>
        <ComparisonRow offers={offers} template={NameCell} />
        <ComparisonRow offers={offers} template={CarImageCell} />
        <ComparisonRow offers={offers} template={CarClassCell} />
        <ComparisonRow label={t('partner')} offers={offers} template={CarPartnerCell} />
        <ComparisonRow
          offers={offers}
          label={t('supplier')}
          template={CarPartnerCell}
          additionalProps={{
            type: 'supplier'
          }}
        />
        <ComparisonRow label={t('total_price')} offers={offers} template={PriceCell} />
        <ComparisonRow
          label={t('price_day')}
          offers={offers}
          template={PriceCell}
          additionalProps={{ perDay: true }}
        />
        <ComparisonRow
          label={t('fuel_policy')}
          labelIcon="gasoline-pump-3"
          offers={offers}
          template={FuelPolicyCell}
        />
        <ComparisonRow
          label={t('0nc_limited_km')}
          labelIcon="distance"
          offers={offers}
          template={DistancePolicyCell}
        />
        <ComparisonRow offers={offers} label={t('gearbox')} template={GearboxCell} />
        <ComparisonRow
          offers={offers}
          labelIcon="doors"
          label={t('0nc_door_amount')}
          template={DoorCell}
        />
        <ComparisonRow
          offers={offers}
          labelIcon="ac"
          label={t('filters_ac')}
          template={AirConditioningCell}
        />
        <ComparisonRow offers={offers} labelIcon="nav" label={t('a_gps')} template={GPSCell} />
        <ComparisonRow
          offers={offers}
          labelIcon="suitcase2"
          label={t('a_kuffer')}
          template={BaggageCell}
        />
        <ComparisonRow
          offers={offers}
          labelIcon="awd"
          label={t('filters_4_wheel')}
          template={Drive4WCell}
        />
        <ComparisonRow offers={offers} template={GoToBookingCell} />
        <ComparisonRow offers={offers} labelIcon="" template={RemoveCell} noDivider />
      </div>
    </OverlayWrapper>
  );
};

Comparison.defaultProps = {
  onClose: () => null,
  offers: []
};

export default Comparison;
