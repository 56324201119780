import React, { FC } from 'react';

import useCarsApi from 'providers/cars-api/use-cars-api';
import useTranslation from 'providers/translations/use-translations';
import { CarOffer } from 'src/cars/types';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import Button from 'components/forms/button';
import CarIcon from 'components/common/car-icon';

import styles from '../compare.module.scss';

interface Props {
  offer?: CarOffer;
}

const RemoveCell: FC<Props> = ({ offer }) => {
  const { removeFromCompare } = useCarsApi();
  const { t } = useTranslation();
  const { isMobile } = useScreenDetect();
  return (
    <Button
      className={styles.comparison__remove}
      variant="secondary"
      onClick={() => removeFromCompare(offer.offerId)}
    >
      {!isMobile && <span>{t('drel_remove_compare')}</span>}
      <CarIcon icon="trash-empty" />
    </Button>
  );
};

RemoveCell.defaultProps = {
  offer: null
};

export default RemoveCell;
