import axiosInstance from 'src/axios-instance';
import { proxyUrl } from 'src/utils';
import { SessionStatus } from 'types';
import { AgentStatus } from 'types/agent/agent-status.type';

export const getAgentStatus = (aid: string, customProxy = ''): Promise<AgentStatus> =>
  new Promise((resolve) => {
    axiosInstance.get<AgentStatus>(`${proxyUrl(customProxy)}agent-status/${aid}`).then(
      (res) => {
        resolve(res.data);
      },
      () => {
        resolve('not_exists');
      }
    );
  });

export const getSessionStatus = (sessionId: string, customProxy = ''): Promise<SessionStatus> => {
  const emptySession = {
    sessionId: '',
    ttl: 0
  };
  if (!sessionId) return Promise.resolve(emptySession);
  return new Promise((resolve) => {
    axiosInstance.post(`${proxyUrl(customProxy)}session-status`, { sessionId }).then(
      (res) => {
        resolve({
          sessionId,
          ttl: res.data?.ttl
        });
      },
      () => resolve(emptySession)
    );
  });
};

export const test = 1;
