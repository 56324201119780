import React, { useEffect, useState } from 'react';

import parse from 'html-react-parser';
import useTheme from 'providers/theme/use-theme';
import useTracking from 'providers/tracking/use-tracking';
import { parseScriptTags } from 'src/utils/string';
import Head from 'next/head';

const AgentCustomHeader = () => {
  const { trackingCode } = useTracking();
  const { customHeaders } = useTheme();
  const [header, setHeader] = useState(customHeaders.header);

  useEffect(() => {
    setHeader(customHeaders.header);
  }, [customHeaders]);

  if (customHeaders.header === '') {
    return null;
  }

  return (
    <div className="header__agent-header">
      {parse(header)}
      {trackingCode.html !== '' && parse(trackingCode.html)}
      <Head>
        {trackingCode.js !== '' &&
          parseScriptTags(trackingCode.js).map((attrs, i) => (
            <script
              key={`tracking-script-${trackingCode.key}-${i}`}
              src={attrs.src}
              dangerouslySetInnerHTML={{
                __html: `/* Custom tracking script no.${i} */${attrs.content}`
              }}
            />
          ))}
      </Head>
    </div>
  );
};

export default AgentCustomHeader;
