import { cloneDeep, uniq } from 'lodash';
import { getSearchParams, updateSearchParams } from 'src/url-manager';
import { filterUrlParams } from 'src/config';
import {
  ArrayFilter,
  ArrayNumberFilter,
  Filter,
  Filters,
  FilterType,
  FuelFilter,
  PassengersFilter,
  PriceFilter
} from './types';

export const filterParamsMap = {
  dayPrice: 'day_price',
  totalPrice: 'total_price',
  station: 'station',
  passengers: 'passengers',
  doors: 'doors',
  fuel: 'fuel',
  partner: 'partner',
  class: 'class',
  carType: 'car_type',
  carScore: 'car_score',
  equip: 'equip',
  deposit: 'deposit',
  hasDeposit: 'has_deposit'
};

export const paramNameToFilterType = (name: string): FilterType =>
  (Object.keys(filterParamsMap).find((key) => filterParamsMap[key] === name) as FilterType) ||
  (name as FilterType);

const filterValueToString = (filter: Filter): string => {
  if (!filter) return '';

  switch (filter.type) {
    case 'dayPrice':
    case 'totalPrice':
      return [(filter as PriceFilter).value.min, (filter as PriceFilter).value.max].join('|');

    case 'station':
    case 'pickupStation':
    case 'partner':
    case 'class':
    case 'carType':
    case 'equip':
    case 'carScore':
    case 'hasDeposit':
      return (filter as ArrayFilter).value.join('|');

    case 'deposit':
      return (filter as ArrayNumberFilter).value.join('|');

    case 'fuel':
      return uniq((filter as FuelFilter).value.policy).join('|');

    default:
      return String(filter.value);
  }
};

export const stringToFilter = (type: FilterType, value: string): Filter => {
  switch (type) {
    case 'dayPrice':
    case 'totalPrice':
      const [min, max] = value.split('|');
      return {
        type,
        value: { min: Number(min), max: Number(max) }
      } as PriceFilter;
    case 'fuel':
      return {
        type,
        value: {
          policy: uniq(value.split('|'))
        }
      } as FuelFilter;
    case 'station':
    case 'pickupStation':
    case 'partner':
    case 'class':
    case 'carType':
    case 'equip':
    case 'carScore':
    case 'hasDeposit':
      return {
        type,
        value: uniq(value.split('|'))
      } as ArrayFilter;
    case 'deposit':
      return {
        type,
        value: uniq(value.split('|')).map((x) => parseInt(x))
      } as ArrayNumberFilter;
    case 'passengers':
      return {
        type,
        value: parseInt(value, 10)
      } as PassengersFilter;
    default:
      return undefined;
  }
};

export const initFilters = (): Filters =>
  getSearchParams(filterUrlParams)
    .map((param) => stringToFilter(paramNameToFilterType(param.name), param.value))
    .filter((item) => item !== undefined);

export const updateFiltersUrlQuery = (filters: Filters) => {
  updateSearchParams(
    Object.keys(filterParamsMap).map((key) => {
      const filter = filters.find((item) => item.type === key);
      return {
        name: filterParamsMap[key],
        value: filter ? filterValueToString(filter) : undefined
      };
    })
  );
};

export const removeSingleFilter = (filters: Filters, type: FilterType): Filters => {
  const i = filters.findIndex((filter) => filter.type === type);
  if (i !== -1) {
    filters.splice(i, 1);
    updateFiltersUrlQuery(filters);
  }
  return cloneDeep(filters);
};

export const isFilterEmpty = (filter: Filter): boolean => {
  const val: any = filter.value;

  switch (filter.type) {
    case 'station':
    case 'pickupStation':
    case 'partner':
    case 'class':
    case 'carType':
    case 'carScore':
    case 'hasDeposit':
    case 'deposit':
    case 'equip':
      return val === null || val.length === 0;

    case 'passengers':
      return val === null || val === 0;

    default:
      return val === null;
  }
};

export const updateSingleFilter = (filters: Filters, filter: Filter): Filters => {
  const i = filters.findIndex((item) => item.type === filter.type);
  if (i !== -1) {
    filters.splice(i, 1);
  }
  if (!isFilterEmpty(filter)) {
    filters.push(filter);
  }

  updateFiltersUrlQuery(filters);
  return cloneDeep(filters);
};
