import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';
import useTranslation from 'providers/translations/use-translations';
import styles from '../compare.module.scss';

interface Props {
  offer?: CarOffer;
}

const NameCell: FC<Props> = ({ offer }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className={styles.comparison__carName}>
        {offer && offer.name}
        <span className={styles.comparison__orSimiliar}>{t('sipp_or_similiar')}</span>
      </h4>
    </div>
  );
};

NameCell.defaultProps = {
  offer: null
};

export default NameCell;
