import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';

interface Props {
  offer?: CarOffer;
}

const DoorCell: FC<Props> = ({ offer }) => <div>{offer.doors ? offer.doors : '–'}</div>;

DoorCell.defaultProps = {
  offer: null
};

export default DoorCell;
