import React, {
  useState,
  ReactNode,
  useEffect,
  forwardRef,
  useImperativeHandle,
  CSSProperties
} from 'react';
import classNames from 'classnames';
import useTranslation from 'providers/translations/use-translations';

import { Validator, ValidatorResponse } from 'src/validators/types';
import { validateValue } from 'src/validators';
import { SlideUp } from 'components/common/animations';
import { Translation } from 'types/engine/translation.type';
import styles from './forms.module.scss';

interface ComponentProps {
  value?: boolean;
  ref?: any;
  className?: string;
  labelClassName?: string;
  style?: CSSProperties;
  label?: string;
  name?: string;
  children?: ReactNode;
  bottomMargin?: boolean;
  validators?: Array<Validator>;
  errors?: Array<Translation>;
  onChange?: (value: boolean) => void;
  reverse?: boolean;
  fullWidth?: boolean;
  radioStyle?: boolean;
  notAllowedForUncheck?: boolean;
  disabled?: boolean;
  inUse?: boolean;
}

const Checkbox: React.FC<ComponentProps> = forwardRef(function CheckboxComponent(props, ref) {
  const { t } = useTranslation();
  const [value, setValue] = useState(props.value);
  const [hover, setHover] = useState(false);
  const [errors, setErrors] = useState([]);
  const allErrors = errors.concat(props.errors || []);

  const runValidation = (val?: boolean): ValidatorResponse => {
    if (Array.isArray(props.errors) && props.errors.length > 0) {
      return { isValid: false, messages: props.errors, name: props.name };
    }
    if (props.validators.length === 0) return { isValid: true };
    const { isValid, messages } = validateValue(val !== undefined ? val : value, props.validators);
    setErrors(messages);
    return { isValid, messages, name: props.name };
  };

  /* ------------------------------------------------------------------------ EXPOSED METHODS --- */

  useImperativeHandle(ref, () => ({
    validate(): ValidatorResponse {
      return runValidation();
    },
    setState(state: boolean) {
      setValue(state);
    }
  }));

  /* -------------------------------------------------------------------------- HANDLE EVENTS --- */

  const handleChange = () => {
    if (props.disabled !== true) {
      if (
        props.notAllowedForUncheck !== true ||
        (props.notAllowedForUncheck === true && value !== true)
      ) {
        setValue(!value);
        props.onChange(!value);
        runValidation(!value);
      }
    }
  };

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  /* ------------------------------------------------------------------------ RENDER TEMPLATE --- */

  return (
    <label
      className={classNames(styles.checkbox__container, props.className, {
        [styles['fullWidth']]: props.fullWidth,
        [styles['checkbox--radio']]: props.radioStyle,
        [styles['checkbox--disabled']]: props.disabled
      })}
      style={props.style}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {props.inUse && (
        <input
          type="checkbox"
          name={props.label}
          onChange={handleChange}
          className={styles.checkbox}
          checked={value}
          disabled={props.disabled}
        />
      )}
      <div
        className={classNames('forms_checkbox__indicator', {
          [styles.checkbox__indicator]: !props.reverse,
          [styles.checkbox__indicatorReversed]: props.reverse,
          [styles.checkbox__indicatorDisabled]: props.disabled,
          forms_checkbox__indicatorDisabled: props.disabled,
          [styles.checkbox__indicatorClickable]: props.inUse,
          [styles.checkbox__indicatorNotClickable]: !props.inUse,
          [styles.checkbox__indicatorRadio]: props.radioStyle,
          [styles.checkbox__indicatorError]: allErrors.length > 0,
          checkbox__indicatorError: allErrors.length > 0,
          [styles.checkbox__indicatorChecked]: value,
          forms_checkbox__indicatorChecked: value
        })}
      >
        {value && (
          <div
            className={classNames(styles.checkbox__tick, 'forms_checkbox__tick', {
              [styles.checkbox__tickRadio]: props.radioStyle,
              forms_checkbox__tickRadio: props.radioStyle
            })}
          />
        )}
      </div>

      {(props.label !== undefined || props.children) && (
        <div
          className={classNames(
            props.reverse ? styles.checkbox__labelReversed : styles.checkbox__label,
            props.disabled ? styles.checkbox__labelDisabled : undefined,
            props.inUse ? styles.checkbox__labelClickable : styles.checkbox__labelNotClickable,
            props.labelClassName
          )}
        >
          {props.label !== undefined && (
            <span className={styles.checkbox__direct_label}>{props.label}</span>
          )}
          {props.children ? props.children : null}
        </div>
      )}

      <SlideUp open={allErrors.length > 0 && hover}>
        <ul className={classNames(styles.fieldErrors, 'fieldErrors')}>
          {allErrors.map((message, i) => (
            <li key={i}>{t(message.key, message.data)}</li>
          ))}
        </ul>
      </SlideUp>
    </label>
  );
});

Checkbox.defaultProps = {
  value: false,
  onChange: () => null,
  validators: [],
  reverse: false,
  fullWidth: false,
  radioStyle: false,
  disabled: false,
  notAllowedForUncheck: false,
  labelClassName: undefined,
  inUse: true
};

export default Checkbox;
