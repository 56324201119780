import React, { FC } from 'react';

import useCarsApi from 'providers/cars-api/use-cars-api';
import useIframeContext from 'providers/iframe/use-iframe-context';
import CompareBar from 'components/car-compare/compare-bar';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import styles from './footer.module.scss';

const BottomStickyContainer: FC = () => {
  const { compare, subpage } = useCarsApi();
  const { isMobile } = useScreenDetect();
  const { inIframe, bottomEdge } = useIframeContext();

  /* --------------------------------------------------------------------------------- RENDER --- */

  return (
    <div
      className={styles.stickyContainer}
      style={{
        position: inIframe ? 'absolute' : undefined,
        bottom: inIframe ? `${bottomEdge}px` : undefined
      }}
    >
      <div className={styles.stickyContainer__content}>
        {!isMobile && subpage === 'offerlist' && compare.length > 0 && (
          <CompareBar offers={compare} />
        )}
      </div>
    </div>
  );
};

export default BottomStickyContainer;
