import React from 'react';
import Link from 'next/link';

const WithLink = ({ withLink = false, href = '', as = '', children }) =>
  withLink ? (
    <Link href={href} as={as}>
      {children}
    </Link>
  ) : (
    children
  );

export default WithLink;
