import { screenContext } from 'providers/screen-detect';
import { useContext } from 'react';
import { ScreenContextType } from './types';

const useScreenDetect = (): ScreenContextType => {
  const { screenWidth, isMobile, isTablet, isDesktop } = useContext(screenContext);
  return {
    screenWidth,
    isMobile,
    isTablet,
    isDesktop
  };
};

export default useScreenDetect;
