import { SearchData } from 'types/search/search-data.type';
import pako from 'pako';
import { CurrencyRate } from 'types/currency-rate.type';
import { SessionData } from 'types/session-data.type';
import moment, { Moment } from 'moment';
import safeNextConfig from './utils/safe-next-config';

const { publicRuntimeConfig } = safeNextConfig();
const COMPRESSION = publicRuntimeConfig.offersCompression ?? false;

class BrowserStorage {
  static isSupported(): boolean {
    try {
      const testKey = '__test__';
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  static async storeSearch(
    sessionId: string,
    search: SearchData,
    wsMessages: Array<string>,
    exchangeRates?: CurrencyRate
  ) {
    const storedSessions: Array<any> = BrowserStorage.getObject('sessions') || [];
    const i = storedSessions.findIndex((item) => item.id === sessionId);
    if (i !== -1) {
      storedSessions.splice(i, 1);
    }

    const data = {
      id: sessionId,
      search: {
        ...search,
        dates: {
          from: search.dates.from as Moment,
          to: search.dates.to as Moment
        }
      },
      wsMessages
    };
    storedSessions.push(data);

    if (exchangeRates) {
      BrowserStorage.setObject('exchange-office', exchangeRates);
    }

    try {
      BrowserStorage.setObject('sessions', storedSessions);
    } catch (err) {
      try {
        BrowserStorage.setObject('sessions', [data]);
      } catch (error) {
        console.error('Session storage quota exceed');
      }
    }
  }

  static getSearchResults(sessionId: string): SessionData {
    const sessions: Array<SessionData> = BrowserStorage.getObject('sessions') || [];
    const session = sessions.find((item) => item.id === sessionId);
    if (!session) return {};

    if (!COMPRESSION) {
      return {
        ...session,
        offers: session?.wsMessages?.map((el) => el.offers).reduce((a, b) => a.concat(b), []),
        stations: session?.wsMessages?.map((el) => el.stations).reduce((a, b) => a.concat(b), []),
        exchangeRates: BrowserStorage.getExchangeRates()
      };
    }
    const packages =
      session?.wsMessages?.map((raw) =>
        JSON.parse(Buffer.from(pako.inflate(Buffer.from(raw, 'base64'))).toString('utf8'))
      ) || [];
    if (session?.search?.dates) {
      session.search.dates.from = moment(session?.search?.dates?.from);
      session.search.dates.to = moment(session?.search?.dates?.to);
    }
    return {
      id: session.id,
      search: session.search,
      offers: packages.map((el) => el.offers).reduce((a, b) => a.concat(b), []),
      stations: packages.map((el) => el.stations).reduce((a, b) => a.concat(b), []),
      exchangeRates: BrowserStorage.getExchangeRates()
    };
  }

  static removeSession(sessionId?: string): void {
    if (!sessionId) return;
    const sessions: Array<SessionData> = BrowserStorage.getObject('sessions') || [];
    const i = sessions.findIndex((item) => item.id === sessionId);
    if (i === -1) return;

    sessions.slice(i, 1);
    BrowserStorage.setObject('sessions', sessions);
  }

  static getExchangeRates(): CurrencyRate {
    return BrowserStorage.getObject('exchange-office') || {};
  }

  /* ---------------------------------------------------------------- STORAGE GETTERS/SETTERS --- */

  static set(key: string, value: string) {
    if (!BrowserStorage.isSupported()) return;
    localStorage.setItem(key, value);
  }

  static get(key: string) {
    if (!BrowserStorage.isSupported()) return null;
    return localStorage.getItem(key);
  }

  static setObject(key: string, data: any) {
    if (!BrowserStorage.isSupported()) return;
    localStorage.setItem(key, JSON.stringify(data));
  }

  static getObject(key: string) {
    if (!BrowserStorage.isSupported()) return null;
    const raw = localStorage.getItem(key);
    return raw ? JSON.parse(raw) : null;
  }

  static remove(key: string) {
    if (!BrowserStorage.isSupported()) return;
    localStorage.removeItem(key);
  }

  static clear() {
    if (!BrowserStorage.isSupported()) return;
    localStorage.clear();
  }
}

export default BrowserStorage;
