import { NextPageContext } from 'next';
import React, { useRef, useState } from 'react';
import { get } from 'lodash';
import { useRouter } from 'next/router';

import { getAgentStatus } from 'src/requests';
import TextInput from 'components/forms/text-input';
import Button from 'components/forms/button';
import Spinner from 'components/common/spinner';
import { prepareToken } from 'src/utils/string';

import { fallbackLocale } from 'src/config';
import AllowedPages from 'enums/page-allowed-routes.enum';
import styles from './login.module.scss';

const Page = () => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const inputRef = useRef(null);
  const router = useRouter();

  const login = () => {
    const aid = inputRef.current.getValue();
    const { origin } = window.location;

    const returnTo = new URL(get(router.query, 'returnTo'), origin);

    if (aid === '') {
      setErr('Enter agent ID');
      return;
    }

    setLoading(true);
    getAgentStatus(aid).then(
      (status) => {
        if (status === 'active') {
          setErr('');
          if (returnTo && returnTo.pathname in AllowedPages) {
            window.location.href = `${origin}${returnTo.pathname}${
              returnTo.search
            }&aid=${aid}&sid=${prepareToken()}`;
          } else {
            window.location.href = `/?aid=${aid}&sid=${prepareToken()}`;
          }
        } else {
          setLoading(false);
          switch (status) {
            case 'inactive':
              setErr(`Cars engine for agent "${aid}" is disabled.`);
              break;
            case 'not_exists':
            default:
              setErr(`Agent "${aid}" not exists - verify agent ID.`);
          }
        }
      },
      () => null
    );
  };

  return (
    <div className={styles.outerContainer}>
      <h1 className={styles.title}>Cars IBE - Ypsilon.Net AG</h1>
      <p className={styles.subtitle}>
        <strong>Agent not specified. </strong>
        Enter your agent name to start
      </p>

      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <TextInput
          ref={inputRef}
          label="Agent id"
          horizontal
          onEnterPress={login}
          style={{ borderRight: 0 }}
          autofocus
        />
        <Button text="GO" variant="primary" onClick={login} />
      </form>

      {loading && <Spinner />}

      {err !== '' && (
        <p className={styles.error}>
          {err}
          <br />
          <span>In case of problems contact us:</span>
          <a href="mailto:car@ypsilon.net">car@ypsilon.net</a>
        </p>
      )}

      <p className={styles.footer}>powered by Ypsilon.net AG</p>
    </div>
  );
};

export const getServerSideProps = async (ctx: { ctx: NextPageContext }): Promise<any> => ({
  props: {
    layout: 'clean',
    subpage: 'login',
    locale: get(ctx, 'locale', fallbackLocale) || fallbackLocale
  }
});

export default Page;
