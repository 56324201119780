import React from 'react';
import classNames from 'classnames';

import styles from './common.module.scss';

interface SpinnerProps {
  className?: string;
  size?: 'default' | 'small';
}

const Spinner: React.FC<SpinnerProps> = ({ className, size = 'default' }) => (
  <div
    className={classNames(className, styles.spinnerContainer, {
      [styles.small]: size === 'small'
    })}
  >
    {[1, 2, 3, 4, 5].map((i) => (
      <div key={i} className={styles.spinnerRectangle} />
    ))}
  </div>
);

export default Spinner;
