import Router from 'next/router';
import { get, cloneDeep, isEqual } from 'lodash';
import {
  availableLocales,
  bookingUrlParams,
  confirmationUrlParams,
  devUrlParams,
  searchUrlParams
} from 'src/config';
import { filterQuery } from 'src/utils/url-tools';
import { isWorker } from 'src/utils';
import BrowserStorage from 'src/browser-storage';
import { QueryParam } from './types';

export const addInhouseParams = (params: Array<string>, inhouse = false): Array<string> =>
  inhouse ? params.concat(devUrlParams) : params;
export const createUrl = (path: string, params: Array<{ name: string; value: string }>) =>
  `${path}?${params.map((item) => `${item.name}=${item.value}`).join('&')}`;

export const paramsToObject = (params: URLSearchParams): any => {
  const output = {};

  params.forEach((value, key) => {
    output[key] = value;
  });
  return output;
};

export const paramsObjectToArray = (params: any) =>
  Object.keys(params)
    .sort()
    .map((name) => ({ name, value: params[name] }));

export const paramsToArray = (params: URLSearchParams): any => {
  const output: Array<{ name: string; value: string }> = [];
  params.forEach((value, name) => {
    output.push({ name, value });
  });
  return output;
};

export const getParamsAsObject = (): any =>
  paramsToObject(new URLSearchParams(window.location.search.slice(1)));

export const getParamsAsArray = (): any =>
  paramsToArray(new URLSearchParams(window.location.search.slice(1)));

export const decodePath = (pathname: string): [string, Array<string>] => {
  const parts = pathname.split('/').slice(1);
  let lng = '';
  if (availableLocales.indexOf(parts[0]) !== -1) {
    [lng] = parts.splice(1);
  } else {
    lng = 'de';
  }
  return [lng, parts];
};

// Change page language
export const getLocalizedPath = (path: string, locale: string) => {
  const regex = /^\/[a-z]{2}_[A-Z]{2}?/gm;
  const hasLocale = get(path.match(regex), 'length', 0) > 0;
  if (hasLocale) {
    return path.replace(regex, `/${locale}`);
  }
  return `/${locale}${path.startsWith('/') ? path.slice(1) : path}`;
};
/* --------------------------------------------------------------- SEARCH PARAMS MANIPULATION --- */

export const updateSearchParam = (name: string, value: string) => {
  if (typeof window === 'undefined') return;
  const { query, pathname } = Router;

  if (query[name] === value) return;

  query[name] = value;
  Router.push(
    {
      pathname,
      query
    },
    undefined,
    { scroll: false }
  );
};

export const updateSearchParams = (params: Array<{ name: string; value: string }>) => {
  if (typeof window === 'undefined') return;
  const { query, pathname } = Router;
  const prev = cloneDeep(query);

  params.forEach((item) => {
    if (item.value === undefined) {
      delete query[item.name];
    } else {
      query[item.name] = item.value;
    }
  });
  if (isEqual(prev, query)) return;

  Router.push(
    {
      pathname,
      query
    },
    undefined,
    { scroll: false }
  );
};

export const deleteSearchParam = (name: string) => {
  if (typeof window === 'undefined') return;

  const { query, pathname } = Router;
  delete query[name];

  Router.push(
    {
      pathname,
      query
    },
    undefined,
    { scroll: false }
  );
};

export const deleteSearchParams = (params: Array<string>) => {
  if (typeof window === 'undefined') return;
  let changed = false;
  const { query, pathname } = Router;

  params.forEach((name) => {
    if (query[name] !== undefined) {
      delete query[name];
      changed = true;
    }
  });
  if (!changed) return;

  Router.push(
    {
      pathname,
      query
    },
    undefined,
    { scroll: false }
  );
};

export const getSearchParams = (params: Array<string> = []): Array<QueryParam> => {
  if (typeof window === 'undefined') return [];

  return Object.keys(Router.query)
    .filter((key) => params.length === 0 || params.indexOf(key) !== -1)
    .map((key) => ({
      name: key,
      value: String(Router.query[key])
    }));
};

export const getSearchParam = (name: string): undefined | string => {
  if (typeof window === 'undefined' || isWorker()) return undefined;

  const { query } = Router;
  return query[name] as string;
};

/* ------------------------------------------------------------------------------- NAVIGATION --- */
export const refreshSession = (inhouse = false) => {
  const { query, push } = Router;
  const params = filterQuery(query, addInhouseParams(searchUrlParams, inhouse));
  delete params['searchid'];

  BrowserStorage.removeSession(
    Array.isArray(params['searchid']) ? params['searchid'][0] : params['searchid']
  );
  push({
    pathname: '/',
    query: params
  });
};

export const crateSearchUrl = (dst?: string) => {
  const params: Array<QueryParam> = [];
  if (dst) {
    const i = params.findIndex((item) => item.name === 'dst');
    if (i !== -1) {
      params.splice(i, 1);
    }
    params.push({ name: 'dst', value: dst });
  }

  return createUrl('/', params);
};

export const backToSearchform = (inhouse = false, overwrite: any = {}) => {
  const { query, push } = Router;
  const params = filterQuery(query, addInhouseParams(searchUrlParams, inhouse));
  delete params['searchid'];
  push({
    pathname: '/',
    query: {
      ...params,
      ...overwrite
    }
  });
};

export const goToOfferlist = (dst?: string, clearSearchId = false, inhouse = false) => {
  const { query, push } = Router;
  const params = filterQuery(query, addInhouseParams(searchUrlParams, inhouse));

  if (dst) {
    params['dst'] = dst;
  }

  if (clearSearchId && params['searchid']) {
    delete params['searchid'];
  }

  push({
    pathname: '/',
    query: params
  });
};

export const goToBookingPage = (oid: string, inhouse = false, newWindow = false) => {
  const { query, push, locale } = Router;
  const params = filterQuery(query, addInhouseParams(bookingUrlParams, inhouse));

  params['oid'] = oid;

  if (newWindow) {
    window.open(`/${locale}/booking?${new URLSearchParams(params as any)}`);
  } else {
    push({
      pathname: '/booking',
      query: params
    });
  }
};

export const goToConfirmationPage = (userKey: string, inhouse = false) => {
  const { query, push } = Router;
  const params = filterQuery(query, addInhouseParams(confirmationUrlParams, inhouse));
  params['userkey'] = userKey;

  push({
    pathname: '/confirmation',
    query: params
  });
};

export const backToBookingForm = (inhouse = false) => {
  const { query, push } = Router;
  const params = filterQuery(query, addInhouseParams(searchUrlParams, inhouse));

  delete params['userkey'];

  push({
    pathname: '/booking',
    query: params
  });
};
