import React, { FC, ReactNode } from 'react';
import { serializeError } from 'serialize-error';
import { log } from 'src/logger';

import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './error-fallback';

type Props = {
  children: ReactNode;
};

const ErrorBoundary: FC<Props> = ({ children }) => {
  const handleError = (error: Error, info: { componentStack: string }) => {
    log({
      level: 'CRITICAL',
      tag: 'appCrash',
      msg: 'Frontend crashed - error boundary',
      data: { error: serializeError(error), componentStack: info.componentStack }
    });
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
