import AgentCustomMeta from 'modules/custom-headers/agent-custom-meta';
import DevTools from 'modules/dev-tools';
import { CarsProvider } from 'providers/cars-api';
import React, { FC, ReactNode } from 'react';
import { CarsApiConstructParams } from 'types/engine/cars-api-construct-params.type';
import TopStickyContainer from 'modules/page-header/top-sticky-container';
import { TrackingProvider } from 'providers/tracking';
import DynamicLocationWrapper from 'components/common/dynamic-location-background/dynamic-location-wrapper';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import classNames from 'classnames';
import styles from './widget-layout.module.scss';

type Props = {
  initData: CarsApiConstructParams;
  children: ReactNode;
};

const WidgetLayout: FC<Props> = ({ initData, children }) => {
  const { inhouse } = useDevToolsContext();

  return (
    <CarsProvider initData={initData} layoutStyle={styles}>
      <TrackingProvider customTracking={initData.customTracking}>
        <AgentCustomMeta />
        <DynamicLocationWrapper deactivateDynamicBackground>
          <div
            id="yps_container"
            className={classNames(styles.pageContainer, 'offersWidgetLayout', {
              [styles['pageContainer--inhouse']]: inhouse
            })}
          >
            <TopStickyContainer />

            {children}

            {initData.agent && !initData.agent.layoutSettings.disableDevTools && <DevTools />}
          </div>
        </DynamicLocationWrapper>
      </TrackingProvider>
    </CarsProvider>
  );
};

export default WidgetLayout;
