import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';
import useTranslation from 'providers/translations/use-translations';

interface Props {
  offer?: CarOffer;
}

const GearboxPolicyCell: FC<Props> = ({ offer }) => {
  const { t } = useTranslation();

  return (
    <div>{offer.equip.includes('automaticTransmission') ? t('0nc_gear_a') : t('0nc_gear_m')}</div>
  );
};

GearboxPolicyCell.defaultProps = {
  offer: null
};

export default GearboxPolicyCell;
