import BrowserStorage from 'src/browser-storage';
import { getSearchParam } from 'src/url-manager';
import { CurrencyRate } from 'types/currency-rate.type';
import { isEmpty } from 'lodash';

class ExchangeOffice {
  private agentCurrency: string;

  private targetPrice: number;

  private targetCurrency: string;

  private exchangeRates: object;

  private sessionId: string;

  private fromPrice: number;

  private fromCurrency: string;

  constructor(exchangeRates: CurrencyRate = {}) {
    this.exchangeRates = exchangeRates;
    this.sessionId = getSearchParam('searchid') || '';
    if (isEmpty(this.exchangeRates['rates'])) {
      this.getExchangeRatesFromStorage();
    }
    this.agentCurrency =
      this.exchangeRates && this.exchangeRates['agentCurrency']
        ? this.exchangeRates['agentCurrency']
        : '';
  }

  private getExchangeRatesFromStorage() {
    if (isEmpty(this.exchangeRates['rates'])) {
      this.exchangeRates = BrowserStorage.getExchangeRates();
    }
  }

  private calculatePrices() {
    if (!this.targetCurrency || !this.exchangeRates['rates']) return;
    if (this.fromCurrency.toLowerCase() !== this.targetCurrency.toLowerCase()) {
      if (this.exchangeRates['rates'][this.targetCurrency]) {
        this.targetPrice =
          (this.fromPrice * this.exchangeRates['rates'][this.fromCurrency]) /
          this.exchangeRates['rates'][this.targetCurrency];
      }
    }
  }

  public getPriceInAgentCurrency(fromAmount: number, fromCurrency: string) {
    this.targetPrice = 0;
    this.targetCurrency = this.agentCurrency;
    this.fromPrice = fromAmount;
    this.fromCurrency = fromCurrency;
    this.calculatePrices();
    return this.targetPrice;
  }

  public getPriceInEur(fromAmount: number, fromCurrency: string) {
    this.targetPrice = 0;
    this.targetCurrency = 'EUR';
    this.fromPrice = fromAmount;
    this.fromCurrency = fromCurrency;
    this.calculatePrices();
    return this.targetPrice;
  }

  public getAgentCurrency() {
    return this.agentCurrency;
  }
}

export default ExchangeOffice;
