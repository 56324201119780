import Button from 'components/forms/button';
import useTranslation from 'providers/translations/use-translations';
import React, { FC } from 'react';
import { log } from 'src/logger';
import { backToSearchform } from 'src/url-manager';
import styles from '../common.module.scss';

type Props = {
  onBack: () => void;
};

const ErrorMessage: FC<Props> = (props) => {
  const { t } = useTranslation();

  const back = () => {
    log({
      level: 'DEBUG',
      tag: 'appCrash',
      msg: 'Error message - back to searchform',
      data: {}
    });

    backToSearchform();
    props.onBack();
  };

  return (
    <div className={styles.errorBoundary}>
      <h1 className={styles.title}>{t('error')}</h1>

      <Button variant="secondary" onClick={() => back()}>
        {t('new_search_start')}
      </Button>
    </div>
  );
};

export default ErrorMessage;
