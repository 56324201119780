import { CarOffer } from 'src/cars/types';
import { twoDigits } from 'src/utils/string';
import { OpenHourItem, OpenHours, RawStation, StationType, Station } from './types';

export const normalizeStations = (data: Array<RawStation>): Array<Station> =>
  data.map((el) => ({
    ...el,
    id: `${el.partner}:${el.code}`,
    cars: []
  }));

export const simplifyStationType = (types: Array<StationType> = []): StationType => {
  if (types.indexOf('terminal') !== -1) return 'terminal';
  if (types.indexOf('airport') !== -1) return 'airport';
  if (types.indexOf('rail') !== -1) return 'rail';
  if (types.indexOf('shuttle') !== -1) return 'shuttle';
  if (types.indexOf('city') !== -1) return 'city';
  return 'other';
};

export const findStationById = (id: string, stations: Array<Station>): Station | undefined =>
  stations.find((el) => el.id === id);

export const findStationByCode = (code: string, stations: Array<Station>): Station | undefined =>
  stations.find((el) => el.code === code);

export const mergeCarsWithStations = (stations: Array<Station>, cars: Array<CarOffer>): void => {
  stations.forEach((station) => {
    station.id = `${station.partner}:${station.code}`;
    station.cars = cars.filter(
      (car) =>
        car.partner === station.partner &&
        (car.pickupStation.code === station.code ||
          car.dropoffStation.code === station.code ||
          car.variants.findIndex(
            (el) =>
              el.pickupStation.code === station.code || el.dropoffStation.code === station.code
          )) !== -1
    );
    return station;
  });
};
export const stationTypeMap = {
  terminal: {
    icon: 'terminal',
    textId: 'location_terminal'
  },
  airport: {
    icon: 'airport',
    textId: 'location_airport'
  },
  shuttle: {
    icon: 'shuttle',
    textId: 'location_shuttle'
  },
  rail: {
    icon: 'train-station',
    textId: 'location_trainstation'
  },
  city: {
    icon: 'city',
    textId: 'location_city'
  }
};

export const stationTypesToIcon = (types: Array<StationType> = []): string => {
  if (types.includes('terminal')) return stationTypeMap.terminal.icon;
  if (types.includes('airport')) return stationTypeMap.airport.icon;
  if (types.includes('shuttle')) return stationTypeMap.shuttle.icon;
  if (types.includes('rail')) return stationTypeMap.rail.icon;

  return stationTypeMap.city.icon;
};

export const openingHourByWeekdayNumber = (n: number, openHours: OpenHours): OpenHourItem => {
  switch (n) {
    case 1:
      return openHours.mon;
    case 2:
      return openHours.tue;

    case 3:
      return openHours.wed;

    case 4:
      return openHours.thu;

    case 5:
      return openHours.fri;

    case 6:
      return openHours.sat;

    case 7:
      return openHours.sun;

    default:
      return openHours.mon;
  }
};

export const openingHourToString = (mins = 0): string =>
  `${twoDigits(Math.floor(mins / 60))}:${twoDigits(mins % 60)}`;

export const openingHourToHour = (mins = 0): number => Math.floor(mins / 60);
