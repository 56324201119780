import React, { FC, ReactNode, useEffect, useRef } from 'react';
import classNames from 'classnames';
import useOnclickOutside from 'react-cool-onclickoutside';

import useHasMounted from 'hooks/use-has-mounted';
import Button from 'components/forms/button';

import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import useIframeContext from 'providers/iframe/use-iframe-context';
import styles from './common.module.scss';
import { FadeIn } from './animations';

interface Props {
  open: boolean;
  display?: boolean;
  className?: string;
  classNameOuter?: string;
  classNameContent?: string;
  children?: ReactNode;
  width?: string;
  closeBtn?: boolean;
  title?: string;
  onClose?: () => void;
  inUse?: boolean;
  zIndex?: number;
  detectIFrame?: boolean;
  useClickOutside?: boolean;
  valign?: 'top' | 'center' | 'bottom';
  scrollMobileTop?: boolean;
}

const OverlayWrapper: FC<Props> = ({
  open,
  display = true,
  width = '600px',
  closeBtn = false,
  className = null,
  classNameOuter = null,
  classNameContent = null,
  children = null,
  title = null,
  onClose = () => null,
  inUse = false,
  zIndex = 20,
  valign = 'center',
  detectIFrame = true,
  useClickOutside = true,
  scrollMobileTop = false
}) => {
  const ref = useRef(null);
  const mounted = useHasMounted();
  const { isMobile } = useScreenDetect();
  const { inIframe, topEdge, bottomEdge } = useIframeContext();

  const backgroundClickEvent = (event) => {
    event.stopPropagation();
  };

  useOnclickOutside(
    () => {
      if (!mounted || !useClickOutside || !open || !inUse || !display) {
        return;
      }
      onClose();
    },
    {
      refs: [ref],
      detectIFrame: detectIFrame
    }
  );

  useEffect(() => {
    if (isMobile && open && display && inUse && scrollMobileTop === true) {
      window.scrollTo(0, 0);
    }

    document.body.style.overflow = open && display && inUse ? 'hidden' : 'auto';
  }, [open, display, inUse]);

  useEffect(
    () => () => {
      document.body.style.overflow = 'auto';
    },
    []
  );

  if (!inUse) {
    return <>{children}</>;
  }

  /* --------------------------------------------------------------------------------- RENDER --- */

  return (
    <FadeIn open={open}>
      <div
        className={classNames(
          styles.overlayWrapper,
          classNameOuter,
          [styles[`overlayWrapper__${valign}`]],
          {
            [styles['overlayWrapper--iframe']]: inIframe,
            [styles['overlayWrapper--ismobile']]: isMobile
          }
        )}
        style={{
          display: display ? 'flex' : 'none',
          top: inIframe ? `${topEdge}px` : undefined,
          bottom: inIframe ? `${bottomEdge}px` : undefined,
          zIndex: zIndex
        }}
        onClick={backgroundClickEvent}
      >
        <div
          ref={ref}
          className={classNames(styles.overlayWrapper__outerContainer, className)}
          style={{
            width: width
          }}
        >
          {(title || closeBtn) && (
            <div className={classNames(styles.overlayWrapper__head)}>
              <h2
                className={styles.overlayWrapper__title}
                dangerouslySetInnerHTML={{
                  __html: title
                }}
              />

              {closeBtn && (
                <Button
                  className={classNames(styles.overlayWrapper__closeBtn)}
                  icon="cross"
                  variant="text"
                  size="large"
                  onClick={onClose}
                />
              )}
            </div>
          )}

          <div className={classNames(styles.overlayWrapper__content, classNameContent)}>
            {children}
          </div>
        </div>
      </div>
    </FadeIn>
  );
};

export default OverlayWrapper;
