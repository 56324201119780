import NotificationService from 'modules/notifications/notification-service';
import { backToSearchform, refreshSession } from 'src/url-manager';
import ErrorCodes from 'types/error-codes.enum';
import { Subject } from 'rxjs';
import FrontendError, { simplifyFrontendError } from 'src/frontend-error.class';
import { wsCritical, wsError, wsInfo, wsWarning } from 'src/logger';
import { CarOffer } from 'src/cars/types';
import { Translation } from 'types/engine/translation.type';
import { GlobalEvent } from './types';

export const defaultContextValue: any = {
  globalEvents: null,
  carsApi: null,
  agent: null,
  general: null,
  search: null,
  setSearch: () => null,
  notifications: [],
  notificationService: null,
  popupManager: {
    open: () => null
  },
  subpage: 'searchform',

  compare: [],
  addToCompare: () => null,
  removeFromCompare: () => null,
  clearCompare: () => null,

  handleError: () => null,
  logger: {
    log: () => null,
    debug: () => null,
    info: () => null,
    notice: () => null,
    warning: () => null,
    error: () => null,
    critical: () => null
  }
};

const extractClientMessages = (err: FrontendError): Array<Translation> => {
  if (!err) return [];
  if (err.reason) {
    return [{ key: `notification_reason_${err.reason}` }];
  }
  if (err.partnerMessage) {
    return [{ key: err.partnerMessage }];
  }

  return [];
};

export const processError = (
  err: FrontendError,
  notifications: NotificationService,
  globalEvents: Subject<GlobalEvent>,
  inhouse = false
) => {
  const messages = extractClientMessages(err);
  switch (err.code) {
    case ErrorCodes.unknownSessionId:
      if (!err.logged) {
        wsInfo('sessionExpired', 'Session expired', simplifyFrontendError(err), err.requestId);
      }
      notifications.createNotificationFromPreset('sessionExpired');
      refreshSession(inhouse);
      globalEvents.next({ type: 'sessionExpired' });
      break;

    case ErrorCodes.offerNotFound:
    case ErrorCodes.unknownOfferId:
      if (!err.logged) {
        wsWarning('noOffer', 'Offer not found', simplifyFrontendError(err), err.requestId);
      }
      notifications.createNotificationFromPreset('offerNotFound');
      backToSearchform(inhouse);
      break;

    case ErrorCodes.doubleBooking:
      if (!err.logged) {
        wsError('bookingFailed', 'Double booking', simplifyFrontendError(err), err.requestId);
      }
      notifications.createNotificationFromPreset('doubleBooking');
      break;

    case ErrorCodes.scaRequestFailed:
    case ErrorCodes.scaScriptError:
    case ErrorCodes.scaNotPerformed:
    case ErrorCodes.scaNotAuthenticated:
      if (!err.logged) {
        wsError('bookingFailed', 'Problem with SCA', simplifyFrontendError(err), err.requestId);
      }
      notifications.createNotificationFromPreset('scaProblem', {
        messages:
          err.message && err.message !== 'Unknown error' ? [{ key: err.message }] : undefined
      });
      break;

    case ErrorCodes.paymentDeclined:
      if (!err.logged) {
        wsError('bookingFailed', 'Payment was declined', simplifyFrontendError(err), err.requestId);
      }
      notifications.createNotificationFromPreset('paymentDeclined', { messages });
      break;

    case ErrorCodes.priceChanged:
      if (!err.logged) {
        wsError('bookingFailed', 'Price has changed', simplifyFrontendError(err), err.requestId);
      }
      notifications.createNotificationFromPreset('offerPriceChange', { messages });
      globalEvents.next({ type: 'offerPriceChange' });
      break;

    case ErrorCodes.bookingError:
    case ErrorCodes.externalBookingError:
    case ErrorCodes.invalidBookingRequest:
      if (!err.logged) {
        wsError('bookingFailed', 'Booking failed', simplifyFrontendError(err), err.requestId);
      }
      notifications.createNotificationFromPreset('bookingFailed', {
        messages
      });
      break;

    case ErrorCodes.hell:
      if (!err.logged) {
        wsCritical('apiResponseError', 'Hell error', simplifyFrontendError(err), err.requestId);
      }
      notifications.createNotificationFromPreset('unknownError');
      break;

    default:
      if (!err.logged) {
        wsWarning(
          'apiResponseError',
          err?.message ?? 'Api response error',
          simplifyFrontendError(err),
          err.requestId
        );
      }
      notifications.createNotificationFromPreset('unknownError', { messages });
  }
};
export const trimCompareArray = (items: Array<CarOffer>): Array<CarOffer> =>
  items.length > 3 ? items.slice(items.length - 3) : items;
