/* eslint newline-per-chained-call: "off" */

import moment, { Moment } from 'moment';

// Moment date to iso string
export const momentToIsoDate = (date: Moment): string => date.format('DD.MM.YYYY');

export const datetimeFormat = (date: string, format = 'DD.MM.YYYY') => moment(date).format(format);

// Prepare pick up date - ensure is in the future
export const preparePickUpDate = (dateString = '', timeString = ''): string => {
  const defaultDate = moment().add(7, 'days').hour(12).minute(0).second(0);
  let checkIn = dateString !== '' ? moment(dateString) : defaultDate;
  if (!checkIn.isValid()) checkIn = defaultDate;

  let times = ['12', '0'];
  if (timeString !== '') {
    times = timeString.split(':');
  }
  checkIn.hour(Number(times[0])).minute(Number(times[1]));

  return checkIn.isAfter(moment())
    ? checkIn.format('YYYY-MM-DDTHH:mm:ss')
    : defaultDate.format('YYYY-MM-DDTHH:mm:ss');
};

// Prepare drop off date - ansure is in the future and after checkin
export const prepareDropOffDate = (dateString = '', timeString = '', checkIn = ''): string => {
  const stayDuration = 1;
  const checkInDate = moment(checkIn);
  const defaultDate = checkInDate.add(stayDuration, 'days').hour(10).minute(0).second(0);
  let checkOut = dateString !== '' ? moment(dateString) : defaultDate;
  if (!checkOut.isValid()) checkOut = defaultDate;

  let times = ['10', '0'];
  if (timeString !== '') {
    times = timeString.split(':');
  }
  checkOut.hour(Number(times[0])).minute(Number(times[1]));

  return !checkOut.isBefore(checkIn)
    ? checkOut.format('YYYY-MM-DDTHH:mm:ss')
    : defaultDate.format('YYYY-MM-DDTHH:mm:ss');
};

// Get day difference between dates
export const dayDiff = (from: Moment, to: Moment): number => to.diff(from, 'days');
export const hourDiff = (from: Moment, to: Moment): number => to.diff(from, 'hours');
export const secondsDiff = (from: Moment, to: Moment): number => to.diff(from, 'seconds');

// Get year as number number
export const getYear = (date?: Moment): number => {
  const ref: Moment = date || moment();
  return Number(ref.format('YYYY'));
};

// Convert birth date to age
export const calcAge = (birth: Moment, referenceDate?: Moment): number => {
  const dob = moment(birth);
  dob.set('hour', 0);
  dob.set('minute', 0);
  dob.set('second', 0);
  dob.set('millisecond', 0);

  if (referenceDate) {
    const refd = moment(referenceDate);
    refd.set('hour', 0);
    refd.set('minute', 0);
    refd.set('second', 0);
    refd.set('millisecond', 0);

    return moment(refd).diff(dob, 'years');
  }

  return moment().diff(dob, 'years');
};

export const weekdayNumberToString = (n: number): string =>
  ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'][
    n !== 0 ? n - 1 : 6
  ];

export const isTime = (val: string): boolean => {
  if (!val) return false;
  return (
    (
      val.match(/^(([0-9]{1})|([0-1]{1}[0-9]{1})|([2]{1}[0-3]{1}))([:]{1})([0-5]{1}[0-9]{1})$/gm) ??
      []
    ).length > 0
  );
};
