/* eslint no-shadow: "off" */

enum ErrorCodes {
  // Backend errors
  requestValidatonError = 422,
  unknownSessionId = 450,
  unknownOfferId = 451,
  offerNotFound = 452,
  unknownProductId = 457,
  unknownAgentId = 453,
  backendInactive = 454,
  incorrectAgentSetup = 455,
  headersIssue = 456,
  bookingNotFound = 469,
  bookingError = 470,
  doubleBooking = 473,
  paymentDeclined = 474,
  priceChanged = 476,
  externalBookingError = 477,
  invalidBookingRequest = 478,
  confirmationNeedsCredentials = 480,
  hell = 666,
  frontendHell = 999,

  // Frontend errors
  bookingFailed = 900,
  scaRequestFailed = 901,
  scaScriptError = 902,
  scaNotPerformed = 903,
  scaNotAuthenticated = 904,
  apiRequestFailed = 905,
  searchProcessError = 906
}

export default ErrorCodes;
