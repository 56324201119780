import React, { createContext, FC, useEffect, useState } from 'react';
import { BannersContextProps, BannersContextType, BannersRequestProps } from './types';
import axiosInstance from 'src/axios-instance';
import { ApiResponse } from 'types/engine/api';
import useCarsApi from 'providers/cars-api/use-cars-api';

const bannersContext = createContext<BannersContextType>({
  getBanners: () => {},
  banners: {}
});

const BannersProvider: FC<BannersContextProps> = ({ children }) => {
  const { agent } = useCarsApi();
  const [banners, setBanners] = useState(null);

  const getBanners = (bannerRequestData: BannersRequestProps): Promise<any> => {
    setBanners([]);
    if (!agent.hasBanners) return;

    return new Promise((resolve, reject) => {
      const { agent, IBEStep, apt = '', country = '', pickupDate = '' } = bannerRequestData;
      axiosInstance
        .post<ApiResponse>('/proxy/banner', {
          agent,
          IBEStep,
          ...(apt && { apt }),
          ...(country && { country }),
          ...(pickupDate && { pickupDate })
        })
        .then((response) => {
          if (response.status === 200) {
            setBanners(response.data);
          }
          resolve(1);
        })
        .catch(() => {
          reject();
        });
    });
  };

  return (
    <bannersContext.Provider
      value={{
        getBanners,
        banners
      }}
    >
      {children}
    </bannersContext.Provider>
  );
};

export { BannersProvider, bannersContext };
