import { carsContext } from 'providers/cars-api';
import { useContext } from 'react';
import { CarsContextType } from './types';

const useCarsApi = (): CarsContextType => {
  const {
    sessionTtl,
    updateSessionTtl,
    setSessionExpireAt,
    globalEvents,
    watchEvents,
    agent,
    general,
    countries,
    notifications,
    notificationService,
    popupManager,
    subpage,
    setSubpage,
    changeCor,
    handleError,
    compare,
    addToCompare,
    removeFromCompare,
    clearCompare
  } = useContext(carsContext);
  return {
    sessionTtl,
    updateSessionTtl,
    setSessionExpireAt,
    globalEvents,
    watchEvents,
    agent,
    general,
    countries,
    notifications,
    notificationService,
    popupManager,
    subpage,
    setSubpage,
    changeCor,
    handleError,
    compare,
    addToCompare,
    removeFromCompare,
    clearCompare
  };
};

export default useCarsApi;
