import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';
import useTranslation from 'providers/translations/use-translations';

interface Props {
  offer?: CarOffer;
}

const BaggageCell: FC<Props> = ({ offer }) => {
  const { t } = useTranslation();
  return (
    <div>
      {offer.baggageLarge}
      {` ${t('0nc_case_big')}`}
      {offer.baggageSmall > 0 ? `, ${offer.baggageSmall} ${t('0nc_case_small')}` : ''}
    </div>
  );
};

BaggageCell.defaultProps = {
  offer: null
};

export default BaggageCell;
