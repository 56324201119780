import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  ChangeEvent,
  KeyboardEvent,
  FC,
  CSSProperties
} from 'react';
import { get } from 'lodash';

import useHasMounted from 'hooks/use-has-mounted';
import useTranslation from 'providers/translations/use-translations';
import { isMandatory, validateValue } from 'src/validators';
import { prepareToken } from 'src/utils/string';
import { Translation } from 'types/engine/translation.type';

import { Validator, ValidatorResponse } from 'src/validators/types';
import { SlideUp } from 'components/common/animations';
import classNames from 'classnames';
import styles from './forms.module.scss';
import InputLabel from './input-label';
import { ComponentSize, Fixes } from './types';

type Props = {
  value?: string;
  ref?: any;
  label?: string;
  name?: string;
  placeholder?: string;
  id?: string;
  validators?: Array<Validator>;
  errors?: Array<Translation>;
  autofocus?: boolean;
  fixes?: Fixes;
  inputType?: 'text' | 'hidden' | 'password';
  onChange?(value: string): void;
  onBlur?(value: string): void;
  onKeyUp?(value: string): void;
  onEnterPress?(value: string): void;

  // Appearance
  className?: string;
  style?: CSSProperties;
  size?: ComponentSize;
  width?: string;
  horizontal?: boolean;
  border?: boolean;
};

const TextInput: FC<Props> = forwardRef(function TextInputComponent(props, ref) {
  const inputRef = useRef(null);
  const hasMounted = useHasMounted();
  const { t } = useTranslation();

  const [id] = useState(props.id ? props.id : `input_${prepareToken()}`);
  const [value, setValue] = useState(props.value);
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const [errors, setErrors] = useState<Array<Translation>>([]);
  const allErrors = errors.concat(props.errors || []);
  const getClasses = (): string => {
    const classes = [props.className, styles.fieldContainer];
    if (focus) classes.push(styles['fieldContainer--focus']);
    if (props.horizontal) classes.push(styles['fieldContainer--horizontal']);
    if (!props.border) classes.push(styles['fieldContainer--noBorder']);
    if (props.size !== 'default') classes.push(styles[`fieldContainer--${props.size}`]);
    return classes.join(' ');
  };

  const runValidation = (): ValidatorResponse => {
    if (Array.isArray(props.errors) && props.errors.length > 0) {
      return { isValid: false, messages: props.errors, name: props.name };
    }
    if (props.validators === undefined) return { isValid: true };
    const { isValid, messages } = validateValue(inputRef.current.value, props.validators);
    setErrors(messages);
    return { isValid, messages, name: props.name };
  };

  /* ------------------------------------------------------------------------ EXPOSED METHODS --- */

  useImperativeHandle(ref, () => ({
    validate(): ValidatorResponse {
      return runValidation();
    },
    getValue(): string {
      return inputRef.current.value;
    }
  }));

  /* -------------------------------------------------------------------------- HANDLE EVENTS --- */

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let v = e.target['value'];
    if (props.fixes.length > 0) {
      props['fixes'].forEach((fix) => {
        switch (fix) {
          case 'removeSpaces':
            v = v.replace(/ /g, '');
            break;

          case 'specialCharacters':
            v = v.replace(/[\r\t\v\n]+/g, '');
            // eslint-disable-next-line prettier/prettier
            v = v.replace(/[`’]+/g, '\'');
            break;
          default:
        }
      });
      inputRef.current.value = v;
    }
    setValue(v);
    props.onChange(get(inputRef, 'current.value', ''));
  };

  const handleBlur = () => {
    if (typeof props.onBlur === 'function') {
      props.onBlur(get(inputRef, 'current.value', ''));
    }
    setFocus(false);
  };

  const handleKeyUp = () => {
    props.onKeyUp(inputRef.current.value);
    runValidation();
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      props.onEnterPress(get(inputRef, 'current.value', ''));
    }
  };

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  /* ------------------------------------------------------------------------ RENDER TEMPLATE --- */

  if (!props.id && !hasMounted) return null;

  return (
    <div className={getClasses()}>
      <InputLabel
        label={props.label}
        id={id}
        errors={errors}
        required={isMandatory(props.validators)}
      />

      <input
        id={id}
        className={classNames(styles.textInput, {
          [styles['textInput--focus']]: hover || focus,
          [styles['textInput--error']]: allErrors.length > 0,
          'textInput--error': allErrors.length > 0
        })}
        name={id}
        value={value}
        type={props.inputType}
        ref={inputRef}
        autoFocus={props.autofocus}
        placeholder={props.placeholder}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onKeyPress={handleKeyPress}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onFocus={() => setFocus(true)}
        onBlur={handleBlur}
        style={{
          width: props.width,
          ...props.style
        }}
      />

      <SlideUp open={allErrors.length > 0 && (hover || focus)}>
        <ul className={classNames(styles.fieldErrors, 'fieldErrors')}>
          {allErrors.map((message, i) => (
            <li key={i}>{t(message.key, message.data)}</li>
          ))}
        </ul>
      </SlideUp>
    </div>
  );
});

TextInput.defaultProps = {
  value: '',
  horizontal: false,
  border: true,
  size: 'default',
  inputType: 'text',
  autofocus: false,
  onChange: () => null,
  onKeyUp: () => null,
  onEnterPress: () => null,
  fixes: [],
  validators: [],
  style: {},
  errors: []
};

export default TextInput;
