import AgentCustomFooter from 'modules/custom-headers/agent-custom-footer';
import AgentCustomHeader from 'modules/custom-headers/agent-custom-header';
import AgentCustomMeta from 'modules/custom-headers/agent-custom-meta';
import DevTools from 'modules/dev-tools';
import { CarsProvider } from 'providers/cars-api';
import React, { FC, ReactNode, useState } from 'react';
import { CarsApiConstructParams } from 'types/engine/cars-api-construct-params.type';
import PageFooter from 'modules/page-footer';
import TopStickyContainer from 'modules/page-header/top-sticky-container';
import dynamic from 'next/dynamic';
import { TrackingProvider } from 'providers/tracking';
import DynamicLocationWrapper from 'components/common/dynamic-location-background/dynamic-location-wrapper';
import DynamicLocationBackground from 'components/common/dynamic-location-background';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import classNames from 'classnames';
import styles from './default-layout.module.scss';

type Props = {
  initData: CarsApiConstructParams;
  children: ReactNode;
};

const DefaultLayout: FC<Props> = ({ initData, children }) => {
  const { inhouse } = useDevToolsContext();
  const [CustomComponentsList] = useState({
    BahnDeHeader: null,
    BahnDeFooter: null
  });

  if (
    initData?.agent &&
    ['bahndecar', 'bahndebahncardcar'].indexOf(initData?.agent?.agentId) > -1
  ) {
    if (!CustomComponentsList.BahnDeHeader) {
      CustomComponentsList.BahnDeHeader = dynamic(
        () => import('components/custom-agent-components/bahnde-header'),
        { ssr: false }
      );
    }
    if (!CustomComponentsList.BahnDeFooter) {
      CustomComponentsList.BahnDeFooter = dynamic(
        () => import('components/custom-agent-components/bahnde-footer'),
        { ssr: false }
      );
    }
  }

  return (
    <CarsProvider initData={initData} layoutStyle={styles}>
      <TrackingProvider customTracking={initData.customTracking}>
        <DynamicLocationBackground />
        <AgentCustomMeta />
        <AgentCustomHeader />

        <DynamicLocationWrapper>
          <div
            id="yps_container"
            className={classNames(styles.pageContainer, {
              [styles['pageContainer--inhouse']]: inhouse
            })}
          >
            <TopStickyContainer />
            <div className="header__custom-components">
              {CustomComponentsList.BahnDeHeader && <CustomComponentsList.BahnDeHeader />}
            </div>

            {children}

            {CustomComponentsList.BahnDeFooter && <CustomComponentsList.BahnDeFooter />}
            {!CustomComponentsList.BahnDeFooter && <PageFooter />}
            {initData.agent && !initData.agent.layoutSettings.disableDevTools && <DevTools />}
          </div>
        </DynamicLocationWrapper>
        <AgentCustomFooter />
      </TrackingProvider>
    </CarsProvider>
  );
};

export default DefaultLayout;
