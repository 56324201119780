import React, { FC, memo, useState } from 'react';
import getConfig from 'next/config';
import classNames from 'classnames';
import useTranslation from 'providers/translations/use-translations';

import styles from './common.module.scss';

const { publicRuntimeConfig } = getConfig();
const IMAGES_URL = publicRuntimeConfig.imagesUrl;

type LabelType = 'partner' | 'supplier';

type Props = {
  name: string;
  width?: number;
  height?: number;
  label?: LabelType;
  className?: string;
  textOnly?: boolean;
  splitLine?: boolean;
};

const labelDisplayed = (label, t) => {
  if (label === 'partner') {
    return t('0nc_partner');
  }
  if (label === 'supplier') {
    return t('0nc_supplier_txt');
  }
  return undefined;
};

const PartnerLogo: FC<Props> = ({
  name,
  width = 75,
  height = 25,
  label = undefined,
  className = undefined,
  textOnly = undefined,
  splitLine = false
}) => {
  const { t } = useTranslation();
  const [isImageFailed, setIsImageFailed] = useState(false);

  if (!name) return null;

  const handleBrokenImage = () => {
    setIsImageFailed(true);
  };

  const imgPath = `${IMAGES_URL}${name.toLowerCase()}/${width}x${height}/`;

  return (
    <span
      className={classNames(styles.partnerLogoContainer, styles[label], className, {
        [styles['line_between']]: splitLine
      })}
    >
      {labelDisplayed(label, t) && (
        <span className={classNames(styles.partnerLogoLabel, label)}>
          {labelDisplayed(label, t)}
        </span>
      )}
      {(textOnly || isImageFailed) && (
        <span className={styles.partnerLogoName}>
          {`${t(`partner_${name.toLowerCase()}`, name)}`}
        </span>
      )}
      {!textOnly && !isImageFailed && (
        <img
          onError={() => handleBrokenImage()}
          alt={`${t(`partner_${name.toLowerCase()}`, name)}`}
          src={imgPath}
          title={`${t(`partner_${name.toLowerCase()}`, name)}`}
          className={classNames(styles.partnerLogoImg)}
        />
      )}
    </span>
  );
};

export default memo(PartnerLogo);
