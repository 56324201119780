import testEmail from 'validator/lib/isEmail';
import { isValid as isValidIban } from 'iban';
import { MutableRefObject } from 'react';
import moment from 'moment';
import validator from 'validator';
import { isNumber } from '@turf/helpers';
import { unique } from 'src/utils/array';
import { Validator, ValidatorResponse } from './types';

const PHONE_REGEX = /^[0-9+]{1,5}[0-9\-/]{3,15}$/;
const CC_NUMBER_REGEX =
  /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;

const mandatory = (value: string, customMessage?: string): ValidatorResponse => {
  if (value) return { isValid: true, messages: [] };
  return {
    isValid: false,
    messages: [{ key: customMessage || 'notification_field_mandatory' }]
  };
};

const safeString = (value: string, customMessage?: string): ValidatorResponse => {
  const forbiddenChars = (value ?? '').match(/([+!#$%^*<>(){};':",<>/\\~\t`])/gm) || [];

  if (forbiddenChars.length === 0) return { isValid: true, messages: [] };

  return {
    isValid: false,
    messages: [
      {
        key: customMessage || 'forbidden_characters',
        data: { chars: unique(forbiddenChars).join(', ') }
      }
    ]
  };
};

const address = (value: string, customMessage?: string): ValidatorResponse => {
  const forbiddenChars = (value ?? '').match(/([+!#$%^*<>(){};:"<>~\\\t`])/gm) || [];

  if (forbiddenChars.length === 0) return { isValid: true, messages: [] };
  return {
    isValid: false,
    messages: [
      {
        key: customMessage || 'forbidden_characters',
        data: { chars: unique(forbiddenChars).join(', ') }
      }
    ]
  };
};

const hasMinLength = (value: string, length: number, customMessage?: string): ValidatorResponse => {
  if (value.length === 0 || value.length >= length) return { isValid: true, messages: [] };
  return {
    isValid: false,
    messages: [{ key: customMessage || 'notification_min_length', data: { count: length } }]
  };
};

const hasMaxLength = (value: string, length: number, customMessage?: string): ValidatorResponse => {
  if (value.length <= length) return { isValid: true, messages: [] };
  return {
    isValid: false,
    messages: [{ key: customMessage || 'notification_max_length', data: { count: length } }]
  };
};

const isEmail = (value: string, customMessage?: string): ValidatorResponse => {
  if (testEmail(value)) return { isValid: true, messages: [] };
  return {
    isValid: false,
    messages: [{ key: customMessage || 'notification_incorrect_email' }]
  };
};

const equalEmail = (value: string, pattern: string, customMessage?: string): ValidatorResponse => {
  if (value === pattern) return { isValid: true, messages: [] };
  return {
    isValid: false,
    messages: [{ key: customMessage || 'notification_email_no_match' }]
  };
};

const isPhone = (value: string, customMessage?: string): ValidatorResponse => {
  if (PHONE_REGEX.test(value)) return { isValid: true, messages: [] };
  return {
    isValid: false,
    messages: [{ key: customMessage || 'notification_incorrect_phone' }]
  };
};

const isIban = (value: string, customMessage?: string): ValidatorResponse => {
  if (isValidIban(value)) {
    return {
      isValid: true,
      messages: []
    };
  }

  return {
    isValid: false,
    messages: [{ key: customMessage || 'notification_incorrect_iban' }]
  };
};

const isBic = (value: string, customMessage?: string): ValidatorResponse => {
  const regSwift = /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;
  if (regSwift.test(value)) {
    return {
      isValid: true,
      messages: []
    };
  }

  return {
    isValid: false,
    messages: [{ key: customMessage || 'bicFormat' }]
  };
};

const isBicCountryAllowed = (
  value: string,
  allowedCountries: Array<string>,
  customMessage?: string
): ValidatorResponse => {
  let valid = true;

  if (allowedCountries.length > 0) {
    const bicCountryCode = value.substring(4, 6);
    if (allowedCountries.indexOf(bicCountryCode) < 0) {
      valid = false;
    }
  }

  if (valid === true) {
    return {
      isValid: true,
      messages: []
    };
  }

  return {
    isValid: false,
    messages: [{ key: customMessage || 'bicCountryAllowed' }]
  };
};

const isBicCountryMatch = (
  value: string,
  accountNumber: string,
  customMessage?: string
): ValidatorResponse => {
  let valid = true;

  if (accountNumber.length > 0) {
    const bicCountryCode = value.substring(4, 6);
    const ibanCountryCode = accountNumber.substring(0, 2);
    if (bicCountryCode !== ibanCountryCode) {
      valid = false;
    }
  }

  if (valid === true) {
    return {
      isValid: true,
      messages: []
    };
  }

  return {
    isValid: false,
    messages: [{ key: customMessage || 'bicCountryMatch' }]
  };
};

const isTime = (value: string, timeFormat: string, customMessage?: string): ValidatorResponse => {
  if (timeFormat && timeFormat.startsWith('hh')) {
    timeFormat = `HH${timeFormat.slice(2)}`;
  }
  const time = moment(value, timeFormat, true);
  if (time.isValid() === true || value.length === 0) {
    return {
      isValid: true,
      messages: []
    };
  }

  return {
    isValid: false,
    messages: [{ key: customMessage || '0nc_v_incorrect_time_format' }]
  };
};

const isFlightNumber = (
  value: string,
  timeFormat: string,
  customMessage?: string
): ValidatorResponse => {
  const regFlightNo = /^([a-zA-Z]{2,3}|[a-zA-Z]{1}[\d]{1}|[\d]{1}[a-zA-Z]{1})(\d{1,4})$/;
  if (regFlightNo.test(value)) {
    return {
      isValid: true,
      messages: []
    };
  }

  return {
    isValid: false,
    messages: [{ key: customMessage || 'notification_reason_flight_number' }]
  };
};

const isProperNumber = (value: string, customMessage?: string): ValidatorResponse => {
  if (isNumber(value)) {
    return {
      isValid: true,
      messages: []
    };
  }
  return {
    isValid: false,
    messages: [
      {
        key: customMessage || '0nc_v_required_digits'
      }
    ]
  };
};

export const isCountryCode = (value: string, customMessage?: string): ValidatorResponse =>
  String(value).length === 2 && validator.isAlpha(value)
    ? {
        isValid: true,
        messages: []
      }
    : {
        isValid: false,
        messages: [{ key: customMessage || 'incorrect_country_code' }]
      };

const isCreditCardNumber = (
  value: string,
  customMessage?: string,
  negative?: boolean
): ValidatorResponse => {
  if (CC_NUMBER_REGEX.test(value)) {
    if (negative === true) {
      return {
        isValid: false,
        messages: [{ key: customMessage || '' }]
      };
    }

    return {
      isValid: true,
      messages: []
    };
  }

  if (negative === true) {
    return {
      isValid: true,
      messages: []
    };
  }

  return {
    isValid: false,
    messages: [{ key: customMessage || '0nc_v_incorrect_cc_nr' }]
  };
};

const luhnCheck = (number) => {
  /* Luhn algorithm number checker - (c) 2005-2008 shaman - www.planzero.org *
   * This code has been released into the public domain, however please      *
   * give credit to the original author where possible.                      */

  // Strip any non-digits (useful for credit card numbers with spaces and hyphens)
  number = number.replace(/\D/g, '');

  // Set the string length and parity
  const numberLength = number.length;
  const parity = numberLength % 2;

  // Loop through each digit and do the maths
  let total = 0;
  for (let i = 0; i < numberLength; i += 1) {
    let digit = number.charAt(i);
    // Multiply alternate digits by two
    if (i % 2 === parity) {
      digit *= 2;
      // If the sum is two digits, add them together (in effect)
      if (digit > 9) {
        digit -= 9;
      }
    }
    // Total up the digits
    total += parseInt(digit);
  }

  // If the total mod 10 equals 0, the number is valid
  if (total % 10 === 0) {
    return true;
  }
  return false;
};

const isProperBahndecard = (program: any, customMessage?: string): ValidatorResponse => {
  let valid = true;
  if (program?.select === 'DBB' || program?.select === 'DB') {
    if (program?.text?.length < 16) valid = false;
    if (!program?.text?.startsWith('7081')) valid = false;
    if ([1, 2, 3].indexOf(parseInt(program?.text[5], 10)) < 0) valid = false;
    if (luhnCheck(program?.text) !== true) valid = false;

    if (valid !== true) {
      return {
        isValid: valid,
        messages: [
          {
            key: customMessage || '0nc_v_incorrect_ftn_nr'
          }
        ]
      };
    }
  }
  return {
    isValid: valid,
    messages: []
  };
};

const processValidator = (value: any, v: Validator): ValidatorResponse => {
  switch (v.type) {
    case 'mandatory':
      return mandatory(value, v.customMessage);

    case 'safeString':
      return safeString(value, v.customMessage);

    case 'address':
      return address(value, v.customMessage);

    case 'minLength':
      return hasMinLength(value, v.value, v.customMessage);

    case 'maxLength':
      return hasMaxLength(value, v.value, v.customMessage);

    case 'email':
      return isEmail(value, v.customMessage);

    case 'equalEmail':
      return equalEmail(value, v.value, v.customMessage);

    case 'iban':
      return isIban(value, v.customMessage);

    case 'phone':
      return isPhone(value, v.customMessage);

    case 'bic':
      return isBic(value, v.customMessage);

    case 'bicCountryMatch':
      return isBicCountryMatch(value, v.value, v.customMessage);

    case 'bicCountryAllowed':
      return isBicCountryAllowed(value, v.value, v.customMessage);

    case 'time':
      return isTime(value, v.format, v.customMessage);

    case 'flightNumber':
      return isFlightNumber(value, v.format, v.customMessage);

    case 'number':
      return isProperNumber(value, v.customMessage);

    case 'countryCode':
      return isCountryCode(value, v.customMessage);

    case 'creditCardNumber':
      return isCreditCardNumber(value, v.customMessage);

    case 'noCreditCardNumber':
      return isCreditCardNumber(value, v.customMessage, true);

    case 'bahndecard':
      return isProperBahndecard(value, v.customMessage);

    default:
      return { isValid: true, messages: [] };
  }
};

export const validateValue = (value: any, validators: Array<Validator>): ValidatorResponse => {
  const result: ValidatorResponse = { isValid: true, messages: [] };
  validators.forEach((v) => {
    const { isValid, messages } = processValidator(value, v);
    if (!isValid) {
      result.isValid = false;
      if (messages && messages.length > 0) {
        result.messages?.push(messages[0]);
      }
    }
  });
  return { isValid: result.isValid, messages: result.messages?.slice(0, 1) };
};

export const isMandatory = (validators: Array<Validator>): boolean =>
  validators.findIndex((item) => item.value === 'mandatory') !== -1;

export const refObjectToArray = (ref: MutableRefObject<any>): Array<MutableRefObject<any>> =>
  Object.keys(ref.current).map((key) => ({
    current: ref.current[key]
  }));

export const validateInputs = (
  refs: Array<MutableRefObject<any>>,
  name?: string
): ValidatorResponse => {
  const result: ValidatorResponse = {
    isValid: true,
    name,
    messages: [],
    children: []
  };

  refs.forEach((fieldRef) => {
    if (!fieldRef || !fieldRef.current) return;
    const response = fieldRef.current.validate();
    if (!response.isValid) {
      result.isValid = false;
      result.messages = result.messages?.concat(response.messages);
      result.children = result.children?.concat(response);
    }
  });

  return result;
};
