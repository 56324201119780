import { Request } from 'express';
import { get } from 'lodash';
import { IncomingMessage } from 'http';
import { Subject } from 'rxjs';
import requestIp from 'request-ip';
import ipRegex from 'src/utils/ip';
import { Location } from 'components/suggester/types';
import { SearchData } from 'types/search/search-data.type';
import { Moment } from 'moment';
import util from 'util';
import { v4 as uuidv4 } from 'uuid';

declare let importScripts: any;

// Whether script executes on the server
export const serverSide = (): boolean => typeof window === 'undefined';

// Whether script executes in the browser
export const browserSide = (): boolean => typeof window !== 'undefined';

export const isWorker = (): boolean => typeof importScripts === 'function';

// Get client ip from express request
export const extractClientIp = (req: Request): string => {
  const ip = requestIp.getClientIp(req);
  return ip !== null &&
    ipRegex({ exact: true }).test(ip) &&
    (ipRegex.v4({ exact: true }).test(ip) || ipRegex.v6({ exact: true }).test(ip))
    ? ip
    : '';
};

// Wheter string is a nubmer
export const isNumber = (value: string): boolean => {
  const regex = /^([0-9]+)$/;
  return regex.test(value);
};

// Merge multiple subjects into single one
export const allSubjects = <T>(subjects: Array<Subject<T>>): Subject<T> => {
  const stream = new Subject<T>();
  let toComplete = subjects.length;

  if (toComplete > 0) {
    subjects.forEach((item) => {
      item.subscribe(
        (data) => {
          stream.next(data);
        },
        (err) => {
          stream.error(err);
        },
        () => {
          toComplete -= 1;
          if (toComplete === 0) {
            stream.complete();
          }
        }
      );
    });
  } else {
    stream.complete();
  }

  return stream;
};

export const checkIfMobileOnServerSide = ({ headers }: IncomingMessage): boolean => {
  const userAgent = headers && headers['user-agent'];
  const isMobile = ((a) => {
    if (!a) return false;
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\-(n|u)|c55\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i.test(
        a.substr(0, 4)
      )
    ) {
      return true;
    }
    return false;
  })(userAgent);
  return isMobile;
};

// Skip function
export const skipRejection = () => null;

export const sameLocations = (
  a: Location | undefined,
  b: Location | undefined,
  trueIfNoB = false
): boolean => {
  if (trueIfNoB && a && !b) return true;
  return get(a, 'locationKey') === get(b, 'locationKey');
};

export const sameSearch = (a: SearchData, b: SearchData): boolean => {
  if (!a && !b) return true;
  if (!a || !b) return false;
  if (a.agentId !== b.agentId) return false;
  if (!sameLocations(a.location?.pickUp, b.location?.pickUp, true)) return false;
  if (!sameLocations(a.location?.dropOff, b.location?.dropOff, true)) return false;

  if (!(a.dates?.from as Moment).isSame(b.dates?.from as Moment)) return false;
  if (!(a.dates?.to as Moment).isSame(b.dates?.to as Moment)) return false;

  if (a.driverAge !== b.driverAge) return false;
  if (a.driverCountry !== b.driverCountry) return false;
  if (a.locale !== b.locale) return false;
  return true;
};

export const randId = (): string => uuidv4();

export const proxyUrl = (debugApi?: string): string => {
  if (debugApi) return debugApi;
  return typeof window === 'undefined' ? 'http://127.0.0.1:3000/proxy/' : '/proxy/';
};

export const printObject = (obj: any) => util.inspect(obj, false, null, true);

export const firstUrlParamValue = (val: string | Array<string>): string =>
  Array.isArray(val) && val.length > 0 ? val[0] : (val as string);

export const sameObjects = (a: any, b: any) =>
  Object.keys(a).reduce((diff, key) => {
    if (b[key] !== a[key]) return false;
    return diff;
  }, true);
export const localStorageAvailable = (): boolean => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export const sessionStorageAvailable = (): boolean => {
  try {
    const testKey = '__test__';
    sessionStorage.setItem(testKey, testKey);
    sessionStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export const scrollToRef = (obj: any) => {
  if (!obj) return;
  obj.scrollIntoView({ behavior: 'smooth' });
};
