import useCarsApi from 'providers/cars-api/use-cars-api';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import React, { FC, useEffect, useState } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import { getQueryParam } from 'src/utils/url-tools';
import { Location } from 'components/suggester/types';
import axiosInstance from 'src/axios-instance';
import styles from '../common.module.scss';

const DEFAULT_IMAGE = 'https://media.mietwagen.de/media/de_frankfurt.jpg';

const fetchImage = async (location: string, country = 'DE'): Promise<string> => {
  if (!location) return DEFAULT_IMAGE;

  const resp = await axiosInstance
    .post('/proxy/city-background', { location, country })
    .catch(() => null);

  if (!resp) return DEFAULT_IMAGE;

  return resp ? resp.data : DEFAULT_IMAGE;
};

const getCountry = (location: string) => {
  if (typeof location === 'undefined') return '';
  const split = location.split('.');
  return split[1] || 'DE';
};

const DynamicLocationBackground: FC = () => {
  const [locationKey, setLocationKey] = useState('');
  const { agent, globalEvents } = useCarsApi();
  const { isMobile } = useScreenDetect();
  const [src, setSrc] = useState(DEFAULT_IMAGE);
  const [srcSecondary, setSrcSecondary] = useState(DEFAULT_IMAGE);
  const [useSrcSecondary, setUseSrcSecondary] = useState(false);

  const refreshImage = (location: Location) => {
    if (!location?.locationKey || locationKey === location?.locationKey) return;
    setLocationKey(location.locationKey);
    fetchImage(location.locationKey, getCountry(location.locationKey)).then((response) => {
      if (response) {
        setUseSrcSecondary(true);
        setSrc(response);
        setTimeout(() => {
          setUseSrcSecondary(false);
          setTimeout(() => {
            setSrcSecondary(response);
          }, 2000);
        }, 1000);
      }
    });
  };

  useEffect(() => {
    if (!get(agent, 'layoutSettings.dynamicBackground', false) || isMobile) return () => null;

    const location = getQueryParam('pckloc');
    setLocationKey(location);

    fetchImage(location, getCountry(location)).then((response) => {
      if (response) {
        setSrc(response);
        setSrcSecondary(response);
      }
    });

    const sub = globalEvents.subscribe({
      next(e) {
        if (e.type !== 'pickupLocationChanged') return;
        refreshImage(e.data);
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, [isMobile]);

  if (!get(agent, 'layoutSettings.dynamicBackground', false) || isMobile) return null;

  return (
    <div
      className={classNames(styles.dynamicBackground__bg, {
        [styles['dynamicBackground__bg__useSecondary']]: useSrcSecondary
      })}
    >
      <div
        className={classNames(
          styles.dynamicBackground__bg__img,
          styles.dynamicBackground__bg__img_primary
        )}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.45)), url(${src})`
        }}
      />
      <div
        className={classNames(
          styles.dynamicBackground__bg__img,
          styles.dynamicBackground__bg__img_secondary
        )}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.45)), url(${srcSecondary})`
        }}
      />
    </div>
  );
};

export default DynamicLocationBackground;
