/* eslint no-shadow: "off" */

enum AllowedPages {
  '',
  '/',
  '/index/',
  '/booking/',
  '/confirmation/',
  '/headerspreview/'
}

export default AllowedPages;
