import React, { FC, createContext, useEffect } from 'react';
import useState from 'react-usestateref';
import ReactDOM from 'react-dom';
import { CarOffer } from 'src/cars/types';
import {
  initFilters,
  removeSingleFilter,
  stringToFilter,
  updateFiltersUrlQuery,
  updateSingleFilter
} from 'src/filters';
import { cloneDeep } from 'lodash';
import {
  Filter,
  Filters,
  FilterType,
  offerDepositFilterCheckAgainst,
  offerHasDepositFilterCheckAgainst
} from 'src/filters/types';
import { carTypeFilterCheckAgainst } from 'types/car-type.type';
import { carClassFilterCheckAgainst } from 'types/car-class.type';
import { carScoreFilterCheckAgainst } from 'types/car-score.type';
import { fuelPolicyCheckAgainst } from 'src/cars/types/fuel-policy.type';
import { TestMode } from 'types/engine/dev-data.type';
import { SearchData } from 'types/search/search-data.type';
import { Station, stationFilterCheckAgainst } from 'src/stations/types';
import { CurrencyRate } from 'types/currency-rate.type';
import { equipFilterCheckAgainst } from 'types/car-equip.type';
import { carMeetsFilters } from 'src/cars/car-tools';
import { updateSearchParams } from 'src/url-manager';
import { getQueryParam } from 'src/utils/url-tools';
import { Subscription } from 'rxjs';
import { Moment } from 'moment';
import { urlDateFormat } from 'src/config';
import { wsInfo } from 'src/logger';
import { FiltersRange, OffersContextProps, OffersContextType } from './types';
import {
  compareKeyInOffer,
  createFiltersRange,
  extendFiltersRange,
  startSearchProcess
} from './tools';

const offersContext = createContext<OffersContextType>({
  searching: false,
  finished: false,
  firstVisit: true,
  progress: 0,
  searchId: '',
  searchData: undefined,
  offers: [],
  allOffers: [],
  pricesStartsFrom: {},
  stations: [],
  filtersRange: createFiltersRange(),
  total: 0,
  filtered: 0,
  startSearch: () => null,
  clearSearch: () => null,
  filters: [],
  clearFilters: () => null,
  removeFilter: () => null,
  updateFilter: () => null,
  inCompareMode: false,
  updateInCompareMode: () => null,
  exchangeRates: {}
});

const OffersProvider: FC<OffersContextProps> = ({ children, agent, general }) => {
  const [searchSubscription, setSearchSubscription, searchSubscriptionRef] =
    useState<Subscription>();
  const [searching, setSearching] = useState(false);
  const [finished, setFinished] = useState(false);
  const [firstVisit, setFirstVisit] = useState(true);
  const [progress, setProgress, progressRef] = useState(0);
  const [searchId, setSearchId] = useState(getQueryParam('searchid'));
  const [searchData, setSearchData] = useState<SearchData>();

  const [allOffers, setAllOffers, allOffersRef] = useState<Array<CarOffer>>([]);
  const [offers, setOffers] = useState<Array<CarOffer>>([]);
  const [filtersRange, setFiltersRange, filtersRangeRef] =
    useState<FiltersRange>(createFiltersRange());
  const [stations, setStations, stationsRef] = useState<Array<Station>>([]);

  const [total, setTotal] = useState(0);
  const [filtered, setFiltered] = useState(0);
  const [filters, setFilters] = useState<Filters>([]);

  const [exchangeRates, setExchangeRates] = useState<CurrencyRate>({
    agentCurrency: agent?.currency,
    rates: {}
  });

  const [inCompareMode, setInCompareMode] = useState(false);

  const [pricesStartsFrom, setPricesStartsFrom] = useState({});
  const partnerFilterCheckAgainst = () => filtersRange?.partners ?? [];

  const FILTERS_SINGLE_SELECTION = {
    station: stationFilterCheckAgainst,
    equip: equipFilterCheckAgainst,
    deposit: offerDepositFilterCheckAgainst,
    hasDeposit: offerHasDepositFilterCheckAgainst
  };

  const FILTERS_MULTIPLE_SELECTION = {
    partner: partnerFilterCheckAgainst(),
    carType: carTypeFilterCheckAgainst,
    class: carClassFilterCheckAgainst,
    carScore: carScoreFilterCheckAgainst,
    fuel: fuelPolicyCheckAgainst
  };

  const FILTERS_SINGLE_KEYS = Object.keys(FILTERS_SINGLE_SELECTION);

  const countPricesStartsFrom = () => {
    const pricesFrom = {};
    if (offers.length > 0) {
      const sortedOffers = offers.sort((a, b) => a.priceSort - b.priceSort);
      sortedOffers.forEach((offer) => {
        FILTERS_SINGLE_KEYS.forEach((key) => {
          if (Array.isArray(FILTERS_SINGLE_SELECTION[key])) {
            if (!pricesFrom[key]) {
              pricesFrom[key] = {};
            }
            FILTERS_SINGLE_SELECTION[key].forEach((filter) => {
              if (typeof pricesFrom[key][filter] === 'undefined') {
                if (carMeetsFilters(offer, [stringToFilter(key as FilterType, filter)])) {
                  pricesFrom[key][filter] = {
                    price: offer.priceSort,
                    offerId: offer.offerId
                  };
                }
              }
            });
          }
        });
      });
      setPricesStartsFrom(pricesFrom);
    }

    if (filters) {
      if (allOffers.length > 0) {
        const sortedAllOffers = allOffers.sort((a, b) => a.priceSort - b.priceSort);
        const FILTERS_MULTIPLE_KEYS = Object.keys(FILTERS_MULTIPLE_SELECTION);
        sortedAllOffers.forEach((offer) => {
          FILTERS_MULTIPLE_KEYS.forEach((key2) => {
            if (Array.isArray(FILTERS_MULTIPLE_SELECTION[key2])) {
              if (!pricesFrom[key2]) {
                pricesFrom[key2] = {};
              }
              let offerQualifies = true;
              FILTERS_MULTIPLE_SELECTION[key2].forEach((filter2) => {
                // check used filters (without FILTERS_MULTIPLE_KEYS)
                filters.forEach((usedFilter) => {
                  if (usedFilter.type !== key2) {
                    if (!carMeetsFilters(offer, [usedFilter])) {
                      offerQualifies = false;
                    }
                  }
                });
                if (
                  offerQualifies &&
                  !pricesFrom[key2][filter2] &&
                  filter2 === compareKeyInOffer(offer, key2)
                ) {
                  pricesFrom[key2][filter2] = {
                    price: offer.priceSort,
                    offerId: offer.offerId
                  };
                }
              });
            }
          });
        });
        setPricesStartsFrom(pricesFrom);
      }
    }
  };

  /* ------------------------------------------------------------------------- SEARCH PROCESS --- */

  const resetState = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setFinished(false);
      setSearching(true);
      setAllOffers([]);
      setOffers([]);
      setTotal(0);
      setStations([]);
      setFiltersRange(null);
      setFiltered(0);
      setProgress(0);
      setExchangeRates({
        agentCurrency: agent.currency,
        rates: {}
      });
    });
  };

  const startSearch = (
    search: SearchData,
    forceNewSearch = false,
    cancellSearch = false,
    testMode: TestMode = [],
    partners: Array<string> = [],
    customProxy = ''
  ): Promise<void> => {
    if (searching && !cancellSearch) return Promise.reject();
    if (searchSubscriptionRef.current) {
      searchSubscriptionRef.current.unsubscribe();
      setSearchSubscription(undefined);
    }
    setSearchData(cloneDeep(search));
    resetState();
    return new Promise((resolve, reject) => {
      let allArr: Array<CarOffer> = [];
      let filteredArr: Array<CarOffer> = [];

      setSearchSubscription(
        startSearchProcess(
          search,
          agent.agentId,
          agent.currency,
          general.affiliateId,
          forceNewSearch,
          testMode,
          partners,
          customProxy
        ).subscribe({
          next: (e) => {
            switch (e.type) {
              case 'start':
                setProgress(0);
                setSearching(true);
                break;

              case 'initialized':
                setSearchId(e.data.searchId);
                updateSearchParams([
                  { name: 'searchid', value: e.data.searchId },
                  { name: 'pckloc', value: search.location.pickUp?.locationKey },
                  { name: 'drploc', value: search.location.dropOff?.locationKey },
                  { name: 'pckdate', value: (search.dates.from as Moment).format(urlDateFormat) },
                  { name: 'pcktime', value: (search.dates.from as Moment).format('HH:mm') },
                  { name: 'drpdate', value: (search.dates.to as Moment).format(urlDateFormat) },
                  { name: 'drptime', value: (search.dates.to as Moment).format('HH:mm') },
                  { name: 'driver_age', value: String(search.driverAge) }
                ]);
                break;

              case 'update':
                allArr = allOffersRef.current.concat(e.data.offers);
                filteredArr = allArr.filter((car) => carMeetsFilters(car, filters));

                ReactDOM.unstable_batchedUpdates(() => {
                  setExchangeRates({
                    agentCurrency: agent.currency,
                    rates: {
                      ...(exchangeRates.rates ?? {}),
                      ...(e.data.exchangeRates?.rates ?? {})
                    }
                  });
                  setAllOffers(allArr);
                  setOffers(filteredArr);
                  setStations(stationsRef.current.concat(e.data.stations));
                  setFiltersRange(
                    extendFiltersRange(filtersRangeRef.current ?? filtersRange, e.data.filtersRange)
                  );
                  setFiltered(filteredArr.length);
                  setTotal(allArr.length);
                  if (e.data.percentageProgress) {
                    setProgress(progressRef.current + e.data.percentageProgress);
                  }
                });
                break;

              default:
            }
          },
          error: (err) => {
            setFinished(true);
            setSearching(false);
            setProgress(100);
            reject(err);
          },
          complete: () => {
            setFinished(true);
            setSearching(false);
            setProgress(100);
            resolve();
          }
        })
      );
    });
  };

  const clearSearch = () => {
    setSearchId('');
    setSearchData(undefined);
    setFinished(false);
    setSearching(false);
    setAllOffers([]);
    setOffers([]);
    setTotal(0);
    setStations([]);
    setFiltersRange(createFiltersRange());
    setFiltered(0);
    setProgress(0);
    setExchangeRates({});
  };

  useEffect(() => {
    setFilters(initFilters());
  }, []);

  /* ----------------------------------------------------------------------------- FILTRATION --- */

  useEffect(() => {
    countPricesStartsFrom();
  }, [offers, filtersRange]);

  const clearFilters = () => {
    wsInfo('userInteraction', 'Clear filters');
    setFilters([]);
    updateFiltersUrlQuery([]);
    setOffers(allOffers);
    setFiltered(total);
  };

  const removeFilter = (type: FilterType) => {
    const newFilters = removeSingleFilter(filters, type);

    const newList =
      newFilters.length === 0
        ? allOffers
        : allOffers.filter((car) => carMeetsFilters(car, newFilters));
    setFilters(newFilters);
    setOffers(newList);
    setFiltered(newList.length);
    wsInfo('userInteraction', `Remove filter: ${type}`, {
      filterType: type,
      activeFilters: newFilters,
      offers: newList.length
    });
  };

  const updateFilter = (filter: Filter) => {
    const newFilters = updateSingleFilter(filters, filter);
    const newList =
      newFilters.length === 0
        ? allOffers
        : allOffers.filter((car) => carMeetsFilters(car, newFilters));
    setFilters(newFilters);
    setOffers(newList);
    setFiltered(newList.length);
    wsInfo('userInteraction', `Update filter: ${filter.type}`, {
      update: filter,
      activeFilters: newFilters,
      offers: newList.length
    });
  };

  const updateInCompareMode = (newState) => {
    setInCompareMode(newState);
  };

  useEffect(() => {
    setFirstVisit(false);
  }, []);

  return (
    <offersContext.Provider
      value={{
        searching,
        finished,
        firstVisit,
        progress,
        offers,
        searchId,
        searchData,
        allOffers,
        stations,
        filtersRange,
        total,
        filtered,
        startSearch,
        clearSearch,
        filters,
        clearFilters,
        removeFilter,
        updateFilter,
        inCompareMode,
        updateInCompareMode,
        exchangeRates,
        pricesStartsFrom
      }}
    >
      {children}
    </offersContext.Provider>
  );
};

export { offersContext, OffersProvider };
