export type CarClass =
  | 'mini'
  | 'mini_elite'
  | 'economy'
  | 'economy_elite'
  | 'compact'
  | 'compact_elite'
  | 'intermediate'
  | 'intermediate_elite'
  | 'standard'
  | 'standard_elite'
  | 'fullsize'
  | 'fullsize_elite'
  | 'premium'
  | 'premium_elite'
  | 'luxury'
  | 'luxury_elite'
  | 'oversize'
  | 'special'
  | 'commercial';

export const carClassFilterCheckAgainst = [
  'mini',
  'mini_elite',
  'economy',
  'economy_elite',
  'compact',
  'compact_elite',
  'intermediate',
  'intermediate_elite',
  'standard',
  'standard_elite',
  'fullsize',
  'fullsize_elite',
  'premium',
  'premium_elite',
  'luxury',
  'luxury_elite',
  'oversize',
  'special',
  'commercial'
];
