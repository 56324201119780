import React, { FC, createContext, useState } from 'react';
import parse from 'html-react-parser';
import Head from 'next/head';
import { parseScriptTags } from 'src/utils/string';
import {
  BookingformTrackingParams,
  PricelistTrackingParams,
  SearchformTrackingParams,
  TrackingContextType,
  TrackingProviderProps
} from './types';

const trackingContext = createContext<TrackingContextType>({
  trackingCode: { key: '', html: '', js: '' },
  triggerSearch: () => null,
  triggerPricelist: () => null,
  triggerBooking: () => null,
  triggerConfirmation: () => null
});

const TrackingProvider: FC<TrackingProviderProps> = ({ children, customTracking }) => {
  const [trackingCode, setTrackingCode] = useState({ key: '', html: '', js: '' });

  const parseCode = (html: string, data: any = {}): string => {
    if (!data || !html) return html;
    data.random_number = Math.random();
    Object.keys(data).forEach((key) => {
      html = html.replace(new RegExp(`@${key}@`, 'gim'), data[key]);
    });
    return html;
  };

  const triggerSearch = (data: SearchformTrackingParams) => {
    setTrackingCode({
      key: 'search',
      html: '',
      js: parseCode(customTracking.search, data)
    });
  };

  const triggerPricelist = (data: PricelistTrackingParams) => {
    setTrackingCode({
      key: 'pricelist',
      html: '',
      js: parseCode(customTracking.pricelist, data)
    });
  };

  const triggerBooking = (data: BookingformTrackingParams) => {
    setTrackingCode({
      key: 'booking',
      html: '',
      js: parseCode(customTracking.booking, data)
    });
  };

  const triggerConfirmation = (data: any) => {
    setTrackingCode({
      key: 'booking',
      html: '',
      js: parseCode(customTracking.confirmation, data)
    });
  };

  return (
    <>
      <Head>
        {trackingCode.js !== '' &&
          parseScriptTags(trackingCode.js).map((attrs, i) => (
            <script
              key={`tracking-script-${trackingCode.key}-${i}`}
              src={attrs.src}
              dangerouslySetInnerHTML={{
                __html: `/* Custom tracking script no.${i} */${attrs.content}`
              }}
            />
          ))}
      </Head>
      <trackingContext.Provider
        value={{
          trackingCode,
          triggerSearch,
          triggerPricelist,
          triggerBooking,
          triggerConfirmation
        }}
      >
        {children}
      </trackingContext.Provider>
      {trackingCode.html !== '' && parse(trackingCode.html)}
    </>
  );
};

export { trackingContext, TrackingProvider };
