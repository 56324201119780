import { sessionStorageAvailable } from 'src/utils';
import { prepareToken } from 'src/utils/string';

export const TAB_ID_LENGTH = 6;
export const TAB_ID_KEY = 'tabId';

export const hasTabId = (): boolean => {
  if (!sessionStorageAvailable()) return false;
  return !!sessionStorage.getItem(TAB_ID_KEY);
};

export const initTabId = (): string => {
  if (!sessionStorageAvailable()) return '';
  if (sessionStorage.getItem(TAB_ID_KEY)) return sessionStorage.getItem(TAB_ID_KEY) || '';
  const id = prepareToken(TAB_ID_LENGTH);
  sessionStorage.setItem(TAB_ID_KEY, id);
  return id;
};

export const getTabId = (): string => {
  if (!sessionStorageAvailable()) return prepareToken(TAB_ID_LENGTH);
  return sessionStorage.getItem(TAB_ID_KEY) ?? '';
};
