import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';
import useTranslation from 'providers/translations/use-translations';
import { ucFirst } from 'src/utils/string';
import styles from '../compare.module.scss';

interface Props {
  offer?: CarOffer;
}

const CarClassCell: FC<Props> = ({ offer }) => {
  const { t } = useTranslation();

  return (
    <div>
      {offer && <span className={styles.comparison__carType}>{ucFirst(t(offer.cartype))}</span>}
    </div>
  );
};

CarClassCell.defaultProps = {
  offer: null
};

export default CarClassCell;
