export type FuelPolicy =
  | 'PFTE'
  | 'PFT'
  | 'PFTF'
  | 'ETE'
  | 'FTE'
  | 'FTF'
  | 'HTE'
  | 'HTH'
  | 'QTE'
  | 'QTQ'
  | string;

export const fuelPolicyCheckAgainst = ['FTF', 'FTE', 'other'];

export const otherFuelPolicy = [
  'PFTE',
  'PFTF',
  'ETE',
  'HTE',
  'HTH',
  'QTE',
  'QTQ',
  'SAME',
  'PFT',
  ''
];
