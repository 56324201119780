import React, { FC } from 'react';
import ErrorMessage from './error-message';

type Props = {
  resetErrorBoundary: () => any;
};

const ErrorFallback: FC<Props> = (props) => <ErrorMessage onBack={props.resetErrorBoundary} />;

export default ErrorFallback;
