import { useContext } from 'react';
import { LayoutTheme } from 'types/layout/layout-theme.type';
import { themeContext } from '.';

const useTheme = (): LayoutTheme => {
  const { settings, customHeaders, customCssUrl } = useContext(themeContext);
  return {
    settings,
    customHeaders,
    customCssUrl
  };
};

export default useTheme;
