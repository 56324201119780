import React, { useEffect, useState } from 'react';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';

import useHasMounted from 'hooks/use-has-mounted';
import TextInput from 'components/forms/text-input';
import Button from 'components/forms/button';
import styles from './dev-tools.module.scss';

const BackendProxy = () => {
  const hasMounted = useHasMounted();
  const { customProxy, setCustomProxy } = useDevToolsContext();

  const [proxy, setProxy] = useState(customProxy);

  useEffect(() => {
    setProxy(customProxy);
  }, [customProxy]);

  return (
    <div>
      <h5 className={styles.subtitle}>Backend proxy</h5>
      <ul className={styles.customProxy}>
        <li className={styles.customProxy__labelGroup}>
          <span>Current proxy:</span>
          {hasMounted && <strong>{customProxy || `${window.location.origin}/proxy/`}</strong>}
        </li>

        <li className={styles.customProxy__labelGroup}>
          <TextInput
            width="300px"
            label="Change local proxy to:"
            value={proxy}
            onChange={setProxy}
            horizontal
          />
          <Button onClick={() => setCustomProxy(proxy)}>Change</Button>
        </li>
      </ul>
    </div>
  );
};

export default BackendProxy;
