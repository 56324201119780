import { useContext } from 'react';
import { trackingContext } from '.';
import { TrackingContextType } from './types';

const useTracking = (): TrackingContextType => {
  const { trackingCode, triggerSearch, triggerPricelist, triggerBooking, triggerConfirmation } =
    useContext(trackingContext);

  return {
    trackingCode,
    triggerSearch,
    triggerPricelist,
    triggerBooking,
    triggerConfirmation
  };
};

export default useTracking;
