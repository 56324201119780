import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';
import useTranslation from 'providers/translations/use-translations';
import { limitedKmLabel } from 'src/cars/car-tools';
import styles from '../compare.module.scss';

interface Props {
  offer?: CarOffer;
}

const DistancePolicyCell: FC<Props> = ({ offer }) => {
  const { t } = useTranslation();

  return (
    <div>
      <span className={styles.policyDescription}>
        <span className={styles.policyValue}>
          {offer.includedKm === 0 && t('0nc_unlimited_km')}
          {offer.includedKm > 0 && limitedKmLabel(offer, t)}
          {offer.includedKm < 0 && t('additional_km_cost')}
        </span>
      </span>
    </div>
  );
};

DistancePolicyCell.defaultProps = {
  offer: null
};

export default DistancePolicyCell;
