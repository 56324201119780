import camelize from 'camelize';
import { DropdownItem } from 'components/forms/types';
import { Feature } from 'geojson';
import { get, cloneDeep } from 'lodash';
import { Coordinates, StationData } from 'modules/maps/types';
import moment from 'moment';
import nestedObjectAssign from 'nested-object-assign';
import { Point } from 'pigeon-maps';
import { CarOfferInterface } from 'src/cars/types';
import { agentSpecialSwitches, defaultLayoutSettings } from 'src/config';
import { simplifyStationType } from 'src/stations/station-tools';
import { Agent } from 'types/agent/agent.type';
import { AutofillData } from 'types/booking/autofill-data.type';
import { CarsApiConstructParams } from 'types/engine/cars-api-construct-params.type';
import { decode } from 'base-64';
import { Station } from 'src/stations/types';

export const distanceAutoUnit = (distance: number): string => {
  if (distance === null || distance === undefined) {
    return '0 m';
  }
  if (distance < 1000) {
    return `${distance.toFixed(0)} m`;
  }
  const km = distance / 1000;
  if (km % 1 === 0) {
    return `${km} km`;
  }
  return `${km.toFixed(2)} km`;
};

export const milisecondsToSeconds = (value: number, accuracy = 2): string =>
  Number(value / 1000).toFixed(accuracy);

export const normalizeAgent = (agentRaw: any): Agent => {
  const base = {
    layoutSettings: cloneDeep(defaultLayoutSettings),
    customTheme: {}
  };

  Object.assign(base.layoutSettings, get(agentSpecialSwitches, agentRaw?.agentId));
  // Move properties to layout config
  base.layoutSettings.searchform.minDaysToBooking = get(agentRaw, 'minDaysToCarBooking', 3);
  delete agentRaw.minDaysToCarBooking;
  base.layoutSettings.searchform.minDuration = get(agentRaw, 'minduration_carbooking', 0);
  delete agentRaw.minduration_carbooking;
  return nestedObjectAssign(base, camelize(agentRaw)) as Agent;
};

export const formatConstructParams = (data: CarsApiConstructParams): CarsApiConstructParams => {
  if (data.search && typeof data.search.dates.from === 'string') {
    data.search.dates.from = moment(data.search.dates.from);
  }

  if (data.search && typeof data.search.dates.to === 'string') {
    data.search.dates.to = moment(data.search.dates.to);
  }
  return data;
};

export const normalizeAutofillData = (data: AutofillData): AutofillData => {
  const ccExpire = get(data, 'payment.creditCard.expire');
  if (ccExpire && typeof ccExpire === 'string' && data.payment.creditCard) {
    data.payment.creditCard.expire = moment(data.payment.creditCard.expire);
  }

  return data;
};

export const overwriteParams = <T>(obj: T, source: T, existsFcn = (val: any) => val): T => {
  const data: T = source;
  Object.keys(obj as any).forEach((key) => {
    if (existsFcn(obj[key])) {
      data[key] = obj[key];
    }
  });
  return data;
};

export const countriesToOptions = (countries: any): Array<DropdownItem> => {
  if (!countries) return [];
  return Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code] || ''
  }));
};

export const countryName = (code: string, countries: any): string => countries[code] ?? code;

export const coordinatesToLatLong = (point: Coordinates): undefined | [number, number] =>
  point ? [point.latitude, point.longitude] : undefined;

export const coordinatesToLongLat = (point: Coordinates): undefined | [number, number] =>
  point ? [point.longitude, point.latitude] : undefined;

export const reverseCoordinates = (point?: Point): undefined | [number, number] =>
  Array.isArray(point) && point.length === 2 ? [point[1], point[0]] : undefined;

export const stationDataGeoJSON = (station: StationData): Feature => ({
  type: 'Feature',
  properties: station,
  geometry: {
    type: 'Point',
    coordinates: reverseCoordinates(station?.geo) ?? [0, 0]
  }
});
export const stationToData = (station: Station, cars?: Array<CarOfferInterface>): StationData => {
  const carOffers = cars || station.cars || [];
  return {
    id: station.id,
    name: station.name,
    code: station.code,
    partner: station.partner,
    geo: coordinatesToLatLong(station.geo),
    type: simplifyStationType(station.locationTypes),
    cars: carOffers,
    minPrice:
      carOffers.length > 0
        ? carOffers.reduce((min, car) => (car.price < min.price ? car : min)).price
        : 0,
    maxPrice:
      carOffers.length > 0
        ? carOffers.reduce((max, car) => (car.price > max.price ? car : max)).price
        : 0
  };
};

export const base64ToArrayBuffer = (base64) => {
  const binaryString = decode(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};
