import React, { FC, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

type Props = {
  open: boolean;
  children: ReactNode;
};

export const FadeIn: FC<Props> = (props) => (
  <CSSTransition in={props.open} timeout={300} classNames="fade" unmountOnExit>
    {props.children}
  </CSSTransition>
);

export const SlideUp: FC<Props> = (props) => (
  <CSSTransition in={props.open} timeout={300} classNames="slideUp" unmountOnExit>
    {props.children}
  </CSSTransition>
);

export const SlideDown: FC<Props> = (props) => (
  <CSSTransition in={props.open} timeout={300} classNames="slideDown" unmountOnExit>
    {props.children}
  </CSSTransition>
);
