import axios, { AxiosError, AxiosResponse } from 'axios';
import { get } from 'lodash';
import FrontendError from 'src/frontend-error.class';
import { ApiResponse } from 'types/engine/api';
import BrowserStorage from 'src/browser-storage';
import { proxyUrl } from 'src/utils';

const axiosInstance = axios.create();

const getRefreshToken = () => BrowserStorage.get('hpreview-refresh-token');
const getAccessToken = () => BrowserStorage.get('hpreview-access-token');

let isRefreshing = false;

const refreshAccessToken = (): Promise<any> =>
  new Promise((resolve) => {
    axiosInstance
      .post(`${proxyUrl()}headerspreview/login/refresh`, undefined, {
        headers: {
          Authorization: `Bearer ${getRefreshToken()}`
        }
      })
      .then(
        (res) => {
          BrowserStorage.set('hpreview-access-token', res.data.accessToken);
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
  });

const axiosInterceptorProcessResponse = async (res: AxiosResponse<ApiResponse>) => {
  if (res.status === 200) {
    if (res.data.success) {
      return {
        ...res,
        data: res.data.data,
        requestId: res.data.requestId
      };
    }

    if (!res.data.success && res.data.errors) {
      if (
        res.data.errors.filter((errorObject) => errorObject.code === 489).length > 0 &&
        isRefreshing !== true &&
        get(res, 'config.url').includes('headerspreview')
      ) {
        isRefreshing = true;
        const originalRequest = res.config;
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
          originalRequest.headers.Authorization = `Bearer ${getAccessToken()}`;
          const secondResponse = await axiosInstance(originalRequest).then((secRes) => {
            isRefreshing = false;
            return {
              ...secRes,
              requestId: secRes.data.requestId
            };
          });
          return secondResponse;
        }
      }
    }
  }

  return Promise.reject(
    new FrontendError(
      (res.status === 200
        ? res.data.errors
        : [
            {
              code: res.status,
              message: 'Request error'
            }
          ]
      ).map((item) => ({
        ...item,
        requestId: get(res, 'data.requestId', ''),
        requestUrl: get(res, 'config.url'),
        requestBody: res.config.data
      }))
    )
  );
};

const axiosInterceptorResponseError = (err: AxiosError) => {
  let message = 'Api request failed';

  if (err.code === 'ERR_CANCELED') {
    message = 'Request cancelled';
  } else if (err.response) {
    message = `Api responded with status code: ${err.response.status}`;
  } else if (err.request) {
    message = 'No api response was received';
  } else {
    message = 'Cannot estabilish api connection';
  }

  return Promise.reject(
    new FrontendError([
      {
        code: get(err, 'response.status', 905),
        message,
        requestId: get(err, 'response.data.requestId', ''),
        requestUrl: get(err, 'config.url'),
        requestBody: err.config?.data,
        responseData: get(err, 'response.data', {}),
        details: { error: err.toJSON(), canceled: err.code === 'ERR_CANCELED' }
      }
    ])
  );
};

axiosInstance.interceptors.response.use(
  axiosInterceptorProcessResponse,
  axiosInterceptorResponseError
);

export default axiosInstance;
