import { get } from 'lodash';
import { HeadTagData } from 'types/engine/head-tag-data.type';

const STYLE_TAG_REGEX = /<style(?<attrs>\b[^>]*)>(?<content>[^<]*)<\/style>/gm;
const SCRIPT_TAG_REGEX = /<script(?<attrs>\b[^>]*)>(?<content>[\s\S]*?)<\/script>/gm;
const LINK_REGEX = /<link(?<attrs>\b[^>]*)[/]*>/gm;
const META_REGEX = /<meta(?<attrs>\b[^>]*)[/]*>/gm;
const ATTR_REGEX = /(?<attr>\b\w+\b)\s*=\s*(?<value>"[^"]*"|'[^']*'|[^"'<>\s]+)/gm;
// const ATTR_REGEX = /(?<attr>\S+)=["']?(?<value>(?:.(?!["']?\s+(?:\S+)=|[>"']))+.)["']/gm;
const TITLE_REGEX = /<title>(?<title>[^<]*)<\/title>/m;
const QUOTE_RM_REGEX = /"/g;
const PMNT_ALT_LABEL_MAP = {
  BANK: 'sepa_payment',
  ONACC: 'payment_types_onacc'
};

// Uppercase first letter of string
export const ucFirst = (str: string): string => {
  if (str === null || str === undefined) return '';
  const firstLetter = str.slice(0, 1);
  return firstLetter.toUpperCase() + str.substring(1);
};

// Replace html entities to characters
export const replaceEntites = (s) => {
  const translateRe = /&(nbsp|amp|quot|lt|gt);/g;
  const translate = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>'
  };
  return s.replace(translateRe, (match, entity) => translate[entity]);
};

// Convert currency code to symbol
export const currencyToSymbol = (currency: string) => currency;
/*
  // turned off, mail from mk@2024-04-18
  switch (currency) {
    case 'EUR':
      return '&#8364;';
    case 'USD':
      return '&#36;';
    case 'GBP':
      return '&#163;';
    default:
      return currency;
  }
  */

// React helper - convert attributes from html to react format
export const attrMap = (attr: string): string => {
  switch (attr) {
    case 'http-equiv':
      return 'httpEquiv';
    case 'charset':
      return 'charSet';
    default:
      return attr;
  }
};

// Locale code to uppercase country code e.g. 'de_DE' -> 'DE'
export const localeToCountry = (loc: string): string => loc.slice(-2).toUpperCase();

// Locale code to lowercase language code e.g. 'de_DE' -> 'de'
export const localeToLanguage = (loc: string): string => loc.slice(0, 2).toLowerCase();

// Credit card code to name
export const ccCodeToName = (code: string): string => {
  switch (code) {
    case 'AX':
    case 'AXV':
    case 'AXB':
      return 'American Express';
    case 'VI':
    case 'VIV':
    case 'VIB':
      return 'VISA';
    case 'MC':
    case 'CA':
    case 'CAV':
    case 'CAB':
      return 'Mastercard';
    case 'DI':
    case 'DC':
      return 'Diners Club';
    case 'IM':
    case 'MAE':
      return 'Maestro';
    default:
      return 'Unknown';
  }
};

// Generate random string
export const prepareToken = (customLength?: number): string => {
  const length = customLength !== undefined ? customLength : 20;
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

// Add leading zeto to ensure number has 2 digits
export const twoDigits = (n: number): string => `00${n}`.slice(-2);

/* ----------------------------------------------------------------------------- HTML PARSERS --- */

export const extractAttrs = (content: string): any => {
  const attrs = {};
  const matches = Array.from(content.matchAll(ATTR_REGEX), (m) => m.groups);
  if (!matches) return {};

  matches.forEach((data) => {
    if (!data) return;
    attrs[attrMap(data.attr)] = data.value.replace(QUOTE_RM_REGEX, '');
  });

  return attrs;
};

export const parseStyleTags = (html: string): Array<HeadTagData> => {
  const matches = Array.from(html.matchAll(STYLE_TAG_REGEX), (m) => m.groups);
  const tags: Array<HeadTagData> = [];
  if (!matches) return [];

  matches.forEach((data) => {
    if (!data) return;
    tags.push({
      content: data.content,
      ...extractAttrs(data.attrs)
    });
  });
  return tags;
};

export const parseScriptTags = (html: string): Array<any> => {
  const matches = Array.from(html.matchAll(SCRIPT_TAG_REGEX), (m) => m.groups);
  const tags: Array<HeadTagData> = [];
  if (!matches) return [];

  matches.forEach((data) => {
    if (!data) return;
    tags.push({
      content: data.content,
      ...extractAttrs(data.attrs)
    });
  });
  return tags;
};

export const parseLinkTags = (html: string): Array<any> => {
  const matches = Array.from(html.matchAll(LINK_REGEX), (m) => m.groups);
  const tags: Array<HeadTagData> = [];
  if (!matches) return [];

  matches.forEach((data) => {
    if (!data) return;
    tags.push({
      ...extractAttrs(data.attrs)
    });
  });
  return tags;
};

export const parseMetaTags = (html: string): Array<any> => {
  const matches = Array.from(html.matchAll(META_REGEX), (m) => m.groups);
  const tags: Array<HeadTagData> = [];
  if (!matches) return [];

  matches.forEach((data) => {
    if (!data) return;
    tags.push({
      ...extractAttrs(data.attrs)
    });
  });
  return tags;
};

export const parseTitle = (tags: Array<string>): string => {
  if (!tags) return '';
  const title = tags.find((html) => html.match(TITLE_REGEX));

  return title ? get(title.match(TITLE_REGEX), 'groups.title', '') : '';
};

export const priceFormat = (price: number, afterComma = 2, delimiter = ','): string => {
  const p = (price / 1).toFixed(afterComma).replace('.', delimiter);
  return p.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const combineString = (baseString: string, combinables: Array<string>) => {
  combinables.forEach((item, index) => {
    baseString = baseString.replace(`@${index + 1}@`, item);
  });
  const pattern = /@\d+@/gi;
  // clean all trailing @X@s
  baseString = baseString.replace(pattern, '');
  return baseString;
};

export const paymentCodeToLabel = (code: string): string =>
  PMNT_ALT_LABEL_MAP[code] || (code || '').toLocaleLowerCase();

export const removeHtmlTags = (html: string): string => {
  try {
    html = html.replace(/(<([^>]+)>)/gim, '');
  } catch (e) {
    return html;
  }
  return html;
};
