import React, { useState } from 'react';

import useHasMounted from 'hooks/use-has-mounted';
import Button from 'components/forms/button';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import UrlParamsPreview from './url-params-preview';
import DevToggles from './dev-toggles';

import styles from './dev-tools.module.scss';
import DevStateSummary from './dev-state-summary';
import BackendProxy from './backend-proxy';

const DevTools = () => {
  const { inhouse } = useDevToolsContext();
  const mounted = useHasMounted();
  const [expanded, setExpanded] = useState(false);

  if (!mounted || !inhouse) return null;

  return (
    <>
      <div className={styles.outerContainer}>
        <div className={styles.innerContainer}>
          <h5 className={styles.title}>
            <span>Dev tools</span>
            {expanded ? (
              <Button size="small" onClick={() => setExpanded(false)}>
                Collapse tools
              </Button>
            ) : (
              <Button size="small" onClick={() => setExpanded(true)}>
                Expand tools
              </Button>
            )}
          </h5>
          {expanded && (
            <div className={styles.detailsContainer}>
              <UrlParamsPreview />
              <DevToggles />
              <BackendProxy />
            </div>
          )}
        </div>
      </div>
      <DevStateSummary />
    </>
  );
};

export default DevTools;
