import Bowser from 'bowser';
import moment from 'moment';
import axiosInstance from 'src/axios-instance';
import { getRawQueryParam } from 'src/utils/url-tools';
import { LogGroup, LogMessage, LogTag } from './types';

const logLevels = ['CRITICAL', 'ERROR', 'WARNING', 'NOTICE', 'INFO', 'DEBUG'];

export const isLogLevel = (value?: string): boolean => {
  if (!value) return false;
  return logLevels.includes(value);
};

export const getTabId = (): string => {
  let tabId = '';
  try {
    tabId = sessionStorage.getItem('tabId') || '';
  } catch (error) {
    //
  }
  return tabId;
};

export const logRequest = (msg: LogMessage, skipBrowserInfo = false) => {
  if (!msg.agentId && window) msg.agentId = getRawQueryParam('aid') || '';
  if (!msg.sid && window) msg.sid = getRawQueryParam('sid') || '';
  if (!msg.tabId && window) msg.tabId = getTabId();
  if (!msg.searchId && window) msg.searchId = getRawQueryParam('searchid') || '';
  if (!msg.url && window) msg.url = window ? window.location.href : '';
  if (skipBrowserInfo) {
    msg.skipBrowserInfo = true;
  }
  axiosInstance.post('/proxy/log', msg).catch(() => null);
};

export const stringToLogLevel = (level: string): number => {
  const lvl = logLevels.indexOf(level);
  return lvl >= 0 ? lvl : 1;
};

export const tagToGroup = (tag: LogTag): LogGroup => {
  switch (tag) {
    case 'emptySuggesterResponse':
    case 'forbiddenLocation':
    case 'alternativeSuggestionSelected':
      return 'suggester';

    case 'searchStarted':
    case 'searchWarning':
    case 'searchError':
    case 'searchFailed':
    case 'partnerOffersError':
    case 'offersLoaded':
    case 'searchSummary':
    case 'missingStationData':
    case 'wsDecompressionFailed':
    case 'noOffers':
      return 'search';

    case 'formConfigFailed':
    case 'invalidForm':
    case 'norrisIssue':
    case 'norrisFormChanged':
    case 'norrisTokenResponse':
    case 'scaProcess':
      return 'preBooking';

    case 'bookingSuccess':
    case 'bookingFailed':
    case 'getConfirmation':
    case 'getConfirmationFailed':
    case 'cancelBookingFailed':
      return 'booking';

    case 'browserInfo':
      return 'client';

    case 'visitLog':
    case 'userInteraction':
      return 'traffic';

    case 'appStarted':
    case 'proxyError':
    case 'unknownAgent':
    case 'invalidUrl':
    case 'appCrash':
    case 'documentError':
    case 'pageNotFound':
    case 'sessionExpired':
    case 'noOffer':
    case 'apiResponseError':
    case 'debug':
    case 'wsTimeout':
    case 'wsTiming':
    case 'wsError':
    default:
      return 'engine';
  }
};

export const getBrowserData = (): any => {
  const { availHeight, availWidth, height, width, colorDepth, pixelDepth } = window.screen;
  const { innerWidth, innerHeight } = window;
  const browserData = Bowser.parse(window.navigator.userAgent);
  return {
    ...browserData,
    screen: { availHeight, availWidth, height, width, colorDepth, pixelDepth },
    window: { innerWidth, innerHeight },
    inIframe: window.self !== window.top,
    userAgent: window.navigator.userAgent
  };
};

export const serverLog = (event: string, message: string, data?: any): void => {
  console.log(`${moment().format('DD.MM.YYYY, HH:mm:ss')} [${event}] - ${message}`);
  if (data) console.log(data);
};

export const serverError = (event: string, message: string, data?: any): void => {
  console.error(`${moment().format('DD.MM.YYYY, HH:mm:ss')} [${event}] - ${message}`);
  if (data) console.error(data);
};
