import { get, cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import { CarOffer } from 'src/cars/types';
import { ApiResponse } from 'types/engine/api';
import { getSearchParam } from 'src/url-manager';
import safeNextConfig from 'src/utils/safe-next-config';
import { unique } from 'src/utils/array';
import { SearchData } from 'types/search/search-data.type';
import { SessionData } from 'types/session-data.type';
import { TestMode } from 'types/engine/dev-data.type';
import { ReplaySubject } from 'rxjs';
import BrowserStorage from 'src/browser-storage';
import { sameSearch, proxyUrl } from 'src/utils';
import { otherFuelPolicy } from 'src/cars/types/fuel-policy.type';
import { wsCritical, wsDebug, wsError, wsInfo, wsWarning } from 'src/logger';
import FrontendError from 'src/frontend-error.class';
import ErrorCodes from 'types/error-codes.enum';
import axiosInstance from 'src/axios-instance';
import {
  FetchPartnerResult,
  FiltersRange,
  InitSearchData,
  ParnerWorkerResult,
  SearchProcessEvent
} from './types';

const { publicRuntimeConfig } = safeNextConfig();

const OFFERS_COMPRESSION = publicRuntimeConfig.offersCompression;
const OFFERS_TIMEOUT = publicRuntimeConfig.offersTimeout;

const emptyResponse: FetchPartnerResult = {
  offersQty: 0,
  rawMessage: null,
  rates: {}
};

export const compareKeyInOffer = (offer: CarOffer, key: string) => {
  switch (key) {
    case 'partner':
      return offer.partner;
    case 'carType':
      return offer.cartype;
    case 'class':
      return offer.carclass;
    case 'carScore':
      return offer.scoreLabel;
    case 'fuel':
      return otherFuelPolicy.indexOf(offer.fuelPolicy) > -1 ? 'other' : offer.fuelPolicy;
    case 'hasDeposit':
      if (offer.hasDeposit === true) return 'has-deposit';
      if (offer.hasDeposit === false) return 'no-deposit';
      return 'unknown';
    case 'deposit':
      return offer.deposit;
    default:
      return undefined;
  }
};

export const retrieveCarsData = (sessionId: string): Promise<any> =>
  new Promise((resolve, reject) => {
    axiosInstance.post<ApiResponse>('offers', { sessionId }).then((res) => {
      resolve(res.data);
    }, reject);
  });

export const createFiltersRange = (offers: Array<CarOffer> = []): FiltersRange => {
  const range: FiltersRange = {
    price: { min: 0, max: 0 },
    partners: []
  };

  offers.forEach((car) => {
    if (car.priceSort > range.price.max) {
      range.price.max = car.priceSort;
    }
    if (range.partners.indexOf(car.partner) === -1) {
      range.partners.push(car.partner);
    }
  });

  return range;
};

export const extendFiltersRange = (a: FiltersRange, b: FiltersRange): FiltersRange => ({
  price: {
    min: a?.price?.min <= b?.price?.min ? a?.price?.min : b?.price?.min,
    max: a?.price?.max >= b?.price?.max ? a?.price?.max : b?.price?.max
  },
  partners: unique([...(a?.partners ?? []), ...(b?.partners ?? [])])
});

export const getOffersFromStorage = (
  search: SearchData,
  searchId: string,
  data: SessionData,
  testMode: TestMode = []
): ReplaySubject<SearchProcessEvent> => {
  const events = new ReplaySubject<SearchProcessEvent>();
  events.next({ type: 'start' });
  events.next({ type: 'initialized', data: { searchId, partners: [] } });
  const offersQty = (data.offers || []).length;
  wsInfo(
    'offersLoaded',
    offersQty > 0
      ? `Session from storage, ${(data.offers || []).length} offers`
      : 'No offers in browser storage',
    {
      search,
      searchId,
      testMode,
      partners: [],
      offers: (data.offers || []).length,
      stations: (data.stations || []).length
    }
  );

  const worker = new Worker(new URL('./process-raw-offers.worker', import.meta.url));

  worker.onmessage = (e: any) => {
    worker.terminate();
    events.next({
      type: 'update',
      data: {
        offers: e.data.offers,
        stations: e.data.stations,
        filtersRange: createFiltersRange(e.data.offers),
        exchangeRates: data.exchangeRates
      }
    });
    events.complete();
  };

  worker.postMessage({
    offers: data.offers,
    stations: data.stations,
    rentDays: search.rentDays,
    sessionId: searchId,
    exchangeRates: data.exchangeRates
  });
  return events;
};

export const initSearch = async (
  frontendId: string,
  agentId: string,
  affiliateId: string,
  search: SearchData,
  testMode: TestMode,
  partners: Array<string> = [],
  customProxy = ''
): Promise<InitSearchData> =>
  new Promise((resolve, reject) => {
    const postData = {
      agentId,
      frontendId,
      affiliateId,
      pickupDateTime: moment(search.dates.from).format('YYYY-MM-DDTHH:mm:ss'),
      pickupLocation: get(search, 'location.pickUp.locationKey'),
      pickupStation: '',
      dropoffDateTime: moment(search.dates.to).format('YYYY-MM-DDTHH:mm:ss'),
      dropoffLocation: get(search, 'location.dropOff.locationKey'),
      dropoffStation: '',
      locale: search.locale,
      partners: partners.length > 0 ? partners : undefined,
      driverAge: search.driverAge,
      driverCountry: search.driverCountry,
      testMode
    };
    axiosInstance.post(`${proxyUrl(customProxy)}search`, postData).then(
      (res) => {
        resolve({
          sessionId: res.data.session_id,
          partners: res.data.partners || []
        });
      },
      (err) => {
        if (frontendId !== 'jsonFeed') postMessage({ type: 'failed' });
        reject(err);
      }
    );
  });

export const fetchParner = async (
  events: ReplaySubject<SearchProcessEvent>,
  sessionId: string,
  partner: string,
  search: SearchData,
  agentCurrency: string,
  percentageProgress: number,
  compression: boolean,
  timeout = 60000,
  customProxy = ''
): Promise<FetchPartnerResult> => {
  const partnerWorker = new Worker(new URL('./partner.worker', import.meta.url));

  return new Promise((resolve) => {
    partnerWorker.postMessage({
      sessionId,
      partner,
      search: JSON.parse(JSON.stringify(search)),
      percentageProgress,
      compression,
      timeout,
      agentCurrency,
      customProxy
    });

    partnerWorker.onmessage = (e) => {
      switch (get(e, 'data.type')) {
        case 'partnerResponse':
          const result: ParnerWorkerResult = e.data.data;
          events.next({
            type: 'update',
            data: result
          });

          resolve({
            offersQty: (result?.offers ?? []).length,
            rawMessage: result?.raw,
            rates: result?.exchangeRates?.rates ?? {}
          });
          break;

        case 'finished':
          partnerWorker.terminate();
          break;

        case 'emptyResponse':
          partnerWorker.terminate();
          resolve(emptyResponse);
          wsDebug('searchWarning', `No offers from ${partner}`);
          break;

        case 'error':
          const code = e?.data?.data?.code;
          partnerWorker.terminate();
          resolve(emptyResponse);
          wsWarning('searchError', `${partner} error ${code ? `(${code})` : ''}`, {
            search,
            error: e?.data?.data
          });
          break;

        default:
      }
    };
  });
};

const newSearch = (
  search: SearchData,
  agentId: string,
  agentCurrency: string,
  affiliateId: string,
  testMode: TestMode = [],
  forcedPartners: Array<string> = [],
  customProxy = ''
): ReplaySubject<SearchProcessEvent> => {
  const events = new ReplaySubject<SearchProcessEvent>();

  let searchId = '';

  events.next({ type: 'start' });

  initSearch(
    getSearchParam('sid'),
    agentId,
    affiliateId,
    search,
    testMode,
    forcedPartners,
    customProxy
  ).then(
    ({ sessionId, partners }) => {
      searchId = sessionId;
      events.next({ type: 'initialized', data: { searchId: sessionId, partners } });
      wsInfo('searchStarted', 'Search started', {
        search,
        searchId,
        testMode,
        partners
      });

      Promise.all(
        partners.map((partner) =>
          fetchParner(
            events,
            sessionId,
            partner,
            search,
            agentCurrency,
            (1 / partners.length) * 100,
            OFFERS_COMPRESSION,
            OFFERS_TIMEOUT,
            customProxy
          )
        )
      ).then(
        (data) => {
          const exchangeRates = {
            agentCurrency,
            rates: {}
          };
          data.forEach(({ rates }) => {
            exchangeRates.rates = {
              ...exchangeRates.rates,
              ...rates
            };
          });

          BrowserStorage.storeSearch(
            searchId,
            search,
            data.map(({ rawMessage }) => rawMessage).filter((item) => !!item),
            exchangeRates
          );
          events.complete();
          const offersQty = data.map((el) => el.offersQty).reduce((sum, val) => sum + val, 0);
          wsInfo(
            'searchSummary',
            `Search finished, ${offersQty} offers,  ${partners.length} partners`,
            {
              search,
              searchId,
              testMode,
              partners,
              offersQty
            }
          );
          if (offersQty === 0) {
            wsWarning(
              'noOffers',
              `No offers in ${search.location?.pickUp?.name},  ${partners.length} partners`,
              {
                search,
                searchId,
                testMode,
                partners,
                offersQty
              }
            );
          }
        },
        (error) => {
          events.error(
            new FrontendError([{ code: ErrorCodes.searchProcessError, message: error }])
          );
          wsCritical('searchFailed', 'Search process failed', {
            search,
            error
          });
        }
      );
    },
    (error: FrontendError) => {
      events.error(error);
      wsError(
        'searchFailed',
        'Init session failed',
        {
          search,
          error
        },
        error?.requestId
      );
    }
  );

  return events;
};

export const startSearchProcess = (
  searchData: SearchData,
  agentId: string,
  agentCurrency: string,
  affiliateId: string,
  forceNewSearch = false,
  testMode: TestMode = [],
  forcedPartners: Array<string> = [],
  customProxy = ''
): ReplaySubject<SearchProcessEvent> => {
  const searchId = getSearchParam('searchid');

  const search: SearchData = {
    ...cloneDeep(searchData),
    dates: {
      from: searchData?.dates?.from as Moment,
      to: searchData?.dates?.to as Moment
    }
  };

  if (searchId && !forceNewSearch) {
    const cacheData = BrowserStorage.getSearchResults(searchId);
    if (cacheData && sameSearch(search, cacheData.search)) {
      return getOffersFromStorage(search, searchId, cacheData, testMode);
    }
  }

  return newSearch(
    search,
    agentId,
    agentCurrency,
    affiliateId,
    testMode,
    forcedPartners,
    customProxy
  );
};
