import { useRouter } from 'next/router';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import React, { useEffect, useState } from 'react';
import { getQueryParam } from 'src/utils/url-tools';
import { clone } from 'lodash';
import { refreshSession } from 'src/url-manager';
import useCarsApi from 'providers/cars-api/use-cars-api';
import classNames from 'classnames';
import styles from './dev-tools.module.scss';

const refreshIcon = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 383.2 323.6"
    xmlSpace="preserve"
  >
    <path
      fill="white"
      d="M0,175.4c2.4-5.3,6.5-7.3,12.3-7c5.7,0.3,11.4,0.1,16.6,0.1C31.7,87.4,69.1,32.1,146.5,5.6c57.6-15,108.9-3,154.5,35.8
C289.4,54.9,278.2,68,267.1,81C223.8,46.5,178,40.3,130.8,70.6c-34.7,22.3-49.6,56.2-48.6,97.8c6.4,0,12.3,0,18.3,0c2,0,4,0,6,0
c4,0,7,1.8,8.7,5.4c1.8,3.8,1,7.3-1.7,10.4c-7.8,9-15.7,18.1-23.6,27.1c-10.1,11.6-20.2,23.1-30.2,34.7c-0.5,0.5-1,1-1.7,1.7
c-10.4-11.9-20.7-23.8-31.1-35.6c-7.7-8.8-15.4-17.7-23.1-26.5c-1.4-1.6-2.5-3.3-3.8-5C0,178.9,0,177.1,0,175.4z M90.3,287.8
c45.2,33.8,95,45,148.3,27.9c70.6-22.6,107.3-73.8,115.6-147c5.8,0,11.9-0.1,17.9,0c4.5,0.1,8.2-1.2,10.2-5.4
c2.1-4.4,0.5-8.1-2.6-11.6c-9.7-10.9-19.2-22-28.8-33c-8.5-9.8-17-19.5-25.7-29.5c-8.3,9.5-16.4,18.8-24.5,28.1
c-10.2,11.8-20.5,23.5-30.7,35.2c-2.7,3.1-3.7,6.5-2,10.4c1.7,3.8,4.6,5.7,8.8,5.7c3.2,0,6.5,0,9.7,0c4.7,0,9.4,0,14.5,0
c-4.3,38.9-22.1,69-55.9,87.6c-41.4,22.8-81.9,18.3-120.7-8.4C113.2,261,101.9,274.2,90.3,287.8z"
    />
  </svg>
);

const DevStateSummary = () => {
  const router = useRouter();
  const { general, globalEvents } = useCarsApi();
  const { testMode, setTestMode, inhouse } = useDevToolsContext();
  const [searchId, setSearchId] = useState('');

  const toggleTest = (enable: boolean) => {
    if (enable) {
      testMode.push('TEST');
    } else {
      testMode.splice(testMode.indexOf('TEST'), 1);
    }
    setTestMode(clone(testMode));
    setTimeout(() => {
      refreshSession(inhouse);
      globalEvents.next({ type: 'testModeChange' });
    }, 100);
  };

  useEffect(() => {
    setSearchId(getQueryParam('searchid') || '');

    const handleRouteChange = () => {
      setSearchId(getQueryParam('searchid') || '');
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  const testApiMode = testMode.includes('TEST');

  return (
    <ul
      className={classNames(styles.stateSummary, {
        [styles['stateSummary--test']]: testApiMode
      })}
    >
      <li>
        {testApiMode ? (
          <div className={styles['stateSummary__mode--test']}>
            <span>Test booking</span>
            <button
              type="button"
              className={styles.stateSummary__mode__btn}
              onClick={() => toggleTest(false)}
            >
              {refreshIcon}
            </button>
          </div>
        ) : (
          <div className={styles.stateSummary__mode}>
            <span>Live booking</span>
            <button
              type="button"
              className={styles.stateSummary__mode__btn}
              onClick={() => toggleTest(true)}
            >
              {refreshIcon}
            </button>
          </div>
        )}
      </li>
      {searchId && (
        <li>
          <a
            className={styles.stateSummary__btn}
            target="_blank"
            href={`http://carmonitor.infosys.loc/cars/session-details?session_id=${searchId}&host=`}
            rel="noreferrer"
          >
            <strong>Session monitor</strong>
          </a>
        </li>
      )}
      <li>
        <a
          className={styles.stateSummary__btn}
          target="_blank"
          href={`http://carmonitor.infosys.loc/cars/frontend?frontend_id=${general.sid}&host=`}
          rel="noreferrer"
        >
          <strong>Frontend logs</strong>
        </a>
      </li>
    </ul>
  );
};

export default DevStateSummary;
