import { useContext } from 'react';
import { devToolsContext } from '.';
import { DevToolsContextType } from './types';

const useDevToolsContext = (): DevToolsContextType => {
  const {
    inhouse,
    dataPreview,
    setDataPreview,
    testMode,
    setTestMode,
    partners,
    setPartners,
    disableSca,
    setDisableSca,
    customProxy,
    setCustomProxy
  } = useContext(devToolsContext);

  return {
    inhouse,
    dataPreview,
    setDataPreview,
    testMode,
    setTestMode,
    partners,
    setPartners,
    disableSca,
    setDisableSca,
    customProxy,
    setCustomProxy
  };
};

export default useDevToolsContext;
